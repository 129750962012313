import { IBaseAudit } from '../auditable.interface';
import { IOrder } from './order.interface';

export interface IOrderAddress extends IBaseAudit {
  order_id: number;
  address_id?: number;
  is_bill_address: boolean;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  type: OrderAddressTypeEnum;
  user_id?: number;
  is_default: boolean;
  formatted_address: string;
  district?: string;
  street_no?: string;
  area?: string;
  postal_code?: number;
  delivery_instruction?: string;
  order?: IOrder;
  is_verified?: boolean;
  image?: string;
  comments?: string;
}

export enum OrderAddressTypeEnum {
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other'
}
