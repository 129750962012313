import { IAuditable } from './auditable.interface';
import { ICommonOption, BaseSelect } from './common.interface';
import { ICommonEmailData } from './notification.interface';
import { EmployeeAvailabilityEnum } from './common.interface';
import { IRole } from './role.interface';
import { ISuperMarket } from './supermarket/super-market.interface';
import { LoginTypeEnum, UserTypeEnum } from './user.interface';
import { IListInterface } from './list.interface';

export interface IEmployee extends IAuditable {
  user: number;
  email?: string;
  otp?: number;
  otp_generated_at?: number;
  password?: string;
  password_salt?: string;
  password_updated_at?: Date;
}

export interface IEmployeeData extends IAuditable {
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  country_code?: string;
  mobile_number?: string;
  dob?: Date;
  role_id?: number;
  super_market_id?: number;
  vehicle_id?: number;
  country_id?: number;
  city_id?: number;
  super_market_ids?: number[];
  role?: IRole;
  super_markets?: IEmployeeSupermarket[];
  supermarkets?: ISuperMarket[];
  language_id?: number;
  user_type?: UserTypeEnum;
  roles?: OperationalEmployeeRoleEnum[];
  operational_roles?: IEmployeeOperationalRole[];
  active?: boolean;
  status?: boolean;
  availability?: EmployeeAvailabilityEnum;
  firstName?: string;
  lastName?: string;
  vehicleId?: number;
  login_type?: LoginTypeEnum;
  social_id?: string;
}

export interface IEmployeeListItem {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  country_code?: string;
  mobile_number?: string;
  active?: boolean;
  country_id?: number;
  city_id?: number;
  super_market_ids?: number[];
  role_id?: number;
  dob?: Date;
  user_type?: UserTypeEnum;
  role?: IRole;
  super_market?: ISuperMarket;
  roles?: OperationalEmployeeRoleEnum[];
  operational_roles?: IEmployeeOperationalRole[];
  super_markets?: IEmployeeSupermarket[];
}

export interface IEmployeeList {
  total_item_count: number;
  limit: number;
  list: [IEmployeeListItem];
}

export interface ICreateEmployee {
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  confirm_password?: string;
  country_code: string;
  mobile_number: string;
  country_id?: number;
  city_id?: number;
  dob?: string;
  role_id?: number;
  user_type: UserTypeEnum;
  roles: string[];
  super_market_ids?: number[];
}

export interface ICreateEmployeeRes {
  token: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  email: string;
  user_id: number;
}

export interface IUpdateEmployeeRes {
  data: number;
}

export interface IEmployeeId {
  id: number;
}

export interface IEmployeeFilteredData {
  employee_list: [IEmployeeId];
  role_list: [IRole];
}

export type IEmployeeListData = {
  page: number;
  name: string;
  email: string;
  mobile_number: string;
  user_type: string;
  id: string;
  role: string;
};

export interface IEmployeeListPayload extends IEmployeeListData {
  [index: string | number]: string | number;
}

export interface IEmployeeOperationalRole {
  employee_id?: number;
  role: OperationalEmployeeRoleEnum;
  employee?: IEmployeeData;
}

export interface IEmployeeSupermarket {
  employee_id?: number;
  super_market_id: number;
  employee?: IEmployeeData;
  supermarket?: ISuperMarket;
}

export interface IOperationalEmployeeActivityLog {
  id?: number;
  employee_id: number;
  type: OperationalEmployeeLogTypeEnum;
  time?: Date;
  employee?: IEmployeeData;
}

export interface IGrpcValidateEmployeeSupermarket {
  employeeId: number;
  supermarketIds: number[];
}

export interface IGrpcValidateEmployeeSupermarketResponse {
  isValid: boolean;
}

export interface IGrpcEmployee {
  employees: IEmployeeData[];
}

export interface IGrpcVehicleDriverUpdateRequest {
  vehicleId: number;
  driverIds: number[];
  deleteAndUpdate: boolean;
}

export interface IGrpcVehicleDriverUpdateResponse {
  isUpdated: boolean;
}

export interface IGrpcVehicleDriverDeleteRequest {
  vehicleId: number;
}

export interface IGrpcUpdateAvailabilityReq {
  employee_id: number;
  availability: EmployeeAvailabilityEnum;
}

export interface IGrpcUpdateAvailabilityRes {
  is_updated: number;
}

export interface IEmployeeWelcomeEmail extends ICommonEmailData {
  new_password: string;
  web_url: string;
  company_name: string;
}

export interface IGrpcListOperationalEmployeeReq {
  employee_id: number;
  supermarket_id: number;
  page: number;
  role: OperationalEmployeeRoleEnum;
  name?: string;
  availability?: EmployeeAvailabilityEnum;
}

export interface IGrpcListOperationalEmployeeRes {
  isValid: boolean;
  list?: IListInterface<IEmployeeData>;
}

export interface IEmployeeListSelect {
  id: boolean;
  first_name?: boolean;
  last_name?: boolean;
  status: boolean;
  availability: boolean;
  vehicle_id?: boolean;
}

export enum OperationalEmployeeRoleEnum {
  PICKER = 'picker',
  PACKER = 'packer',
  DRIVER = 'driver',
  CHECKER = 'checker',
  SECURITY_OFFICER = 'security_officer',
  INVENTORY_OFFICER = 'inventory_officer',
  RETURN_OFFICER = 'return_officer'

}

export enum OperationalEmployeeLogTypeEnum {
  LOGIN = 'login',
  LOGOUT = 'logout',
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum EmployeeGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  USER_TYPE = 'user_type',
  ROLE = 'role'
}

export const employeeSelect = {
  id: true,
  is_deleted: true,
  user: true,
  email: true,
  password: true,
  password_salt: true,
  otp: true,
  otp_generated_at: true
};

export const operationalEmployeeBaseListSelect = {
  id: true,
  first_name: true,
  last_name: true
};

export const employeeTypeOptions: ICommonOption<UserTypeEnum>[] = [
  {
    id: UserTypeEnum.ADMIN,
    name: 'Administrator'
  },
  {
    id: UserTypeEnum.OPERATION,
    name: 'Operational Employee'
  }
];

export const operationalEmployeeRoleOptions: ICommonOption<OperationalEmployeeRoleEnum>[] = [
  {
    id: OperationalEmployeeRoleEnum.PICKER,
    name: 'Picker'
  },
  {
    id: OperationalEmployeeRoleEnum.PACKER,
    name: 'Packer'
  },
  {
    id: OperationalEmployeeRoleEnum.DRIVER,
    name: 'Driver'
  },
  {
    id: OperationalEmployeeRoleEnum.CHECKER,
    name: 'Checker'
  },
  {
    id: OperationalEmployeeRoleEnum.SECURITY_OFFICER,
    name: 'Security Officer'
  },
  {
    id: OperationalEmployeeRoleEnum.INVENTORY_OFFICER,
    name: 'Inventory Officer'
  },
  {
    id: OperationalEmployeeRoleEnum.RETURN_OFFICER,
    name: 'Return Officer'
  }
];

export const EmployeeListSelect = {
  id: true,
  first_name: true,
  last_name: true,
  email: true,
  gender: true,
  country_code: true,
  mobile_number: true,
  dob: true,
  user_type: true,
  role_id: true,
  country_id: true,
  city_id: true,
  active: true,
  role: {
    ...BaseSelect
  },
  operational_roles: {
    role: true
  },
  super_markets: {
    super_market_id: true
  }
};

export type EmployeeCondition =
  | {
      email: string;
      is_deleted: boolean;
    }
  | {
      mobile_number: string;
      is_deleted: boolean;
    };
