import { IAuditable } from './auditable.interface';
import { LocaleEnum, BaseSelect } from './common.interface';

export interface ILanguage extends IAuditable {
  id?: number;
  name: string;
  code: LocaleEnum;
  flag_url?: string;
  text_right?: boolean;
}

export interface IGrpcLanguageReq {
  id?: number;
  code?: LocaleEnum;
}

export interface IGrpcGetLanguagesByIdsReq {
  id?: number[];
  code?: LocaleEnum;
}

export interface IGrpcGetLanguagesByIdsRes {
  languages: ILanguage[];
}

export const languageListSelect = {
  ...BaseSelect,
  code: true,
  flag_url: true,
  text_right: true
};

export const languageBaseListSelect = {
  ...BaseSelect,
  code: true,
  text_right: true
};
