export interface ItoastListener {
  is_error: boolean;
  message?: string;
  show: boolean;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: (...args: any[]) => void; // Optional callback
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any[];
  hideIcon?: boolean;
  actionLabel?: string;
}
