import { IBaseAudit } from '../auditable.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IWmsZone } from './wms-zone.interface';
import { BaseSelect } from './../common.interface';
import { IWmsStockLocation } from './wms-stock-management.interface';

export interface IWmsLocation extends IBaseAudit {
  supermarket_id: number;
  zone_id: number;
  aisle: string;
  position: number;
  bay?: string;
  level?: string;
  division?: string;
  super_market?: ISuperMarket;
  zone?: IWmsZone;
  is_selected?: boolean;
  stocks?: IWmsStockLocation[];
  barcode_label?: string;
}

export interface IWmsLocationListData {
  page: number;
  id: string;
  supermarket_id: number;
  zone: string;
  aisle: string;
  bay: string;
  level: string;
  division: string;
  position: number;
}

export interface IWmsLocationListPayload extends IWmsLocationListData {
  [index: string]: string | number;
}

export interface IWmsLocationBaseListData {
  supermarket_ids: number[];
  zone_id: number;
}

export interface IWmsLocationBaseListPayload extends IWmsLocationBaseListData {
  [index: string]: number | number[];
}

export interface IWmsLocationUploadCsv {
  aisle: string;
  bay: string;
  level: string;
  division: string;
  position: number;
}

export interface IWmsDownloadLocationBarcodePayload {
  [index: string]: string | number | number[];
}

export interface IBulkWmsLocationUploadPayload {
  supermarket_id?: number;
  zone_id?: number;
  event: string;
  list: IWmsLocation[];
}

export enum WmsLocationGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  SUPERMARKET_ID = 'supermarket_id',
  ZONE = 'zone',
  AISLE = 'aisle',
  BAY = 'bay',
  LEVEL = 'level',
  DIVISION = 'division',
  POSITION = 'position'
}

export const wmsLocationBaseListSelect = {
  id: true,
  aisle: true,
  bay: true,
  level: true,
  division: true,
  position: true
};

export const wmsLocationSelect = {
  id: true,
  supermarket_id: true,
  zone: {
    ...BaseSelect
  },
  aisle: true,
  bay: true,
  level: true,
  division: true,
  position: true
};

export const wmsLocationUploadCsvLabels = {
  aisle: 'Aisle',
  bay: 'Bay',
  level: 'Level',
  division: 'Division',
  position: 'Position'
};
