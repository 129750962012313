import { OperationalEmployeeRoleEnum } from './employee.interface';
import { IKeyMapping } from './key-mapping.interface';
import { IOrder } from './order/order.interface';
import { IProductVariant } from './product/product-variant.interface';
import { IProduct } from './product/product.interface';
import { ITaxonGroup } from './product/taxon-group.interface';
import { IUserToken, UserTypeEnum } from './user.interface';
import { IWmsPutaway } from './warehouse-management/wms-putaway.interface';
import { IWmsReceiving } from './warehouse-management/wms-receiving.interface';
import { IWmsStockMaster } from './warehouse-management/wms-stock-management.interface';
import { IOrderPickingBatch } from './fulfillment/order-picking.interface';
import { IDispatchJob } from './fulfillment/dispatch-job.interface';
import {
  IOrderPickingShipmentBins,
  IOrderPickingShipmentProducts,
  StaggerJobItemsTypeEnum
} from './order/order-picking-stagger.interface';
import { IAddress } from './location/address.interface';
import { IOrderAddress } from './order/order-address.interface';
import { IUserGiftCard } from './order/gift-card.interface';
import { IOrderShipment } from './order/order-shipment.interface';

export interface ICommonEntity {
  name: string;
  [key: string]: unknown;
}

export interface IRequest {
  user: IUserToken;
}

export interface IHttpRequest {
  url: string;
  headers: IKeyMapping<string>;
}

export interface IExistCheckRequest {
  params: {
    id?: number;
    taxon_id?: number;
    option_value_id?: number;
    ingredient_id?: number;
    putaway_id?: number;
    shipment_id?: number;
    key?: string;
    idOrSlug?: string;
    stagger_job_item_type?: StaggerJobItemsTypeEnum;
    short_code?: string;
  };
  body: {
    districts?: number[];
    order_cancellation_reason_id?: number;
    product?: IProduct;
    product_variant?: IProductVariant;
    receiving?: IWmsReceiving;
    putaway?: IWmsPutaway;
    stock?: IWmsStockMaster;
    taxon_group?: ITaxonGroup;
    order?: IOrder;
    new_password?: string;
    order_picking_batch?: IOrderPickingBatch;
    dispatch_job?: IDispatchJob;
    stagger_job_item?: IOrderPickingShipmentBins | IOrderPickingShipmentProducts;
    address?: IAddress;
    orderAddress?: IOrderAddress;
    giftCard?: IUserGiftCard;
    shipment?: IOrderShipment;
  };
  headers: { platform?: UserTypeEnum };
  user?: IUserToken;
}

export interface IEncryptPassword {
  password_hash: string;
  password_salt: string;
}

export interface IPagination {
  activePage: number;
  viewMinPages: boolean;
  viewMaxPages: boolean;
  intermediatePages: number[];
}

export interface IImageDimension {
  width: number;
  height: number;
}

export interface IFileSize extends IImageDimension {
  size: string;
}

export interface ICommonOption<T = number> {
  id: T;
  name: string;
}

export interface IBarcode {
  code: string;
  label?: string;
}

export interface ISort {
  sortBy: string;
  sort: SortOrderEnum;
}

export interface IFilterEvent {
  orginialEvent: InputEvent;
  filter: string;
}

export interface IBaseListData {
  page: number;
  searchValue: string;
  id: number | string;
}

export interface IBaseListPayload extends IBaseListData {
  [index: string]: string | number | number[];
}

export interface ICommonName {
  name: string;
}

export interface IAuthenticationOthers {
  roles?: OperationalEmployeeRoleEnum[];
  is_guest?: boolean;
}

export interface IAxiosResponse<T> {
  status: number;
  data: T;
  statusText?: string;
}

export interface IAxiosError<T> {
  response: IAxiosResponse<T>;
}

export interface IFacebookGraphResponseData {
  data: {
    is_valid: boolean;
    user_id: string;
  };
}

export interface IGoogleAuthResponseData {
  sub: string;
}

export interface ISmsRequestBody {
  messageText: string;
  senderName: string;
  messageType: string;
  recipients: string;
  clientMessageId?: number;
  deliveryTime?: string;
  dlrUrl?: string;
  dataCoding?: string;
  shortURL?: boolean;
}

interface ISmsId {
  SMSId: string;
}

export interface ISendSmsSuccessResponse {
  SentSMSIDs: ISmsId[];
  InvalidRecipients: string;
}

export interface ISendSmsResponse {
  replyCode: number;
  replyMessage: string;
  requestId: string;
  clientRequestId: number;
  requestTime: string;
  data?: ISendSmsSuccessResponse;
  error?: ISendSmsErrorResponse;
}

export interface IAssignedJobsCount {
  total_jobs: number;
  completed_jobs: number;
}

export interface IQueueMessage<T> {
  attempt: number;
  payload: T;
  identifierId: number;
}

interface ISendSmsErrorResponse {
  status: number;
  description: string;
  internalErrors: Array<{ code: number; details: string }>;
}

export interface IOrderFreshDeskSummary {
  state: string;
  number: number;
  href: string;
  completed_at: string;
  total: number;
}

export interface IFreshDeskOrderResponse {
  orders: IOrderFreshDeskSummary[];
}

export enum VisibleFilterEnum {
  ACTIVE = 'yes',
  INACTIVE = 'no'
}

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum FormControlPopulateType {
  NEW = 'new',
  PREFILL = 'prefill'
}

export enum LocaleEnum {
  ENGLISH = 'en-US',
  ARABIC = 'ar-SA'
}

export enum WmsBarcodeDownloadTypeEnum {
  ALL = 'all',
  SELECTION = 'selection',
  FILTERED = 'filtered'
}

export enum StatusEnum {
  ACTIVE = 'true',
  DEACTIVE = 'false'
}

export type TranslationFields = {
  title: string;
  selector: string;
  en: string;
  ar: string;
};

export interface PageChangeEvent {
  page: number;
}

export type FilterPayloadType = IKeyMapping<string | number | boolean | string[] | number[]>;

export const BaseSelect = {
  id: true,
  name: true
};

export const defaultPaginationDetails: IPagination = {
  activePage: 1,
  viewMaxPages: true,
  viewMinPages: false,
  intermediatePages: Array.from({ length: 3 }, (_, index) => index + 3)
};

export const statusOptions: ICommonOption<StatusEnum>[] = [
  {
    id: StatusEnum.ACTIVE,
    name: 'Active'
  },
  {
    id: StatusEnum.DEACTIVE,
    name: 'Deactive'
  }
];

export interface IGrpcCommonSuccessRes {
  success: boolean;
}

export const transformInterceptorExcludePath: string[] = ['/api/auth/page/verify-email', '/api/freshdesk/orders'];

export enum EmployeeAvailabilityEnum {
  ON_JOB = 'on-job',
  AVAILABLE = 'available'
}

export const employeeAvailabilityOptions: ICommonOption<EmployeeAvailabilityEnum>[] = [
  {
    id: EmployeeAvailabilityEnum.ON_JOB,
    name: 'On Job'
  },
  {
    id: EmployeeAvailabilityEnum.AVAILABLE,
    name: 'Available'
  }
];
