import { IKeyMapping } from '../key-mapping.interface';

export interface ITranslation {
  id: number;
  key: string;
  value: string;
  language_id: number;
}

export interface ITranslationData {
  id: number;
  language_name: string;
  language_code: string;
  flag_url?: string;
}

export interface ITranslationResponse<T> {
  contentful_url: string;
  list: T[];
}

export interface ICreateTranslation {
  id: IKeyMapping<number>;
}

export interface ITranslationField {
  key: string;
  label: string;
}

export interface IAddTranslation {
  key: IKeyMapping;
  value: IKeyMapping;
}

export enum ContentfulAppModelsEnum {
  CONSUMER_APP_TRANSLATION_LABELS = 'consumerAppTranslations',
  OPERATIONAL_APP_TRANSLATION_LABELS = 'operationalAppTranslationLabels',
  SUPERMARKET_TRANSLATION = 'supermarket'
}
