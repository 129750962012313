import { IBaseAudit } from './auditable.interface';

export interface IFeedback extends IBaseAudit {
  user_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  mobile_number: string;
  city: string;
  purchase_date?: Date;
  invoice_number?: string;
  message: string;
  images?: IFeedbackImage[];
}

export interface IFeedbackImage extends IBaseAudit {
  feedback_id: number;
  image_url: string;
  feedback?: IFeedback;
}

export interface IFaqCategory {
  order: number;
  name: string;
}

export interface IFaq {
  category: IFaqCategory;
  question: string;
  answer: string;
  order: number;
}

export interface IFaqFields {
  category?: { fields: IFaqCategory };
  question: string;
  answer: string;
  order: number;
}

export interface IFaqList {
  categories?: IFaqCategory[];
  list: IFaq[];
}
