import { IAssignedJobsCount } from './common.interface';
import { IEmployeeData } from './employee.interface';
import { IVehicleManagement } from './vehicle-management.interface';

export interface IWmsDriverWithJobsCount {
  driver: IEmployeeData;
  vehicle: IVehicleManagement;
  regular_delivery_count?: number;
  express_delivery_count?: number;
}

interface IWmsDriverWithJobsCountData {
  supermarket_id: number;
  page: number;
  name: string;
  availability: string;
  vehicle_type: string;
  vehicle_capacity: string;
}

export interface IWmsDriverWithJobsCountListPayload extends IWmsDriverWithJobsCountData {
  [index: string]: string | number;
}

export interface IGrpcGetDriversReq {
  driver_ids: number[];
}

export interface IGrpcGetDriversRes {
  drivers: IEmployeeData[];
}

export interface IDriverAssignedJobs {
  dispatch_jobs: IAssignedJobsCount;
}
