import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from '../common.interface';
import { IEmployeeData } from '../employee.interface';

export interface IOrderShipmentReport extends IBaseAudit {
  order_shipment_id: number;
  reporter_id: number;
  reason_id: number;
  comments: string;
  image_url?: string;
  reason?: IOrderShipmentReportReason;
  reporter?: IEmployeeData;
}

export interface IOrderShipmentReportReason extends IBaseAudit {
  name?: string;
  name_ar?: string;
}

export const orderShipmentReportReasonBaseListSelect = {
  ...BaseSelect
};

export const orderShipmentReportSelect = {
  reporter_id: true,
  reason_id: true,
  comments: true,
  image_url: true,
  created_at: true,
  reason: { name: true }
};
