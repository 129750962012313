import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { ApiService } from '../index';
import { ServerSendEvent } from './server-event.service';

import {
  IBulkProductUploadPayload,
  IBundleProductsCsv,
  IProductTaxonsCsv,
  IInventoryModifiersCsv,
  IJobEntity,
  IMetadataCsv,
  INutritionalFactsCsv,
  IProductUpdateCsv,
  IProductUploadCsv,
  IOfflineProductCsv,
  IResponse,
  IProductLocationAndStockCsv
} from '@ecommerce/common-types';

@Injectable({ providedIn: 'root' })
export class BulkUploadService {
  constructor(private api: ApiService, private serverSendEvent: ServerSendEvent) {}

  public uploadFile(file: File, event: string): Observable<IResponse<string>> {
    const payload: FormData = new FormData();

    payload.append('event', event);
    payload.append('file', file);

    return this.api.post<IResponse<string>, FormData>('bulk-upload/file', payload);
  }

  public listenServerEvents(event: string): Observable<string> {
    const path = `bulk-upload/event?event=${event}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public validateBulkProductUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/product-upload?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkProductUpload(payload: IBulkProductUploadPayload<IProductUploadCsv>): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IProductUploadCsv>>(
      'bulk-upload/job/product-upload',
      payload
    );
  }

  public validateBulkProductUpdate(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/product-update?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkProductUpdate(payload: IBulkProductUploadPayload<IProductUpdateCsv>): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IProductUpdateCsv>>(
      'bulk-upload/job/product-update',
      payload
    );
  }

  public validateMetadataUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/metadata?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkMetadataUpload(payload: IBulkProductUploadPayload<IMetadataCsv>): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IMetadataCsv>>(
      'bulk-upload/job/metadata',
      payload
    );
  }

  public validateNutritionalFactsUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/nutritional-facts?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkNutritionalFactsUpload(
    payload: IBulkProductUploadPayload<INutritionalFactsCsv>
  ): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<INutritionalFactsCsv>>(
      'bulk-upload/job/nutritional-facts',
      payload
    );
  }

  public validateInventoryModifiersUpload(
    fileUrl: string,
    countryId: number,
    supermarketIds: string
  ): Observable<string> {
    const path = `bulk-upload/job/validate/inventory-modifiers?file_url=${fileUrl}&country_id=${countryId}&supermarket_ids=${supermarketIds}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkInventoryModifiersUpload(
    payload: IBulkProductUploadPayload<IInventoryModifiersCsv>
  ): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IInventoryModifiersCsv>>(
      'bulk-upload/job/inventory-modifiers',
      payload
    );
  }

  public validateBundleProductsUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/bundle-products?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkBundleProductsUpload(
    payload: IBulkProductUploadPayload<IBundleProductsCsv>
  ): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IBundleProductsCsv>>(
      'bulk-upload/job/bundle-products',
      payload
    );
  }

  public validateProductTaxonsUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/product-taxons?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkProductTaxonsUpload(payload: IBulkProductUploadPayload<IProductTaxonsCsv>): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IProductTaxonsCsv>>(
      'bulk-upload/job/product-taxons',
      payload
    );
  }

  public validateOfflineProductsUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/validate/offline-products?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkOfflineProductsUpload(
    payload: IBulkProductUploadPayload<IOfflineProductCsv>
  ): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IOfflineProductCsv>>(
      'bulk-upload/job/offline-products',
      payload
    );
  }

  public bulkProductDeeplinksUpload(fileUrl: string, countryId: number): Observable<string> {
    const path = `bulk-upload/job/deeplink?file_url=${fileUrl}&country_id=${countryId}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public validateProductLocationAndStockUpload(
    fileUrl: string,
    supermarket_id: number,
    zone_id: number
  ): Observable<string> {
    const path = `bulk-upload/job/validate/product-location-and-stock?file_url=${fileUrl}&supermarket_id=${supermarket_id}&zone_id=${zone_id}`;
    return this.serverSendEvent.listenServerEvent(path);
  }

  public bulkProductLocationAndStockUpload(
    payload: IBulkProductUploadPayload<IProductLocationAndStockCsv>
  ): Observable<IResponse<string>> {
    return this.api.post<IResponse<string>, IBulkProductUploadPayload<IProductLocationAndStockCsv>>(
      'bulk-upload/job/product-location-and-stock',
      payload
    );
  }

  public listJobEntities(): Observable<IResponse<IJobEntity[]>> {
    return this.api.get<IResponse<IJobEntity[]>>('bulk-upload/job-entities');
  }
}
