import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { countryCodes } from '../../../common/service/country-codes';
import { countryNames } from '../../../common/service/country-names';
import {
  constants,
  AppAbility,
  IKeyMapping,
  IEventMethod,
  ICountryCode,
  ItoastListener,
  FilterPayloadType,
  PermissionActionEnum,
  PermissionModuleEnum
} from '@ecommerce/common-types';

interface IToastConfig {
  title?: string;
  message: string;
  action?: () => void;
  hideIcon? : boolean;
  actionLabel? : string;
}

@Injectable({ providedIn: 'root' })
export class RootService {
  private toasterObservable = new BehaviorSubject<ItoastListener>({
    is_error: false,
    show: false
  });
  public toggleObservable: BehaviorSubject<boolean>;
  public toggleObserv: Observable<boolean>;

  public PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,30}$/;
  public NON_WHITE_SPACE_REG_EXP = /\S/;
  public LAT_LONG_REGEXP = `^-?\\d*\\.{0,1}\\d+$`;
  public CURRENCY_REGEXP = `^\\d+(\\d*|.\\d+)$`;
  public PERCENTAGE_REGEXP = `^\\d+(\\d*|.\\d+)%$`;
  public EMAIL_DOMAIN_REGEXP = '^([\\w-.]+\\.[A-Za-z]{2,}(\\s*,?\\s*)*)+$';
  public BIN_NUMBER_REGEXP = '^([0-9]{6,8}(\\s*,?\\s*)*)+$';

  constructor(
    private appAbility: AppAbility,
    private location: Location,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {
    this.toggleObservable = new BehaviorSubject<boolean>(!this.deviceService.isDesktop());
    this.toggleObserv = this.toggleObservable.asObservable();
  }

  public toastListener(): Observable<ItoastListener> {
    return this.toasterObservable.asObservable();
  }

  public showToast(message: string = constants.DEFAULT_ERROR_MESSAGE, isError: boolean = false): void {
    this.toasterObservable.next({
      is_error: isError,
      message,
      show: true
    });
  }

  public showActionToast(toastConfig: IToastConfig, isError: boolean = false): void {
    this.toasterObservable.next({
      is_error: isError,
      show: true,
      ...toastConfig
    });
  }

  public onBack(): void {
    this.location.back();
  }

  public MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const matchControl = control.get(controlName);
      const matchingControl = control.get(matchingControlName);
      if (matchingControl?.errors && !matchingControl.errors['mustMatch']) return null;

      return matchControl && matchingControl && matchControl.value !== matchingControl.value
        ? { mustMatch: true }
        : null;
    };
  }

  public logOut(): void {
    localStorage.clear();
    this.appAbility.update([]);
    this.router.navigate(['/login']);
  }

  public getSerialNumber(page: number, limit: number, rowIndex: number): string {
    const serialNumber: number = rowIndex + 1 + (page - 1) * limit;
    return serialNumber < 10 ? '0' + serialNumber : serialNumber.toString();
  }

  public listCountryCodes(): ICountryCode[] {
    const countryCode: ICountryCode[] = [];
    Object.keys(countryCodes).forEach((key): void => {
      const countryName: string = countryNames[key];
      countryCode.push({ countryDialCode: key, name: countryName });
    });
    countryCode.sort((a, b): number => {
      const code1: number = parseInt(a.countryDialCode.slice(1));
      const code2: number = parseInt(b.countryDialCode.slice(1));
      return code1 > code2 ? 1 : -1;
    });
    return countryCode;
  }

  public checkSelectedFormData<T, T1, T2 = string>(
    selectedFormData: T,
    formValue: string,
    emptyReturnData: T1
  ): T1 | T2 {
    const formData: IKeyMapping<T2> = Object.assign({}, selectedFormData);
    return formData?.[formValue] ? formData[formValue] : emptyReturnData;
  }

  public trimText(formControl: AbstractControl): void {
    if (formControl?.value) formControl.patchValue(formControl.value?.replace(/\s\s+/g, ' ')?.trim());
  }

  public trimLeadingZeros(formControl: AbstractControl): void {
    if (formControl?.value) {
      const value = formControl.value.toString();
      formControl.patchValue(
        value.includes('-')
          ? value.replace(/^-0+/g, '-').replace(/(^-\.)/g, '-0.')
          : value.replace(/^0+/g, '').replace(/(^\.)/g, '0.')
      );
    }
  }

  public keyPressCheck(event: IEventMethod, regExp: string): void {
    const pattern = new RegExp(regExp);
    const inputChar: string = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) event.preventDefault();
  }

  public abilityCheck(action: PermissionActionEnum, isAllCheck: boolean, ...modules: PermissionModuleEnum[]): boolean {
    let canAccess: boolean = isAllCheck;
    modules.forEach((module: PermissionModuleEnum): void => {
      if (isAllCheck && this.appAbility.cannot(action, module)) canAccess = false;
      else if (!isAllCheck && this.appAbility.can(action, module)) canAccess = true;
    });

    return canAccess;
  }

  public copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
  }

  public getFiltersPayloadByQueryParams<T extends Record<string, string>, S extends FilterPayloadType>(
    gridFilterEnum: T,
    payload: S,
    queryParams: IKeyMapping<string>
  ): S {
    for (const key of Object.values(gridFilterEnum)) {
      payload = {
        ...payload,
        [key]: Number(queryParams[key]) ? Number(queryParams[key]) : queryParams[key] ?? ''
      };
    }

    return payload;
  }

  public updateUrlQueryParams<T extends Record<string, string>, S extends FilterPayloadType>(
    gridFilterEnum: T,
    payload: S,
    path: string,
    pagination = true
  ): void {
    if (!pagination || payload?.['page']) {
      const queryParams: IKeyMapping<string> = {};

      for (const key of Object.values(gridFilterEnum)) {
        const value: string = payload[key]?.toString() ?? '';

        if (value) queryParams[key] = value;
      }

      this.router.navigate([], { queryParams });
    } else {
      const route: string = pagination ? `${path}?page=1` : path;
      this.location.replaceState(route);
      this.router.navigateByUrl(route, { replaceUrl: true });
    }
  }

  public responseMsgPluralize(responseMsg: string, nounToPluralize: string): string {
    const msgSubStrings = responseMsg.split(nounToPluralize);
    msgSubStrings[0] = msgSubStrings[0] + nounToPluralize + 's are';

    return msgSubStrings.join('');
  }

  public calculateEAN13CheckDigit(digits: number[]): number | undefined {
    if (digits.length !== 12 || !digits.every((d: number): boolean => Number.isInteger(d) && d >= 0 && d <= 9)) {
      return;
    }

    let sum = 0;
    for (let i = 0; i < 12; i++) {
      const weight = i % 2 === 0 ? 1 : 3;
      sum += digits[i] * weight;
    }
    const checkDigit: number = (10 - (sum % 10)) % 10;

    return checkDigit;
  }
}
