import { IBaseAudit } from '../auditable.interface';
import { ISupplier } from '../product/supplier.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IProduct, IProductBaseList } from '../product/product.interface';
import { ICommonOption } from '../common.interface';
import { IWmsReceiving } from './wms-receiving.interface';

export interface IWmsPurchaseOrder extends IBaseAudit {
  supermarket_id: number;
  supplier_id: number;
  status: WmsPurchaseOrderStatusEnum;
  super_market: ISuperMarket;
  supplier: ISupplier;
  fulfilled_at?: Date;
  purchase_order_products?: IWmsPurchaseOrderProduct[];
  receiving?: IWmsReceiving[];
}

export interface IWmsPurchaseOrderProduct extends IBaseAudit {
  product_id: number;
  units: number;
  purchase_order_id: number;
  product?: IProduct;
}

export interface IWmsPurchaseOrderProductDisplay extends IProductBaseList {
  units: number;
}

export interface IWmsPurchaseOrderListData {
  page: number;
  supplier_id: number;
  supermarket_id: number;
  id: string;
  status: string;
  created_at: string;
  fulfilled_at: string;
}

export interface IWmsPurchaseOrderListPayload extends IWmsPurchaseOrderListData {
  [index: string]: string | number;
}

export interface IWmsPoBaseListPayload {
  status: string;
  [index: string]: string | number;
}

export interface IGrpcViewPurchaseOrderReq {
  supplierId: number;
  productIds: number[];
}

export interface IGrpcViewPurchaseOrderResponse {
  supplier: ISupplier;
  products: IProduct[];
}

export enum WmsPurchaseOrderStatusEnum {
  RAISED = 'raised',
  FULFILLED = 'fulfilled',
  PARTIALLY_FULFILLED = 'partially-fulfilled',
  CANCELLED = 'cancelled',
  DRAFT = 'draft'
}

export enum WmsPurchaseOrderGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  STATUS = 'status',
  SUPPLIER_ID = 'supplier_id',
  SUPERMARKET_ID = 'supermarket_id',
  CREATED_AT = 'created_at',
  FULFILLED_AT = 'fulfilled_at'
}

export const wmsPurchaseOrderBaseListSelect = {
  id: true,
  created_at: true,
  status: true
};

export const wmsPurchaseOrderSelect = {
  id: true,
  supermarket_id: true,
  supplier_id: true,
  fulfilled_at: true,
  created_at: true,
  status: true
};

export const wmsViewPurchaseOrderSelect = {
  ...wmsPurchaseOrderSelect,
  purchase_order_products: {
    id: true,
    product_id: true,
    units: true
  }
};

export const wmsPurchaseOrderUpdateStatusSelect = {
  id: true,
  status: true,
  fulfilled_at: true,
  purchase_order_products: {
    id: true,
    product_id: true
  },
  receiving: {
    id: true,
    receiving_products: {
      product_id: true
    }
  }
};

export const wmsPurchaseOrderStatusOptions: ICommonOption<WmsPurchaseOrderStatusEnum>[] = [
  {
    id: WmsPurchaseOrderStatusEnum.RAISED,
    name: 'Raised'
  },
  {
    id: WmsPurchaseOrderStatusEnum.FULFILLED,
    name: 'Fulfilled'
  },
  {
    id: WmsPurchaseOrderStatusEnum.PARTIALLY_FULFILLED,
    name: 'Partially Fulfilled'
  },
  {
    id: WmsPurchaseOrderStatusEnum.CANCELLED,
    name: 'Cancelled'
  },
  {
    id: WmsPurchaseOrderStatusEnum.DRAFT,
    name: 'Draft'
  }
];
