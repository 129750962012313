export default {
  ALPHA_DASH_REGEXP: '^[a-zA-Z-]*$',
  ALPHA_REGEXP: '^[a-zA-Z]*$',
  ALPHA_SPACE_REGEXP: '^[a-zA-Z ]*$',
  ALPHANUM_REGEXP: '^[a-zA-Z0-9]*$',
  ALPHANUM_COMMA_REGEXP: '^[a-zA-Z0-9,]*$',
  ALPHANUM_COMMA_DOT_REGEXP: '^[a-zA-Z0-9,.]*$',
  ALPHANUM_SPACE_REGEXP: '^[a-zA-Z0-9 ]*$',
  ALPHANUM_SPACE_DASH_REGEXP: '^[a-zA-Z0-9 -]*$',
  ALPHANUM_SPECIAL_CHAR_REGEXP: '^[a-zA-Z0-9!"#$%&\'()\\\\*+,-./:;<=>?@[\\]^_`{|}~“”‘’–]+$',
  ALPHANUM_SPECIAL_CHAR_SPACE_REGEXP: '^[a-zA-Z0-9\\s!"#$%&\'()\\\\*+,-./:;<=>?@[\\]^_`{|}~“”‘’–]+$',
  ARABIC_ENGLISH_ALPHANUM_SPECIAL_CHAR_SPACE_REGEXP:
    '^[\u0600-\u06FFa-zA-Z0-9\\s!"#$%&\'()\\\\*+,-./:;<=>?@[\\]^_`{|}~“”‘’–]+$',
  ARABIC_ALPHA_SPACE_REGEXP: '^[\u0600-\u06FF ]*$',
  ARABIC_ALPHANUM_REGEXP: '^[\u0600-\u06FF0-9]*$',
  ARABIC_ALPHANUM_DASH_REGEXP: '^[\u0600-\u06FF-0-9]*$',
  ARABIC_ALPHANUM_SPACE_REGEXP: '^[\u0600-\u06FF0-9 ]+$',
  ARABIC_ALPHANUM_SPECIAL_CHAR_SPACE_REGEXP: '^[\u0600-\u06FF0-9\\s!"#$%&\'()\\\\*+,-./:;<=>?@[\\]^_`{|}~“”‘’–]+$',
  BUNDLE_SKU_REQEXP: '^[a-zA-Z0-9]+-[1-9][0-9]*$',
  CAP_ALPHANUM_8_REGEXP: '^[A-Z0-9]{8}$',
  COORDINATES_REGEXP: '^[-0-9.]*$',
  DECIMAL_NUM_REGEXP: '^[0-9.]*$',
  MATCH_PERCENTAGE: '^(100(\\.0{1,2})?|[1-9]\\d?(\\.\\d{1,2})?|\\.\\d{1,2}|0(\\.\\d{1,2})?)$',
  MOBILE_REGEXP: '^[0-9 +]*$',
  NUM_DECIMAL_PLACES_REGEXP: '^\\d+(\\.\\d{0,<decimal_places>})?$',
  NUM_REGEXP: '^[0-9]*$',
  NUM_COMMA_REGEXP: '^[0-9,]*$',
  REMOVE_SPACE_REGEXP: /\s+/g,
  TIME_24_HOURS_REGEXP: '^([01]\\d|2[0-3]):([0-5]\\d)$'
};
