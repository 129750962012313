import CommonConstants from './constants/common.constant';
import ResponseConstants from './constants/response.constant';
import ValidationConstants from './constants/validation-message.constant';
import RepositoryConstants from './constants/repository.constant';
import ContentfulConstants from './constants/contentful.constant';
import PackageConstant from './constants/package.constant';
import validationRegExpConstant from './constants/validation-reg-exp.constant';
import fileRelativePathConstant from './constants/file-relative-path.constants';
import infoTooltipText from './constants/info-tooltip-text.constant';
import emailContent from './constants/email-content.constant';
import smsContent from './constants/sms-content.constant';
import philosophicConstants from './constants/philosophic.constants';
import queueConstants from './constants/queue.constants';
import valusConstants from './constants/valus.constants';
import toWordsConstants from './constants/to-words.constants';

export const constants = {
  ...CommonConstants,
  ...ResponseConstants,
  ...ValidationConstants,
  ...RepositoryConstants,
  ...ContentfulConstants,
  ...PackageConstant,
  ...validationRegExpConstant,
  ...fileRelativePathConstant,
  ...emailContent,
  ...smsContent,
  ...philosophicConstants,
  ...queueConstants,
  ...valusConstants,
  ...toWordsConstants,
  validationRegExpConstant,
  infoTooltipText
};
