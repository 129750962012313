import { ISuperMarket } from './supermarket/super-market.interface';
import { IEmployeeData } from './employee.interface';
import { IBaseAudit } from './auditable.interface';
import { ICommonOption } from './common.interface';

export interface IVehicleManagement extends IBaseAudit {
  type: VehicleTypeEnum;
  brand_name: VehicleBrandEnum;
  plate_number: string;
  manufacturer_year: number;
  supermarket_id: number;
  capacity_bins: number;
  vehicle_last_maintenance_date: Date;
  vehicle_next_maintenance_date: Date;
  vehicle_last_maintenance_kms: number;
  vehicle_next_maintenance_kms: number;
  chiller_last_maintenance_date: Date;
  chiller_next_maintenance_date: Date;
  chiller_last_maintenance_kms: number;
  chiller_next_maintenance_kms: number;
  permit_registration_validation_date: Date;
  insurance_validation_date: Date;
  vehicle_joining_ops_date: Date;
  active: boolean;
  assigned_drivers?: IEmployeeData[];
  supermarket?: ISuperMarket;
  vehicle_health_evidence?: IVehicleHealthEvidence;
}

export interface IVehicleHealthEvidence extends IBaseAudit {
  driver_id: number;
  vehicle_id: number;
  images?: IVehicleHealthEvidenceImage[];
  vehicle?: IVehicleManagement;
  driver?: IEmployeeData;
}

export interface IVehicleHealthEvidenceImage extends IBaseAudit {
  vehicle_health_evidence_id: number;
  image_url: string;
  title: string;
  order: number;
}

export interface IGrpcVehicleDetailsRequest {
  employeeId: number;
  vehicleIds?: number[];
}

export interface IGrpcVehicleDetailsResponse {
  supermarkets: ISuperMarket[];
  employees: IEmployeeData[];
}

export interface IVehicleListData {
  page: number;
  id: string;
  type: string;
  brand_name: string;
  plate_number: string;
  manufacturer_year: string;
  supermarket_id: number;
  city_id: number;
  driver_id: number;
  capacity_bins: number;
  active: string;
}

export interface IGrpcGetVehicleIdReq {
  driver_id: number;
}

export interface IGrpcGetVehicleIdRes {
  vehicle_id: number;
}

export interface IVehicleListPayload extends IVehicleListData {
  [index: string]: string | number;
}

export interface IVehicleHealthEvidenceListData {
  page: number;
  id: string;
  driver_id: number;
  plate_number: string;
  created_at: string;
}

export interface IVehicleHealthEvidenceListPayload extends IVehicleHealthEvidenceListData {
  [index: string]: string | number;
}

export enum VehicleTypeEnum {
  ELECTRIC_SCOOTER = 'electric-scooter',
  MOTORBIKE = 'motorbike',
  MINI_SEDAN = 'mini-sedan',
  MID_VAN = 'mini-van',
  VIG_VAN = 'vig-van',
  T3_TRUCK = '3t-truck'
}

export enum VehicleBrandEnum {
  TOYOTO = 'toyoto',
  HONDA = 'honda',
  HYUNDAI = 'hyundai'
}

export enum VehicleGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  TYPE = 'type',
  BRAND_NAME = 'brand_name',
  PLATE_NUMBER = 'plate_number',
  MANUFACTURER_YEAR = 'manufacturer_year',
  SUPERMARKET_ID = 'supermarket_id',
  CITY_ID = 'city_id',
  DRIVER_ID = 'driver_id',
  CAPACITY_BINS = 'capacity_bins',
  ACTIVE = 'active'
}

export enum VehicleHealthEvidenceGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  DRIVER_ID = 'driver_id',
  PLATE_NUMBER = 'plate_number',
  CREATED_AT = 'created_at'
}

export const vehicleTypeOptions: ICommonOption<VehicleTypeEnum>[] = [
  {
    id: VehicleTypeEnum.ELECTRIC_SCOOTER,
    name: 'Electric Scooter'
  },
  {
    id: VehicleTypeEnum.MOTORBIKE,
    name: 'Motor Bike'
  },
  {
    id: VehicleTypeEnum.MINI_SEDAN,
    name: 'Mini Sedan'
  },
  {
    id: VehicleTypeEnum.MID_VAN,
    name: 'Mini Van'
  },
  {
    id: VehicleTypeEnum.VIG_VAN,
    name: 'Vig Van'
  },
  {
    id: VehicleTypeEnum.T3_TRUCK,
    name: '3T Truck'
  }
];

export const vehicleBrandOptions: ICommonOption<VehicleBrandEnum>[] = [
  {
    id: VehicleBrandEnum.TOYOTO,
    name: 'Toyoto'
  },
  {
    id: VehicleBrandEnum.HONDA,
    name: 'Honda'
  },
  {
    id: VehicleBrandEnum.HYUNDAI,
    name: 'Hyundai'
  }
];

export const vehicleHealthEvidenceSelect = {
  id: true,
  created_at: true,
  vehicle_id: true,
  driver_id: true,
  vehicle: { plate_number: true }
};

export const vehicleManagementSelect = {
  id: true,
  type: true,
  brand_name: true,
  plate_number: true,
  manufacturer_year: true,
  supermarket_id: true,
  capacity_bins: true,
  vehicle_last_maintenance_date: true,
  vehicle_next_maintenance_date: true,
  chiller_last_maintenance_date: true,
  chiller_next_maintenance_date: true,
  vehicle_last_maintenance_kms: true,
  vehicle_next_maintenance_kms: true,
  chiller_last_maintenance_kms: true,
  chiller_next_maintenance_kms: true,
  permit_registration_validation_date: true,
  insurance_validation_date: true,
  vehicle_joining_ops_date: true,
  active: true
};
