import { IBaseAudit } from '../auditable.interface';
import { IInventoryModifiers } from './inventory-modifiers.interface';
import { IProduct } from './product.interface';

export interface IProductPriceVersion extends IBaseAudit {
  inventory_modifier_id: number;
  supermarket_id: number;
  user_id: number;
  product_id: number;
  variant_id?: number;
  object_changes?: string;
  sale_price?: number;
  updated_sale_price?: number;
  action_price?: number;
  updated_action_price?: number;
  event: ProductPriceVersionEventTypeEnum;
  product?: IProduct;
  inventory_modifier?: IInventoryModifiers;
}

export enum ProductPriceVersionEventTypeEnum {
  CREATE = 'create',
  UPDATE = 'update'
}
