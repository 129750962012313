import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IWmsLocation } from './wms-location.interface';
import { IWmsStockLocation } from './wms-stock-management.interface';
import { BaseSelect } from './../common.interface';

export interface IWmsZone extends IBaseAudit {
  name: string;
  supermarket_id: number;
  type: WmsZoneTypeEnum;
  super_market?: ISuperMarket;
  is_selected?: boolean;
  stock?: IWmsStockLocation;
  locations?: IWmsLocation[];
  stocks?: IWmsStockLocation[];
  barcode_label?: string;
}

export interface IWmsZoneListData {
  page: number;
  id: string;
  name: string;
  supermarket_id: number;
  type: string;
}

export interface IWmsZoneListPayload extends IWmsZoneListData {
  [index: string]: string | number;
}

export interface IWmsZoneBaseListPayload {
  [index: string]: string | number[];
}

export interface IWmsDownloadZoneBarcodePayload {
  [index: string]: string | number | number[];
}

export enum WmsZoneGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  SUPERMARKET_ID = 'supermarket_id',
  TYPE = 'type',
  NAME = 'name'
}

export enum WmsZoneTypeEnum {
  STAGING = 'staging',
  DISPATCH = 'dispatch',
  AISLE = 'aisle',
  RECEIVING = 'receiving',
  VAN_COMPARTMENT = 'van-compartment'
}

export const wmsZoneTypeOptions: ICommonOption<WmsZoneTypeEnum>[] = [
  {
    id: WmsZoneTypeEnum.STAGING,
    name: 'Staging'
  },
  {
    id: WmsZoneTypeEnum.DISPATCH,
    name: 'Dispatch'
  },
  {
    id: WmsZoneTypeEnum.AISLE,
    name: 'Aisle'
  },
  {
    id: WmsZoneTypeEnum.RECEIVING,
    name: 'Receiving'
  },
  {
    id: WmsZoneTypeEnum.VAN_COMPARTMENT,
    name: 'Van Compartment'
  }
];

export const wmsZoneSelect = {
  ...BaseSelect,
  supermarket_id: true,
  type: true
};
