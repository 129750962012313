import { IBaseAudit } from '../auditable.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { ISupplier } from '../product/supplier.interface';
import { IProduct } from '../product/product.interface';
import { IWmsPurchaseOrder } from './wms-purchase-order.interface';
import { IWmsProductReport } from './wms-product-report.interface';
import { IWmsZone } from './wms-zone.interface';
import { IWmsLocation } from './wms-location.interface';

export interface IWmsReceiving extends IBaseAudit {
  supermarket_id: number;
  zone_id: number;
  location_id: number;
  supplier_id: number;
  invoice_number: string;
  invoice_date: Date;
  invoice_total_quantity: number;
  invoice_total_value: number;
  purchase_order_id?: number;
  invoice_file?: string;
  invoice_filename?: string;
  super_market?: ISuperMarket;
  supplier?: ISupplier;
  purchase_order?: IWmsPurchaseOrder;
  zone?: IWmsZone;
  location?: IWmsLocation;
  receiving_products?: IWmsReceivingProduct[];
}

export interface IWmsReceivingProduct {
  receiving_id: number;
  product_id: number;
  received: number;
  qc_passed_count: number;
  qc_failed_count: number;
  batch_number: string;
  expiry_date?: Date | string;
  product_report_id?: number;
  product?: IProduct;
  product_report?: IWmsProductReport;
}

export interface IWmsReceivingProductDetailed extends IWmsReceivingProduct {
  name?: string;
  sku?: string;
}

export interface IWmsReceivingListData {
  page: number;
  id: string;
  purchase_order_id: string;
  supermarket_id: number;
  supplier_id: number;
  invoice_number: string;
  invoice_date: string;
  invoice_total_quantity: number;
  invoice_total_value: number;
}

export interface IWmsReceivingListPayload extends IWmsReceivingListData {
  [index: string]: string | number;
}

export enum WmsReceivingGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  PURCHASE_ORDER_ID = 'purchase_order_id',
  SUPERMARKET_ID = 'supermarket_id',
  SUPPLIER_ID = 'supplier_id',
  INVOICE_NUMBER = 'invoice_number',
  INVOICE_DATE = 'invoice_date',
  INVOICE_TOTAL_QUANTITY = 'invoice_total_quantity',
  INVOICE_TOTAL_VALUE = 'invoice_total_value'
}

export const wmsReceivingSelect = {
  id: true,
  purchase_order_id: true,
  supermarket_id: true,
  supplier_id: true,
  invoice_number: true,
  invoice_date: true,
  invoice_total_quantity: true,
  invoice_total_value: true
};

export const wmsReceivingDetailSelect = {
  id: true,
  purchase_order_id: true,
  supermarket_id: true,
  supplier_id: true,
  invoice_number: true,
  invoice_date: true,
  invoice_total_quantity: true,
  invoice_total_value: true,
  invoice_file: true,
  invoice_filename: true,
  purchase_order: {
    status: true
  },
  zone: { name: true },
  location: {
    aisle: true,
    bay: true,
    level: true,
    division: true
  },
  receiving_products: {
    product_id: true,
    received: true,
    qc_passed_count: true,
    qc_failed_count: true,
    batch_number: true,
    expiry_date: true,
    product_report: {
      comments: true,
      product_report_reason: { name: true }
    }
  }
};
