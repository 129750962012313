import { IAuditable } from './auditable.interface';
import { ISuperMarket } from './supermarket/super-market.interface';
import { IRole } from './role.interface';
import { IAddress } from './location/address.interface';
import { ILanguage } from './language.interface';
import { IGrpcUserOrderStatsRes } from './order/order.interface';

export interface ICustomer extends IAuditable {
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  country_code: string;
  mobile_number: string;
  dob?: Date;
  super_market_id?: number;
  super_market?: ISuperMarket;
  language_id?: number;
  created_at?: Date;
  mobile_verified?: boolean;
  currency?: string;
  blacklisted_reason?: string;
  blacklisted_at?: Date;
  addresses?: IAddress[];
}

export interface ICustomerDetails {
  customer: ICustomer;
  order?: IGrpcUserOrderStatsRes;
  language?: ILanguage;
}

export interface ICustomerListData {
  page: number;
  name: string;
  email: string;
  mobile_number: string;
  id: number[];
  super_market: string;
}

export interface ICustomerListPayload extends ICustomerListData {
  [index: string | number]: string | number | number[];
}

export interface ICustomerFilteredData {
  customer_list: [ICustomerId];
  role_list: [IRole];
  super_market_list: [ISuperMarket];
}

export interface ICustomerId {
  id: number;
}

export interface ICustomerFilterList {
  customer_list: ICustomer[];
  super_market_list: ISuperMarket[];
}

export interface IUserBlacklistPayload {
  blacklisted_reason?: string;
}

export enum CustomerGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  MOBILE_NUMBER = 'mobile_number'
}

export enum BlacklistEnum {
  FRADULENT_ACTIVITY = 'Fradulent activity',
  OTHER = 'Other'
}

export const customerDetailSelect = {
  id: true,
  first_name: true,
  last_name: true,
  email: true,
  country_code: true,
  mobile_number: true,
  created_at: true,
  blacklisted_at: true,
  addresses: true
};
