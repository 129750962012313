<div class="form-outline">
  <label class="m-b-15">File</label>
  <div
    ecommFileDragDrop
    *ngIf="!fileSelected || fileUploadErrMsg"
    class="file-drop-zone"
    (click)="fileUpload.click()"
    (filesChangeEmitter)="onFileSelect($event)"
  >
    <div class="text-wrapper">
      <div class="centered">
        <input
          data-testid="file-input"
          #fileUpload
          type="file"
          name="file"
          style="display: none"
          [accept]="acceptFileType"
          (click)="$event.target.value = null"
          (change)="onFileSelect($event.target.files)"
        />
        <img class="float-start" src="../../../assets/images/folder.svg" alt="folder" />
        <div class="content mt-4">Drag or drop your {{ fileTypeLabel }} file here</div>
        <div class="note">Select {{ fileTypeLabel }} file first</div>
      </div>
    </div>
  </div>

  <div *ngIf="fileSelected && !fileUploadErrMsg" class="static file-upload-progress">
    <div class="flex flex-row justify-content-between absolute z-5 w-full">
      <div class="flex flex-column justify-content-center pl-3 pt-5">
        <span class="fs-14">{{ uploadProgressValue === 100 ? "Uploading Complete" : "Uploading 1 file" }}</span>
        <span class="fs-13 font-light">{{ uploadProgressValue }}%</span>
      </div>
      <div class="pr-5 pt-7">
        <button type="button" icon="pi pi-times" class="btn-close" (click)="removeUploadedFile()">
          <i class="pi pi-times fs-15 font-bold"></i>
        </button>
      </div>
    </div>
    <p-progressBar class="z-0" [value]="uploadProgressValue" [showValue]="false"></p-progressBar>
    <p-progressBar class="z-3 progress-line" [value]="uploadProgressValue" [showValue]="false"></p-progressBar>
  </div>

  <div>
    <p *ngIf="!fileSelected" class="fs-13 mt-3 no-file-text">No file selected.</p>
    <p *ngIf="fileSelected && !fileUploadErrMsg && uploadProgressValue" class="fs-13 mt-3">
      {{ uploadedFileName }}
    </p>
    <p *ngIf="fileSelected && !fileUploadErrMsg && uploadProgressValue <= 0" class="fs-13 mt-4 no-file-text">
      Connecting to Server...
    </p>
  </div>
  <div class="error-msg">
    <p *ngIf="fileSelected && fileUploadErrMsg" class="fs-13 mt-3">{{ fileUploadErrMsg }}</p>
  </div>
</div>
