import { IBaseAudit } from '../auditable.interface';

export interface IUserGiftCard extends IBaseAudit {
  order_product_id: number;
  purchased_by: number;
  used_by?: number;
  number: string;
  transaction_id: string;
  barcode?: string;
  amount: number;
  activated?: boolean;
  referable?: boolean;
}

export interface IGiftCardCreationPayload {
  purchasedBy: number;
  order_product_id: number;
  amount: number;
}
export interface IPhilosophicAuthenticationReq {
  username: string;
  password: string;
}

export interface IPhilosophicBaseResponse {
  response_code: string;
  response_description: string;
}

export interface IPhilosophicDataResponse<T> extends IPhilosophicBaseResponse {
  response_body: T;
}

export interface IPhilosophicAuthenticationRes {
  access_token: string;
}

export interface IPhilosophicGiftCardCreationReq {
  value: string;
  reference: string;
}

export interface IPhilosophicGiftCardCreationRes {
  transaction_id: string;
  short_code: string;
}

export interface ICreateGiftCardTransactionalData extends IPhilosophicGiftCardCreationRes {
  amount: number;
}

export interface IPhilosophicGiftCardActivationReq extends IPhilosophicGiftCardCreationReq {
  tid: string;
  short_code: string;
}

export interface IPhilosophicLinkGiftCardReq {
  mobile_no: string;
  short_code: string;
}

export interface IPhilosophicLinkGiftCardRes {
  account_number: string;
}

export interface IPhilosophicGetGiftCardBalanceRes {
  balance: string;
  currency: string;
  expires: string;
  sequence_no: string;
  starting_balance: string;
  is_activated: boolean;
}

export interface IPhilosophicBeginTransactionReq {
  account_number: string;
  account_type: PhilosophicAccountTypeEnum;
  transaction_type: PhilosophicTransactionTypeEnum;
  value: string;
}

export interface IPhilosophicBeginTransactionRes {
  transaction_id: string;
}

export interface IPhilosophicProcessTransactionReq {
  account_number: string;
  account_type: PhilosophicAccountTypeEnum;
  transaction_type: PhilosophicTransactionTypeEnum;
  value: string;
  tid: string;
}

export enum PhilosophicTransactionTypeEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export enum PhilosophicAccountTypeEnum {
  WALLET = 'WALLET',
  GIFTCARD = 'GIFTCARD'
}

export enum GiftCardActions {
  CREATE_GIFT_CARD = 'CREATE_GIFT_CARD'
}

export enum PhilosophicActionEnum {
  AUTH = 'AUTHENTICATE',
  BEGIN_GIFTCARD_ACTIVATION = 'BEGIN_GIFTCARD_ACTIVATION',
  PROCESS_GIFTCARD_ACTIVATION = 'PROCESS_GIFTCARD_ACTIVATION',
  LINK_GIFTCARD = 'LINK_GIFTCARD',
  GET_GIFTCARD_BALANCE = 'GET_GIFTCARD_BALANCE',
  BEGIN_TRANSACTION = 'BEGIN_TRANSACTION',
  PROCESS_TRANSACTION = 'PROCESS_TRANSACTION'
}

export type IGiftCardListData = {
  page: number;
};

export interface IGiftCardListPayload extends IGiftCardListData {
  [index: string]: string | number;
}

export const userGiftCardSelect = {
  id: true,
  number: true,
  amount: true,
  used_by: true,
  activated: true,
  created_at: true,
  updated_at: true
};

export const giftCarOrderCurrencySelect = {
  id: true,
  order_shipment: {
    order: {
      id: true,
      currency: true
    }
  }
};
