import JsBarcode from 'jsbarcode';

import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { constants } from '@ecommerce/common-types';

@Component({
  selector: 'ecomm-barcode',
  templateUrl: './barcode.component.html'
})
export class BarcodeComponent implements OnInit, OnChanges {
  @Input() public barcode = '';
  @Input() public label = '';
  @Input() public isEan = false;
  @Input() public showLabel = true;
  @Input() public width = '110px';
  @Input() public height = '38px';
  @Input() public fontSize = '10px';

  public ngOnInit(): void {
    this.generateBarcode(this.barcode, `#barcodeImage${this.barcode}`);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.barcode = changes['barcode'].currentValue;
    this.generateBarcode(this.barcode, `#barcodeImage${this.barcode}`);
  }

  private generateBarcode(barcode: string, element: string): void {
    setTimeout((): void => {
      try {
        if (!barcode) return;

        JsBarcode(element, barcode, {
          format: this.isEan ? 'EAN13' : constants.BARCODE_TYPE,
          text: barcode,
          displayValue: this.isEan
        });
      } catch (error) {
        if (error instanceof (ReferenceError || TypeError || SyntaxError)) throw error;
      }
    }, 0);
  }
}
