import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from './../common.interface';

export interface ITaxonGroup extends IBaseAudit {
  name: string;
  key: string;
  visible: boolean;
  taxon_group_memberships?: ITaxonGroupMembership[];
}

export interface ITaxonGroupMembership {
  taxon_group_id?: number;
  taxon_id: number;
  position: number;
  taxon_group?: ITaxonGroup;
}

export const taxonGroupSelect = {
  ...BaseSelect,
  key: true,
  visible: true
};

export type ITaxonGroupListData = {
  page: number;
  name: string;
  key: string;
};

export interface ITaxonGroupListPayload extends ITaxonGroupListData {
  [index: string | number]: string | string[] | number | number[];
}

export enum TaxonGroupGridFilterEnum {
  PAGE = 'page',
  KEY = 'key',
  NAME = 'name'
}
