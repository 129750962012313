import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from './../common.interface';

export interface ISupplier extends IBaseAudit {
  name: string;
  code: string;
}

export interface ISupplierListItem extends ISupplier {
  id: number;
}

export const supplierListSelect = {
  ...BaseSelect,
  code: true
};

export type ISupplierListData = {
  page: number;
  code: string;
  name: string;
};

export interface ISupplierListPayload extends ISupplierListData {
  [index: string | number]: string | string[] | number | number[];
}

export interface IGrpcSupplier {
  suppliers: ISupplier[];
}

export enum SupplierGridFilterEnum {
  PAGE = 'page',
  CODE = 'code',
  NAME = 'name'
}
