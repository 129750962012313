import {} from 'googlemaps';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { timer, Subscription, switchMap, of, Observable, tap, catchError } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { RootService } from './common/service/root/root.service';
import { AuthService } from './common/service';
import { LatestOrderService } from './common/service/latest-order.service';
import { AudioService } from './common/service/audio.service';
import { defineAbilities } from './common/auth/ability.factory';

import { AppAbility, constants, ILatestOrders, IResponse, ItoastListener } from '@ecommerce/common-types';
import { IToastMessage } from './common/interface/toast-message.interface';

@Component({
  selector: 'ecomm-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Danube';
  public isError = false;
  public message = '';
  public showRefreshModal = false;
  private showToaster = false;
  private authenticateSubscription?: Subscription;
  private pollLatestOrdersSubscription?: Subscription;
  private readonly noCheckUrls = ['/', '/forgot-password', '/login', '/unauthorize'];
  private currentRouterUrl = '';

  constructor(
    private readonly appAbility: AppAbility,
    private readonly rootService: RootService,
    private readonly authService: AuthService,
    private readonly messageService: MessageService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly router: Router,
    private readonly latestOrderService: LatestOrderService,
    private readonly audioService: AudioService
  ) {
    this.appAbility.update(defineAbilities());
    this.router.events.subscribe((val): void => {
      if (val instanceof NavigationEnd) {
        if (!this.currentRouterUrl && !this.noCheckUrls.includes(this.router.url)) this.validateToken().subscribe();
        this.currentRouterUrl = this.router.url;
      }
    });
  }

  public ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.rootService.toastListener().subscribe((data: ItoastListener): void => {
      this.showToaster = data?.show ?? false;

      if (this.showToaster) {
        const summary: string = data.title ?? (data.is_error ? 'Error' : 'Success');
        this.isError = data.is_error;
        const message: IToastMessage = {
          severity: data.is_error ? 'error' : 'success',
          summary,
          detail: data.message,
          hideIcon: data.hideIcon,
          actionLabel: data.actionLabel
        };

        if (data.action) {
          message.btnAction = (): void => {
            this.messageService.clear();
            data?.action && data.action(data?.params);
          };
        }
        this.messageService.add(message);
      }
    });

    this.checkAthentication();
    this.pollLatestOrders();
  }

  public ngOnDestroy(): void {
    this.authenticateSubscription?.unsubscribe();
  }

  public onClose(): void {
    this.messageService.clear('c');
  }

  public refreshPage(): void {
    location.reload();
  }

  private pollLatestOrders(): void {
    this.pollLatestOrdersSubscription?.unsubscribe();
    this.pollLatestOrdersSubscription = timer(0, constants.LATEST_ORDERS_POLL_TIME)
      .pipe(switchMap(() => this.getLatestOrders()))
      .subscribe();
  }

  private checkAthentication(): void {
    this.authenticateSubscription?.unsubscribe();
    this.authenticateSubscription = timer(0, constants.RE_AUTHENTICATION_FREQUENCY)
      .pipe(
        switchMap(() => {
          if (!this.noCheckUrls.includes(this.currentRouterUrl || this.router.url)) return this.validateToken();
          return of(true);
        })
      )
      .subscribe();
  }

  private validateToken(): Observable<boolean | IResponse<string>> {
    return this.authService.validateToken().pipe(
      tap((response: IResponse<string>): void => {
        const token: string | null = localStorage.getItem('x-auth-token');
        if (token && token !== response.data) {
          localStorage.setItem('x-auth-token', response.data);
          this.appAbility.update(defineAbilities());
          this.showRefreshModal = true;
        }
      }),
      catchError((err): Observable<boolean> => {
        if (err?.code === 403) {
          this.authenticateSubscription?.unsubscribe();
          this.rootService.logOut();
        }

        return of(true);
      })
    );
  }

  private getLatestOrders(): Observable<boolean | IResponse<ILatestOrders>> {
    return this.latestOrderService.getLatestOrders().pipe(
      tap((response: IResponse<ILatestOrders>): void => {
        if (response?.data?.latest_order_id) {
          this.latestOrderService.lastOrderId = response.data.latest_order_id;
        }

        if (response?.data?.new_order_ids?.length) {
          this.playOrderReceivedSound();
          response.data.new_order_ids.forEach((orderNo: number): void => {
            this.rootService.showActionToast(
              {
                title: constants.ORDER_RECEIVED,
                message: `Order ID: #${orderNo}`,
                action: () => {
                  this.router.navigate([`/orders/${orderNo}`]);
                },
                hideIcon: true,
                actionLabel: 'View Order'
              },
              false
            );
          });
        }
      })
    );
  }

  private async playOrderReceivedSound(): Promise<void> {
    await this.audioService.play();
  }
}
