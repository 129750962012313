import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import * as Sentry from '@sentry/angular-ivy';

import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';

import { TokenInterceptor } from './common/interceptors/token.interceptor';
import { LocalServerInterceptor } from './common/interceptors/local-server.interceptor';

import { AppAbility } from '@ecommerce/common-types';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LocalServerInterceptor, multi: true }
];

@NgModule({
  providers: [
    MessageService,
    httpInterceptorProviders,
    { provide: AppAbility, useValue: new AppAbility() },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ logErrors: true })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  imports: [SharedModule, BrowserModule, BrowserAnimationsModule, AppRoutingModule, HttpClientModule, ToastModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
