export default {
  TO_WORDS_LOCALE_CODE: 'en-IN',
  TO_WORDS_CURRENCY: {
    NAME: 'Riyal',
    NAME_PLURAL: 'Riyals',
    SYMBOL: '﷼'
  },
  TO_WORDS_UNIT_CURRENCY: {
    NAME: 'Halala',
    NAME_PLURAL: 'Halalas',
    SYMBOL: 'هللة'
  }
};
