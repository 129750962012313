export interface IEventMethod {
  charCode: number;
  keyCode: number;
  dataTransfer: { files: File[] };

  preventDefault: () => void;
  stopPropagation: () => void;
}

export interface IEventStreamError {
  type: string;
  errorCode: number;
  errorMessage: string;
}
