<label class="m-b-15">{{ title }}</label>
<div
  ecommFileDragDrop
  class="file-drop-zone"
  (click)="imageUploader.click()"
  (filesChangeEmitter)="onFileSelect($event)"
>
  <div class="text-wrapper">
    <div class="centered">
      <input
        data-testid="image-uploader"
        #imageUploader
        type="file"
        name="icon"
        style="display: none"
        [accept]="acceptFileTypes"
        (click)="$event.target.value = null"
        (change)="onFileSelect($event.target.files)"
      />
      <img class="float-start" src="../../../assets/images/folder.svg" alt="folder" />
      <div class="content mt-4">Drag or drop your files here</div>
      <div class="note">Max 1 upload allowed</div>
    </div>
  </div>
</div>
<div *ngIf="!uploadErrorMsg">
  <p *ngIf="!requiredError && !fileSelected && !imageUrl" class="fs-13 mt-3 no-file-text">No file selected.</p>
  <p *ngIf="requiredError" class="fs-13 mt-3 error-msg">{{ title }} is required</p>
  <div *ngIf="selectedImageUrl || imageUrl" class="image-container mt-5">
    <img [height]="height" alt="Image" [src]="selectedImageUrl || imageUrl" />
    <button type="button" class="btn-close" (click)="onFileRemove()">
      <img src="../../../assets/images/image-close.svg" alt="image-close" />
    </button>
  </div>
</div>
<div class="error-msg">
  <p *ngIf="fileSelected && uploadErrorMsg" class="fs-13 mt-3">{{ uploadErrorMsg }}</p>
</div>
