import { Pipe, PipeTransform } from '@angular/core';

import { SimplifyTextPipe } from './simplify-text.pipe';

import { IEmployeeListItem } from '@ecommerce/common-types'; // replace 'path-to-interface' with the actual path to IEmployeeListItem interface

@Pipe({ name: 'roleName' })
export class RoleNamePipe implements PipeTransform {
  transform(employee: IEmployeeListItem): string {
    if (employee.user_type === 'admin') return employee?.role?.name ?? '';

    const simplifyText: SimplifyTextPipe = new SimplifyTextPipe();
    return employee?.operational_roles?.length
      ? employee.operational_roles.map((d): string => simplifyText.transform(d.role, '_')).join(', ')
      : '';
  }
}
