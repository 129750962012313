import { IBaseAudit } from '../auditable.interface';
import { IMulterFile } from '../multer.interface';
import { ICuisine } from './cuisine.interface';
import { IProductBaseList } from './product.interface';
import { ITaxon } from './taxon.interface';
import { BaseSelect } from './../common.interface';

export interface IRecipe extends IBaseAudit {
  name_en: string;
  name_ar: string;
  cooking_time: number;
  country_id: number;
  cuisine_id: number;
  is_visible: boolean;
  position: number;
  app_home_image_url: string;
  recipe_banner_image_url: string;
  cuisines_banner_image_url: string;
  servings?: number;
  description_en?: string;
  description_ar?: string;
  recipe_en?: string;
  recipe_ar?: string;
  calories?: number;
  cuisine?: ICuisine;
  ingredients?: IIngredient[];
}

export interface IConsumerRecipe extends IRecipe {
  name?: string;
}

export interface IIngredient extends IBaseAudit {
  name_en: string;
  name_ar: string;
  recipe_id: number;
  quantity: string;
  measurement: MeasurementEnum;
  taxon_id?: number;
  taxon_name?: string;
  recipe?: IRecipe;
  ingredient_products?: Partial<IIngredientProduct>[];
  recommended_product?: number;
  products?: IProductBaseList[];
  taxon?: ITaxon;
}

export interface IIngredientProduct {
  ingredient_id: number;
  product_id: number;
  product?: IProductBaseList;
}

export interface IRecipeListData {
  page: number;
  id: string;
  name: string;
  cuisine_id: number;
  is_visible: string;
  position: number;
}

export interface IRecipeListPayload extends IRecipeListData {
  [index: string]: string | number;
}

export interface IRecipeFile {
  app_home_image: IMulterFile[];
  recipe_banner_image: IMulterFile[];
  cuisines_banner_image: IMulterFile[];
}

export enum MeasurementEnum {
  KG = 'kg',
  GM = 'gm',
  CUP = 'cup',
  TEASPOON = 'teaspoon',
  TABLESPOON = 'tablespoon',
  LITER = 'liter',
  PCS = 'pcs'
}

export enum RecipeGridFilterEnum {
  ID = 'id',
  PAGE = 'page',
  NAME = 'name',
  CUISINE_ID = 'cuisine_id',
  IS_VISIBLE = 'is_visible',
  POSITION = 'position'
}

export const recipeListSelect = {
  id: true,
  name_en: true,
  cuisine_id: true,
  is_visible: true,
  position: true,
  cuisine: {
    id: true,
    name_en: true
  }
};

export const recipeDetailSelect = {
  id: true,
  name_en: true,
  name_ar: true,
  cooking_time: true,
  country_id: true,
  cuisine_id: true,
  is_visible: true,
  position: true,
  app_home_image_url: true,
  recipe_banner_image_url: true,
  cuisines_banner_image_url: true,
  servings: true,
  description_en: true,
  description_ar: true,
  recipe_en: true,
  recipe_ar: true,
  calories: true,
  cuisine: {
    id: true,
    name_en: true
  },
  ingredients: {
    id: true,
    name_en: true,
    name_ar: true,
    recipe_id: true,
    quantity: true,
    measurement: true,
    taxon_id: true,
    taxon: {
      ...BaseSelect,
      pretty_name: true
    },
    ingredient_products: {
      ingredient_id: true,
      product_id: true,
      product: {
        ...BaseSelect,
        sku: true
      }
    }
  }
};

export const recipeConsumerListSelect = {
  id: true,
  name_en: true,
  cooking_time: true,
  app_home_image_url: true
};
