export const countryCodes: { [key: string]: string } = {
  '+91': '00000-00000',
  '+1': '(000) 000-0000',
  '+971': '(00) 000 0000',
  '+44': '0000 000000',
  '+598': '0000 0000',
  '+213': '00 0000000',
  '+54': '000 0000000',
  '+297': '0000000',
  '+61': '00 00000000',
  '+973': '0000 0000',
  '+880': '0000 000000',
  '+32': '000 00 00 00',
  '+501': '000 0000',
  '+591': '0 0000000',
  '+55': '00 00000-0000',
  '+673': '000 0000',
  '+855': '00 000 0000',
  '+56': '0 0000 0000',
  '+86': '000 0000 0000',
  '+57': '000-000-0000',
  '+506': '0000-0000',
  '+357': '00 000000',
  '+420': '000 000 000',
  '+45': '00-00-00-00',
  '+593': '0-0000-0000',
  '+20': '00-0000-0000',
  '+503': '0000 0000',
  '+358': '00 000 00 00',
  '+33': '0 00 00 00 00',
  '+49': '00 0000 0000',
  '+233': '00 0000000',
  '+30': '000 000 0000' || '000 0000000',
  '+502': '0000 0000',
  '+592': '0000-0000',
  '+509': '00 00 0000',
  '+504': '0000 0000',
  '+852': '0000 0000',
  '+36': '(00) 000 000',
  '+354': '000 0000',
  '+62': '000 0000 0000',
  '+39': '000 0000000',
  '+81': '000-000-0000',
  '+962': '00 000 0000',
  '+254': '000 000000',
  '+82': '00-000-0000',
  '+965': '00000000',
  '+961': '00 000000',
  '+352': '000 000 000',
  '+853': '0000 0000',
  '+261': '00 0000000',
  '+60': '00-000 0000',
  '+960': '0 000000',
  '+356': '0000 0000',
  '+222': '0000 0000',
  '+52': '(000) 000-0000',
  '+377': '00 00 00 00',
  '+212': '000-0000',
  '+31': '0000 000000',
  '+64': '(00) 000 0000',
  '+505': '0000 0000',
  '+234': '000 000 0000',
  '+47': '00 00 00 00',
  '+968': '00000000',
  '+92': '000-0000000',
  '+970': '00 000 0000',
  '+507': '0000-0000',
  '+595': '000 000000',
  '+51': '000 000 000',
  '+63': '000-000-0000' || '00-0000-0000',
  '+48': '00 000 00 00',
  '+351': '000 000 000',
  '+974': '00 000000',
  '+40': '000 000000',
  '+7': '000 000-00-00',
  '+966': '000 000 0000',
  '+221': '000 0000',
  '+65': '0000 0000',
  '+27': '(00) 000-0000',
  '+34': '000 000 000',
  '+94': '000-0000000',
  '+46': '000-000-0000',
  '+41': '00 000 00 00',
  '+886': '00 0000000' || '000 000000',
  '+66': '00-0000000',
  '+216': '00000000',
  '+90': '(000) 000 0000',
  '+380': '(000) 000 0000',
  '+58': '000 0000000',
  '+84': '000 0000000',
  '+967': '00 0000000'
};
