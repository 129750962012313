import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { IEventMethod } from '@ecommerce/common-types';

@Directive({ selector: '[ecommRestrictInput]' })
export class RestrictInputDirective implements OnInit {
  @Input() regExp!: string;
  @Input() trimText = false;
  @Input() trimLeadingZeros = false;
  @Input() keepWholeNumbers = false;
  @Input() trimCommaSeparatedValues = false;
  @Input() inputControl!: AbstractControl;
  private pattern!: RegExp;

  public ngOnInit(): void {
    this.pattern = new RegExp(this.regExp);
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: IEventMethod): void {
    const inputChar: string = String.fromCharCode(event.charCode) ?? '';

    if (event.keyCode !== 8 && !this.pattern.test(inputChar)) event.preventDefault();
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    const pasteValue: string = event.clipboardData?.getData('text') ?? '';

    if (!this.pattern.test(pasteValue)) event.preventDefault();
  }

  @HostListener('blur', ['$event']) onBlur(): void {
    if (this.trimText && this.inputControl?.value) {
      this.inputControl.patchValue(this.inputControl.value?.replace(/\s\s+/g, ' ')?.trim());

      return;
    }

    if (this.trimLeadingZeros && this.inputControl?.value) {
      const value: string = this.inputControl.value?.toString() ?? '';
      this.trimNumber(value);

      return;
    }

    if (this.keepWholeNumbers && this.inputControl?.value) {
      const value: string = this.inputControl.value?.toString() ?? '';
      if (parseInt(value) !== 0) this.trimNumber(value);

      return;
    }

    if (this.trimCommaSeparatedValues && this.inputControl?.value && typeof this.inputControl?.value !== 'object') {
      const trueValues: string = this.inputControl.value.split(',').filter((value: string): string => value);
      this.inputControl.patchValue(trueValues);

      return;
    }
  }

  private trimNumber(value: string): void {
    this.inputControl.patchValue(
      value.includes('-')
        ? value.replace(/^-0+/g, '-').replace(/(^-\.)/g, '-0.')
        : value.replace(/^0+/g, '').replace(/(^\.)/g, '0.')
    );
  }
}
