import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'addHours' })
export class AddHoursPipe implements PipeTransform {
  transform(value: string, hoursToAdd: number = 0): Date | string {
    if (!value) return '';

    const date: Date = new Date(value);
    date.setHours(date.getHours() + hoursToAdd);

    return date;
  }
}
