export interface IResponse<T> {
  code: number;
  message?: string;
  data: T;
}

export interface IErrorResponse<T = unknown> {
  code: number;
  message: string;
  data?: T;
}
