import { AbilityBuilder } from '@casl/ability';
import jwtDecode from 'jwt-decode';

import { AppAbility, IUserToken } from '@ecommerce/common-types';

export function defineAbilities() {
  const { can, rules } = new AbilityBuilder(AppAbility);
  const token: string | null = localStorage.getItem('x-auth-token');

  if (token) {
    const { permissions } = jwtDecode<IUserToken>(token);
    if (permissions?.length) {
      (permissions as string[][])?.forEach((permission: string[]): void => {
        if (permission.length > 1) {
          const [module, ...actions] = permission;
          actions.forEach((action: string) => can(action, module));
        }
      });
    }
  }

  return rules;
}

export function createAbility(): AppAbility {
  return new AppAbility(defineAbilities(), { detectSubjectType: (object: never): never => object });
}
