import { IBaseAudit } from './auditable.interface';
import { ICity } from './location/city.interface';
// import { IDistrict } from './location/district.interface';
import { ICountry } from './location/country.interface';
import { IWarehousePayment } from './warehouse-payment.interface';
import { ISuperMarket } from './supermarket/super-market.interface';
import { ICreateTranslation, ITranslationData } from './translation/translation.interface';
import { IKeyMapping } from './key-mapping.interface';
import { BaseSelect } from './common.interface';

export interface IWarehouse extends IBaseAudit {
  name: string;
  description?: string;
  country_code?: string;
  mobile_number?: string;
  country_id: number;
  country?: ICountry;
  city_id: number;
  city?: ICity;
  super_markets?: ISuperMarket[];
  super_markets_grid?: ISuperMarket[];
  // district_id?: number;
  // districts: IDistrict[];
  //districts_grid?: IDistrict[];
  translation_id?: string;
  latitude?: number;
  longitude?: number;
  regular_delivery_fee: number;
  express_delivery_fee: number;
  time_slots_lead_time: number;
  minimum_delivery_fee?: number;
  store_pickup_payment_options?: IWarehousePayment[];
  home_delivery_payment_options?: IWarehousePayment[];
  warehouses_payments?: IWarehousePayment[];
  call_mask?: boolean;
  is_visible?: boolean;
}

export interface IWarehouseListItem extends IWarehouse {
  id: number;
}

export const WarehouseListSelect = {
  ...BaseSelect,
  description: true,
  country_id: true,
  country: {
    name: true,
    currency_precision: true,
    currency: {
      name: true
    }
  },
  city_id: true,
  city: {
    name: true
  },
  // districts: {
  //   id: true,
  //   name: true
  // },
  country_code: true,
  mobile_number: true,
  warehouses_payments: {
    payment_id: true,
    delivery_type: true,
    payment_option: {
      name: true
    }
  },
  super_markets: {
    ...BaseSelect
  },
  latitude: true,
  longitude: true,
  regular_delivery_fee: true,
  express_delivery_fee: true,
  time_slots_lead_time: true,
  minimum_delivery_fee: true,
  call_mask: true,
  is_visible: true
};

export type IWarehouseData = {
  page: number;
  name: string;
  description: string;
  country: string;
  city: string;
  district: string;
  super_market: string;
  is_visible: string[];
};

export interface IWarehousePayload extends IWarehouseData {
  [index: string | number]: string | string[] | number | number[];
}

export interface IWarehouseTranslation extends ITranslationData {
  name?: string;
}

export interface ICreateWarehouseTranslation extends ICreateTranslation {
  name?: IKeyMapping<string>;
}

export enum WarehouseGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  COUNTRY = 'country',
  CITY = 'city',
  SUPER_MARKET = 'super_market',
  IS_VISIBLE = 'is_visible',
  DESCRIPTION = 'description'
}
