export const countryNames: { [key: string]: string } = {
  '+1': 'United States',
  '+91': 'India',
  '+971': 'United Arab Emirates',
  '+44': 'United Kingdom',
  '+598': 'Uruguay',
  '+213': 'Algeria',
  '+54': 'Argentina',
  '+297': 'Aruba',
  '+61': 'Australia',
  '+973': 'Bangladesh',
  '+880': 'Bangladesh',
  '+32': 'Belgium',
  '+501': 'Belize',
  '+591': 'Bolivia',
  '+55': 'Brazil',
  '+673': 'Brunei Darussalam',
  '+855': 'Cambodia',
  '+56': 'Chile',
  '+86': 'China',
  '+57': 'Colombia',
  '+506': 'Costa Rica',
  '+357': 'Cyprus',
  '+420': 'Czech Republic',
  '+45': 'Denmark',
  '+593': 'Ecuador',
  '+20': 'Egypt',
  '+503': 'El Salvador',
  '+358': 'Finland',
  '+33': 'France',
  '+49': 'Germany',
  '+233': 'Ghana',
  '+30': 'Greece',
  '+502': 'Guatemala',
  '+592': 'Guyana',
  '+509': 'Haiti',
  '+504': 'Honduras',
  '+852': 'Hong Kong',
  '+36': 'Hungary',
  '+354': 'Iceland',
  '+62': 'Indonesia',
  '+39': 'Italy',
  '+81': 'Japan',
  '+962': 'Jordan',
  '+254': 'Kenya',
  '+82': 'Korea/South',
  '+965': 'Kuwait',
  '+961': 'Lebanon',
  '+352': 'Luxembourg',
  '+853': 'Macau',
  '+261': 'Madagascar',
  '+60': 'Malaysia',
  '+960': 'Maldives',
  '+356': 'Malta',
  '+222': 'Mauritania',
  '+52': 'Mexico',
  '+377': 'Monaco',
  '+212': 'Morocco',
  '+31': 'Netherlands',
  '+64': 'New Zealand',
  '+505': 'Nicaragua',
  '+234': 'Nigeria',
  '+47': 'Norway',
  '+968': 'Oman',
  '+92': 'Pakistan',
  '+970': 'Palestine, State of',
  '+507': 'Panama',
  '+595': 'Paraguay',
  '+51': 'Peru',
  '+63': 'Philippines',
  '+48': 'Poland',
  '+351': 'Portugal',
  '+974': 'Qatar',
  '+40': 'Romania',
  '+7': 'Russia',
  '+966': 'Saudi Arabia',
  '+221': 'Senegal',
  '+65': 'Singapore',
  '+27': 'South Africa',
  '+34': 'Spain',
  '+94': 'Sri Lanka',
  '+46': 'Sweden',
  '+41': 'Switzerland',
  '+886': 'Taiwan',
  '+66': 'Thailand',
  '+216': 'Tunisia',
  '+90': 'Turkey',
  '+380': 'Ukraine',
  '+58': 'Venezuela',
  '+84': 'Vietnam',
  '+967': 'Yemen'
};
