import { IBaseAudit } from '../auditable.interface';
import { ICountry } from '../location/country.interface';
import { BaseSelect } from './../common.interface';

export interface IOfflineProduct extends IBaseAudit {
  name: string;
  sku: string;
  price: number;
  country_id: number;
  barcodes?: IOfflineProductBarcode[];
  country?: ICountry;
}

export interface IOfflineProductBarcode {
  barcode: number;
  offline_product_id: number;
  order: number;
  offline_product?: IOfflineProduct;
}

export interface IOfflineProductCsv {
  sku: string;
  name: string;
  price: string;
  barcodes: string;
  offline_product_barcodes: string[];
}

export type IOfflineProductListData = {
  page: number;
  sku: string;
  name: string;
  country_ids: number[];
  barcode: string;
};

export interface IOfflineProductListPayload extends IOfflineProductListData {
  [index: string]: string | number | number[];
}

export const offlineProductCsvLabels = {
  sku: 'SKU',
  name: 'Name',
  price: 'Price',
  barcodes: 'Barcodes'
};

export const offlineProductListSelect = {
  ...BaseSelect,
  sku: true,
  country_id: true,
  barcodes: {
    barcode: true,
    order: true
  }
};

export enum OfflineProductGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  SKU = 'sku',
  BARCODE = 'barcode',
  COUNTRY_IDS = 'country_ids'
}

export const offlineProductByBarcodeSelect = {
  ...BaseSelect,
  price: true,
  country_id: true
};
