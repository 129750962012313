import { IBaseAudit } from '../auditable.interface';
import { IOptionType, IOptionValue } from './option-type.interface';
import { IProduct } from './product.interface';

export interface IProductVariant extends IBaseAudit {
  product_id: number;
  option_type_id: number;
  option_value_id: number;
  option_type?: IOptionType;
  option_value?: IOptionValue;
  barcodes?: IProductVariantBarcode[];
  images?: IProductVariantImage[];
  product?: IProduct;
}

export interface IProductVariantBarcode {
  barcode: string;
  product_variant_id: number;
  product_variant?: IProductVariant;
}

export interface IProductVariantImage extends IBaseAudit {
  url: string;
  product_variant_id?: number;
  product_variant?: IProductVariant;
}

export interface IVariantImage {
  id?: number;
  url: string | ArrayBuffer | null;
}

export interface IGrpcProductVariant {
  productId: number;
}

export interface IGrpcProductVariantResponse {
  productVariants: IProductVariant[];
}

export interface IProductVariantBaseListData {
  product_id: number;
}

export interface IProductVaraintBaseListPayload extends IProductVariantBaseListData {
  [index: string]: number;
}

export const productVariantBaseListSelect = {
  id: true,
  option_type: {
    name_en: true,
    name_ar: true
  },
  option_value: {
    name: true,
    presentation_en: true,
    presentation_ar: true
  }
};

export const productVariantAlgoliaSelect = {
  id: true,
  product_id: true,
  option_type: {
    name_en: true,
    name_ar: true,
    presentation_en: true,
    presentation_ar: true
  },
  option_value: {
    name: true,
    presentation_en: true,
    presentation_ar: true,
    position: true
  },
  images: { url: true }
};
