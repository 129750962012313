import { BaseSelect } from './common.interface';
import { ICity } from './location/city.interface';
import { IBaseAudit } from './auditable.interface';
import { ICountry } from './location/country.interface';
import { ISuperMarket } from './supermarket/super-market.interface';

export interface IHoliday extends IBaseAudit {
  name: string;
  start_date: Date;
  end_date: Date;
  global: boolean;
  holiday_countries?: IHolidayCountry[];
  holiday_cities?: IHolidayCity[];
  holiday_supermarkets?: IHolidaySupermarket[];
  countries: ICountry[];
  cities: ICity[];
  supermarkets: ISuperMarket[];
}

export interface IHolidayList extends IHoliday {
  id: number;
  countries_grid?: ICountry[];
}

export interface IHolidayListItem extends IHoliday {
  id: number;
}

export interface IHolidayCountry {
  holiday_id: number;
  country_id: number;
}

export interface IHolidayCity {
  holiday_id: number;
  city_id: number;
}

export interface IHolidaySupermarket {
  holiday_id: number;
  supermarket_id: number;
  holiday?: IHoliday;
}

export interface IHolidayListData {
  page: number;
  name: string;
  start_date: string;
  end_date: string;
  country: string;
  global: string[];
}

export interface IHolidayListpayload extends IHolidayListData {
  [index: string]: string | string[] | number;
}

export const HolidayListSelect = {
  ...BaseSelect,
  start_date: true,
  end_date: true,
  global: true,
  countries: BaseSelect,
  cities: BaseSelect,
  supermarkets: BaseSelect
};

export enum HolidayGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  COUNTRY = 'country',
  GLOBAL = 'global',
  START_DATE = 'start_date',
  END_DATE = 'end_date'
}
