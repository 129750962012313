<nav class="flex justify-content-between align-items-center p-l-25 p-r-25">
  <div class="page-item" [ngClass]="{ disabled: activePage < 2 }">
    <a class="page-link page-next" tabindex="-1" aria-disabled="true" (click)="changePage(activePage - 1)">
      <img src="../../../assets/images/arrow-left.svg" class="m-r-15" alt="Prev" />Prev
    </a>
  </div>

  <ul *ngIf="totalPage < paginationLimit" class="pagination justify-content-center">
    <li
      *ngFor="let page of [].constructor(totalPage); index as i"
      class="page-item"
      [ngClass]="{ active: activePage === i + initialPage }"
      (click)="changePage(i + initialPage)"
    >
      <a class="page-link">{{ i + initialPage }}</a>
    </li>
  </ul>

  <ul *ngIf="totalPage >= paginationLimit" class="pagination justify-content-center">
    <li [ngClass]="{ active: activePage === initialPage }" (click)="changePage(1)">
      <a class="page-link">{{ initialPage }}</a>
    </li>
    <li *ngIf="!viewMinPages" [ngClass]="{ active: activePage === initialPage + 1 }" (click)="changePage(2)">
      <a class="page-link">{{ initialPage + 1 }}</a>
    </li>
    <li *ngIf="viewMinPages" (click)="pivotIntermPages(false)">
      <a class="page-link">{{ ellipsisPoints }}</a>
    </li>
    <li *ngFor="let page of intermediatePages" [ngClass]="{ active: activePage === page }" (click)="changePage(page)">
      <a class="page-link">{{ page }}</a>
    </li>
    <li *ngIf="!viewMaxPages" [ngClass]="{ active: activePage === totalPage - 1 }" (click)="changePage(totalPage - 1)">
      <a class="page-link">{{ totalPage - 1 }}</a>
    </li>
    <li *ngIf="viewMaxPages" (click)="pivotIntermPages(true)">
      <a class="page-link">{{ ellipsisPoints }}</a>
    </li>
    <li [ngClass]="{ active: activePage === totalPage }" (click)="changePage(totalPage)">
      <a class="page-link">{{ totalPage }}</a>
    </li>
  </ul>

  <div class="page-item" [ngClass]="{ disabled: activePage === totalPage }">
    <a data-testid="next-page-button" class="page-link page-next" (click)="changePage(activePage + 1)">
      Next
      <img src="../../../assets/images/arrow-right.svg" class="m-l-15" alt="Next" />
    </a>
  </div>
</nav>
