import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AudioService {
  private readonly audio: HTMLAudioElement;
  private readonly filePath: string = '../../../assets/audio/ting.wav';

  constructor() {
    this.audio = new Audio(this.filePath);
  }

  public async play(): Promise<void> {
    try {
      await this.audio.play();
    } catch (error) {
      console.error('Playback failed:', error);
    }
  }

  public pause(): void {
    this.audio.pause();
  }

  public setVolume(volume: number): void {
    this.audio.volume = volume; // Volume should be between 0 and 1
  }
}
