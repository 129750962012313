export default {
  DEFAULT_ERROR_MESSAGE: 'Something went wrong. Please try again.',
  USER_LOGIN_SUCCESS: 'Login successfully',
  RESET_PASSWORD_SUCCESS: 'Temporary password has been sent to your Email',
  PASSWORD_CHANGE_SUCCESS: 'Password changed successfully',
  NEW_EMPLOYEE_CREATE:
    'New Employee created successfully. Notifications will be sent to the employee with login credentials.',
  EMPLOYEE_DELETE: 'Employee deleted successfully',
  EMPLOYEE_UPDATE: 'Employee updated successfully. Notifications will be sent to the employee with login credentials.',
  ROLE_ID_REQUIRED: 'Role ID required',
  DEFAULT_EMAIL_VERIFICATION_SUCCESS_MESSAGE: 'Your email has been verified',
  DEFAULT_ERROR_MESSAGE_WITH_TRANSLATION: 'common_some_went_wrong_error_message',
  NOT_FOUND: 'common_not_found_error_message',
  USER_ID_REQUIRED: 'common_user_id_required_error_message',
  INVALID_USER_ID: 'common_user_id_invalid_error_message',
  INVALID_USER_PASSWORD: 'login_incorrect_email_password_error_message',
  USER_ALREADY_DELETED: 'common_user_already_deleted_error_message',
  ACCOUNT_NOT_EXIST: 'common_account_not_exist_error_message',
  USER_EMAIL_ALREADY_EXIST: 'common_email_already_exist_error_message',
  USER_MOBILE_NUMBER_ALREADY_EXIST: 'common_mobile_no_already_exist_error_message',
  NEW_PASSWORD_SAME: 'common_new_pass_same_as_current_error_message',
  NEW_PASSWORD_NOT_SAME: 'common_new_pass_not_same_as_confirm_error_message',
  INCORRECT_OLD_PASSWORD: 'common_incorrect_current_pass_error_message',
  INVALID_OTP: 'common_invalid_code_error_message',
  OTP_EXPIRED: 'common_otp_expired_error_message',
  COUNTRY_ID_NOT_EXIST: 'cities_country_id_not_exist_error_message',
  LANGUAGE_ID_NOT_EXIST: 'stores_language_id_not_exist_error_message',
  PRODUCT_ID_NOT_EXIST: 'customer_product_id_not_exist_error_message',
  PRODUCT_SLUG_NOT_EXIST: 'customer_product_slug_not_exist_error_message',
  INVALID_ADDRESS_ID: 'address_address_id_invalid_error_message',
  ADMIN_EMAIL_NOT_FOUND: 'feedback_admin_email_not_found_error_message',
  INVALID_MOBILE_NUMBER: 'invalid_mobile_number_error_message',
  INVALID_COUNTRY_CODE: 'invalid_country_code_error_message',
  LOCATION_NOT_VALID: 'location_not_valid_error_message',
  SUPERMARKET_NOT_VALID: 'supermarket_not_valid_error_message',
  INVALID_LANGUAGE_ID: 'invalid_language_id_error_message',
  REQUESTED_PRODUCT_NOT_AVAILABLE: 'requested_product_not_available',
  ORDER_ALREADY_CANCELLED: 'order_already_cancelled',
  ORDER_ALREADY_ADMIN_CANCELLED: 'order_already_admin_cancelled',
  ORDER_ALREADY_SHIPPED: 'order_already_shippped',
  ORDER_ALREADY_DELIVERED: 'order_already_delivered',
  ORDER_ALREADY_COMPLETED: 'order_already_completed',
  ORDER_SHIPMENT_NOT_PENDING: 'order_shipment_not_pending',
  ADDRESS_TYPE_ALREADY_EXIST: 'address_type_already_exist',
  INVALID_DELIVERY_OPTION_ID: 'invalid_delivery_option_id_error_message',
  INVALID_EMAIL_VERIFICATION_KEY: 'invalid_email_verification_key',
  SOCIAL_ACCOUNT_ALREADY_EXIST: 'social_account_already_exists',
  EMAIL_ALREADY_VERIFIED: 'email_already_verified',
  MOBILE_NUMBER_ALREADY_VERIFIED: 'mobile_number_already_verified',
  EMAIL_VERIFICATION_SUCCESS_MESSAGE: 'email_verification_success',
  PICKER_REACHED_MAX_PICK_LIMIT: 'picker_reached_max_pick_limit',
  REQUEST_ORDER_SHIPMENT_NOT_AVAILABLE: 'request_order_shipment_not_available',
  INVALID_ORDER_PICKING_BATCH_ID: 'invalid_order_picking_batch_id',
  ORDER_PICKING_CANT_HOLD: 'order_picking_cant_hold',
  ORDER_PICKING_CANT_UN_HOLD: 'order_picking_cant_un_hold',
  INVALID_HOLD_REASON_ID: 'invalid_hold_reason_id',
  ORDER_PICKING_TIME_SPENT_CANT_UPDATE: 'order_picking_time_spent_cant_update',
  ORDER_PICKING_CANT_START: 'order_picking_cant_start',
  ORDER_PICKING_BATCH_STATE_CANT_SAVE: 'order_picking_batch_state_cant_save',
  ORDER_PICKING_CANT_FINISH: 'order_picking_cant_finish',
  INVALID_SHIPMENT_ID: 'invalid_shipment_id',
  INVALID_ORDER_PICKING_SHIPMENT_ID: 'invalid_order_picking_shipment_id',
  ORDER_NOT_READY_FOR_PICKUP: 'order_not_ready_for_pickup',
  REQUESTED_REPLACEMENT_PRODUCT_NOT_AVAILABLE: 'requested_replacement_product_not_available',
  ORDER_PICKING_SHIPMENT_BINS_ALREADY_PICKED: 'order_picking_shipment_bins_already_picked',
  ORDER_PICKING_SHIPMENT_BINS_ALREADY_DROPPED: 'order_picking_shipment_bins_already_dropped',
  ORDER_PICKING_SHIPMENT_PRODUCTS_ALREADY_PICKED: 'order_picking_shipment_products_already_picked',
  ORDER_PICKING_BINS_PICKED_BIN_ID_NOT_VALID: 'order_picking_bins_picked_bin_id_not_valid',
  ORDER_PICKING_PRODUCT_PICKED_BARCODE_NOT_VALID: 'order_picking_product_picked_barcode_not_valid',
  ORDER_PICKING_SHIPMENT_BINS_NOT_PICKED: 'order_picking_shipment_bins_not_picked',
  ORDER_PICKING_SHIPMENT_PRODUCTS_NOT_PICKED: 'order_picking_shipment_products_not_picked',
  ORDER_PICKING_BINS_DISPATCH_LOCATION_ID_NOT_VALID: 'order_picking_bins_dispatch_location_id_not_valid',
  ORDER_PICKING_PRODUCT_DISPATCH_LOCATION_ID_NOT_VALID: 'order_picking_product_dispatch_location_id_not_valid',
  ORDER_PICKING_SHIPMENT_BINS_OR_PRODUCT_SHOULD_PICKED_ATLEAST_ONE:
    'order_picking_shipment_bins_or_product_should_picked_atleast_one',
  DISPATCH_JOB_STATE_CANT_SAVE: 'dispatch_job_state_cant_save',
  INVALID_DISPATCH_JOB_SHIPMENT_ID: 'invalid_dispatch_job_shipment_id',
  DISPATCH_JOB_CANT_HOLD: 'dispatch_job_cant_hold',
  DISPATCH_JOB_CANT_COMPLETE: 'dispatch_job_cant_complete',
  DISPATCH_JOB_CANT_UN_HOLD: 'dispatch_job_cant_un_hold',
  DISPATCH_JOB_CANT_REPORT: 'dispatch_job_state_cant_report',
  ORDER_ADJUSTED_BY_PROMOTION_LOCALE: 'order_adjusted_by_promotion',
  INVALID_GIFT_CARD_ID_NUMBER: 'invalid_gift_card_number',
  UNKNOWN_GIFT_CARD_NUMBER: 'unknown_gift_card_number',
  GIFT_CARD_ALREADY_REDEEMED: 'gift_card_already_redeemed',
  GIFT_CARD_ALREADY_ACTIVATED: 'gift_card_already_activated',
  UNABLE_TO_ACTIVATE_GIFT_CARD: 'unable_activate_gift_card',
  INVALID_BARCODE_NUMBER: 'invalid_barcode_number',
  CREDIT_CARD_ORDER_PAYMENT_FAILED: 'credit_card_order_payment_failed',
  DELIVERY_ADDRESS_NOT_IN_DELIVERY_REGION: 'Delivery address should be inside the Supermarket delivery region',
  ORDER_PRODUCT_OUT_OF_STOCK: 'Order product(s) is out of stock',
  ORDER_SHIPMENT_TOTAL_NOT_MATCH: 'The total amount for the order shipment is incorrect, please re-calculate',
  ORDER_SHIPMENT_TAX_TOTAL_NOT_MATCH: 'The tax amount for the order shipment is incorrect, please re-calculate',
  ORDER_TOTAL_NOT_MATCH: 'The total amount for the order is incorrect, please re-calculate',
  ORDER_ITEM_TOTAL_NOT_MATCH: 'The total amount for the item(s) is incorrect, please re-calculate',
  ORDER_ITEM_COUNT_NOT_MATCH: 'The total items count for the order is incorrect, please re-calculate',
  ORDER_PRODUCT_PRICE_NOT_MATCH: 'Product(s) price is incorrect, please re-calculate',
  SUPERMARKET_DONT_HAVE_DELIVERY_OPTION: "Supermarket doesn't support this delivery option",
  SUPERMARKET_DONT_HAVE_DELIVERY_TYPE: "Supermarket doesn't support this type of delivery",
  SUPERMARKET_DONT_HAVE_PAYMENT_OPTION: "Supermarket doesn't support this payment method",
  USER_CARD_ID_REQUIRED: 'User card ID is required',
  USER_CARD_NOT_EXIST: 'User card does not exists',
  CARD_CVV_REQUIRED: 'CVV is required',
  ORDER_SHIPMENT_TIMESLOT_NOT_VALID: 'Shipment time slot is not valid',
  ORDER_SHIPMENT_DELIVERY_CAPACITY_EXCEED: 'The delivery capacity of supermarket exceeded for this time slot',
  COUNTRY_UPDATE: 'Country updated successfully',
  NEW_COUNTRY_CREATE: 'New Country created successfully',
  COUNTRY_EXIST: 'Country already exists',
  COUNTRY_NOT_EXIST: 'Country does not exists',
  COUNTRY_DELETE: 'Country deleted successfully',
  COUNTRY_UNDELETABLE: 'Remove the Cities associated with the Country',
  NEW_CITY_CREATE: 'New City created successfully',
  CITY_EXIST: 'City already exists',
  CITY_UPDATE: 'City updated successfully',
  CITY_DELETE: 'City deleted successfully',
  CITY_UNDELETABLE: 'Remove the Districts associated with the City',
  DISTRICT_EXIST: 'District already exists',
  NEW_DISTRICT_CREATE: 'New District created successfully',
  DISTRICT_UPDATE: 'District updated successfully',
  DISTRICT_UNDELETABLE: 'Remove the Supermarkets associated with the District',
  DISTRICT_DELETE: 'District deleted successfully',
  NEW_DELIVERY_ZONE: 'New Delivery Zone created successfully',
  SUPER_MARKET_EXIST: 'Supermarket already exists',
  NEW_SUPER_MARKET_CREATE: 'New Supermarket created successfully',
  SUPER_MARKET_DELETE: 'Supermarket deleted successfully',
  SUPER_MARKET_UPDATE: 'Supermarket updated successfully',
  NEW_WAREHOUSE_CREATE: 'New Warehouse created successfully',
  WAREHOUSE_UPDATE: 'Warehouse updated successfully',
  WAREHOUSE_DELETE: 'Warehouse deleted successfully',
  WAREHOUSE_EXIST: 'Warehouse already exists',
  ROLE_EXIST: 'Role already exists',
  INVALID_ROLE_ID: 'Invalid Role Id',
  NEW_ROLE_CREATE: 'New Role created successfully',
  EDIT_ROLE_CREATE: 'Role updated successfully',
  ROLE_UNDELETABLE: 'Role is already assigned to an employee',
  ROLE_DELETE: 'Role deleted successfully',
  BARCODE_EXIST: 'Barcode Number already exists',
  NEW_AISLE_SHELF_CREATE: 'New Aisle and Shelf created successfully',
  AISLE_SHELF_UPDATE: 'Aisle and Shelf updated successfully',
  AISLE_SHELF_DELETE: 'Aisle and Shelf deleted successfully',
  SUPPLIER_NAME_EXIST: 'Supplier Name already exists',
  SUPPLIER_CODE_EXIST: 'Supplier Code already exists',
  NEW_BIN_CATEGORY_CREATE: 'New Bin Category created successfully',
  BIN_CATEGORY_DELETE: 'Bin Category deleted successfully',
  NEW_SUPPLIER_CREATE: 'New Supplier created successfully',
  INVALID_SUPPLIER_ID: 'Invalid Supplier Id',
  INVALID_SUPERMARKET_ID: 'Invalid Supermarket Id',
  SUPPLIER_UPDATE: 'Supplier updated successfully',
  SUPPLIER_DELETE: 'Supplier deleted successfully',
  MANUFACTURER_NAME_EXIST: 'Manufacturer Name already exists',
  MANUFACTURER_CODE_EXIST: 'Manufacturer Code already exists',
  NEW_MANUFACTURER_CREATE: 'New Manufacturer created successfully',
  NEW_TIMESLOT_CREATE: 'New Time Slot created successfully',
  NEW_DELIVERY_CAPACITY_CREATE_UPDATE: 'Delivery Capacity updated successfully',
  UPDATE_TIMESLOT_WARNING:
    'There are capacities assigned to Supermarket(s) for this Time Slot, still want to update this Time Slot?',
  DELETE_TIMESLOT_WARNING:
    'There are capacities assigned to Supermarket(s) for this Time Slot, still want to delete this Time Slot?',
  WARNING_APPLY_TO_ALL_SUPERMARKETS:
    'Are you sure you want to apply this capacity for all the Supermarkets in this Time Slot?',
  WARNING_APPLY_TO_ALL_TIMESLOTS:
    'Are you sure you want to add this capacity to all the Time Slots in this Supermarket?',
  DELIVERY_CAPACITY_UPDATE_ALL_SUPERMARKETS: 'Delivery Capacity updated to all Supermarkets',
  DELIVERY_CAPACITY_UPDATE_ALL_TIMESLOTS: 'Delivery Capacity updated to all Time Slots',
  DELIVERY_CAPACITY_UPDATE_ALL_DAYS: 'Delivery Capacity applied to all days for this Supermarket',
  DELIVERY_CAPACITY_ENABLE: 'Delivery Capacity enabled successfully',
  DELIVERY_CAPACITY_DISABLE: 'Delivery Capacity disabled successfully',
  TIME_SLOTS_ENABLE: 'Time Slots enabled successfully',
  TIME_SLOTS_DISABLE: 'Time Slots disabled successfully',
  INVALID_MANUFACTURER_ID: 'Invalid Manufacturer Id',
  INVALID_BIN_CATEGORY_ID: 'Invalid Bin Category Id',
  MANUFACTURER_UPDATE: 'Manufacturer updated successfully',
  TIME_SLOT_UPDATE: 'Time Slot updated successfully',
  TIME_SLOT_DISABLE_EDIT: 'Disabled time slot can not be edited',
  MANUFACTURER_DELETE: 'Manufacturer deleted successfully',
  TIME_SLOT_DELETE: 'Time Slot deleted successfully',
  INVALID_COUNTRY_ID: 'Invalid Country Id',
  INVALID_DISTRICT_ID: 'Invalid District Id',
  INVALID_HOLIDAY_ID: 'Invalid Holiday Id',
  NEW_TAX_CREATE: 'New Tax created successfully',
  BIN_CATEGORY_EXIST: 'Bin Category already exists',
  BIN_CODE_EXIST: 'Bin Code already exists',
  TAX_NAME_EXIST: 'Tax Name already exists',
  TAX_CODE_EXIST: 'Tax Code already exists',
  TIME_SLOT_EXIST: 'Time Slot already exists',
  PROMOTION_NAME_EXIST: 'Name already exists',
  PROMOTION_CODE_EN_EXIST: 'Base Code (EN) already exists',
  PROMOTION_CODE_AR_EXIST: 'Base Code (AR) already exists',
  BIN_CATEGORY_UPDATE: 'Bin Category updated successfully',
  INVALID_TAX_ID: 'Invalid Tax Id',
  TAX_UPDATE: 'Tax updated successfully',
  TAX_DELETE: 'Tax deleted successfully',
  SELECT_ZIP_FILE: 'Select Zip File First',
  SELECT_CSV_FILE: 'Select CSV File First',
  INVALID_ZIP_FILE: 'Invalid zip file',
  INVALID_CSV_FILE: 'Invalid csv file',
  INVALID_FILE: 'Invalid file',
  MULTI_CSV_UPLOAD_ERROR: 'Only one csv file is allowed',
  EMPTY_REASON: '{label} is missing',
  NOT_EXIST_REASON: '{label} does not exist in our record',
  DATE_FORMAT_REASON: '{label} should be in "{date_format}" format',
  DATE_GREATER_THAN_REASON: '{label} should be greater than {start_date}',
  DATE_LESS_THAN_REASON: '{label} should be less than {start_date}',
  NOT_GREATER_THAN_REASON: '{label} should not be greater than {value}',
  NOT_LESS_THAN_REASON: '{label} should not be less than {value}',
  TIME_FORMAT_REASON: '{label} should be in "{date_format}" (24 hours) format',
  NO_SPACE_REASON: 'Please remove space in {label}',
  ALPHA_REASON: '{label} should be alphabets only',
  NUMERIC_REASON: '{label} should be numeric only',
  ALPHA_NUMERIC_REASON: '{label} should be alphanumeric',
  ALPHA_NUMERIC_SPACE_REASON: '{label} should be alphanumeric with space',
  ALPHA_NUMERIC_SPECIAL_CHAR_SPACE_REASON: '{label} should be alphanumeric with special characters and space',
  DECIMAL_PLACES_REASON: '{label} should be numeric and only {decimal_places} decimal places',
  CHARACTERS_LENGTH_REASON: '{label} should contain only {length} characters',
  CONTAINS_REASON: '{label} should be {contains}',
  NUMBER_RANGE_REASON: '{label} can range between {range_start} to {range_end}',
  FILE_NOT_FOUND_REASON: '{label} does not exist in zip file',
  DUPLICATE_REASON: 'Duplicate {label} exists in this file',
  BUNDLE_OF_BUNDLE_REASON: '"{label}" cannot be a bundled SKU and part of a Bundle SKU at the same time',
  ALREADY_EXIST_REASON: 'Duplicate {label} exists in our record',
  BUNDLE_SKU_NOT_VALID_REASON: 'Invalid format for Bundle SKU',
  PRODUCT_TAXON_NOT_VALID_REASON: 'Taxon Id not valid',
  ALREADY_BUNDLED_PRODUCT_REASON: `A product marked as 'bundled' cannot be bundled again`,
  OFFLINE_PRODUCT_BARCODE_INVALID_REASON: 'Invalid Barcode number',
  INVALID_BARCODE: 'Invalid Barcode number',
  MAX_COUNT_REASON: 'Maximum "{length}" "{label}" are allowed',
  WAITING_FOR_FILE_UPLOAD: 'Waiting for upload to complete',
  SUBMIT_JOB: 'Submit Job',
  JOB_INPROGRESS: 'Job is in progress',
  PRODUCT_CREATION_INPROGRESS: 'Product Creation in progress',
  PRODUCT_UPDATE_INPROGRESS: 'Update is in progress',
  DISTRICTS_REQUIRED: 'Districts required',
  DISTRICTS_ASSOCIATED: 'Districts are already associated',
  IMPORT_INPROGRESS: 'Import is in progress',
  BRAND_CODE_EXIST: 'Brand Code already exists',
  NEW_BRAND_CREATE: 'New Brand created successfully',
  INVALID_BRAND_ID: 'Invalid Brand Id',
  BRAND_UPDATE: 'Brand updated successfully',
  BRAND_TRANSLATION: 'Translation added successfully',
  BRAND_DELETE: 'Brand deleted successfully',
  PRODUCT_ALREADY_ACTIVE: 'Product is already activated',
  PRODUCT_ALREADY_INACTIVE: 'Product is already deactivated',
  PRODUCT_ACTIVATION: 'Product activated successfully',
  PRODUCT_DEACTIVATION: 'Product deactivated successfully',
  NEW_TAXONOMY_CREATE: 'New Taxonomy created successfully',
  INVALID_TAXONOMY_ID: 'Invalid Taxonomy Id',
  NAME_EXIST: 'Name already exists',
  TAXONOMY_UPDATE: 'Taxonomy updated successfully',
  INVALID_TAXON_ID: 'Invalid Taxon Id',
  NEW_TAXON_CREATE: 'New Taxon created successfully',
  TAXON_INVALID_LEVEL: 'Taxons are limited to three levels of sub-taxons',
  TAXON_DELETE: 'Taxon deleted successfully',
  OPTION_VALUE_REQUIRED: 'Option Value is required',
  NEW_OPTION_TYPE: 'New Option Type created successfully',
  OPTION_TYPE_UPDATE: 'Option Type updated successfully',
  NEW_TAXON_GROUP_CREATE: 'New Taxon Group created successfully',
  TAXON_GROUP_UPDATE: 'Taxon Group updated successfully',
  TAXON_UPDATE: 'Taxon updated successfully',
  TAXON_GROUP_DELETE: 'Taxon Group deleted successfully',
  INVALID_TAXON_GROUP_ID: 'Invalid Taxon Group Id',
  INVALID_TAXON_GROUP_KEY: 'Invalid Taxon Group Key',
  INVALID_TIME_SLOT_ID: 'Invalid Time Slot Id',
  OPTION_TYPE_DELETE: 'Option Type deleted successfully',
  OPTION_VALUE_DELETE: 'Option Value deleted successfully',
  OPTION_VALUE_UPDATE: 'Option Value updated successfully',
  INVALID_OPTION_TYPE_ID: 'Invalid Option Type Id',
  INVALID_OPTION_VALUE_ID: 'Invalid Option Value Id',
  TRANSLATION_UPDATE: 'Translation updated successfully',
  TAXON_P_TG_UNDELETABLE: 'Remove the Products and Taxon Groups associated with the taxon "{label}"',
  TAXON_P_UNDELETABLE: 'Remove the Products associated with the taxon "{label}"',
  TAXON_TG_UNDELETABLE: 'Remove the Taxon Groups associated with the taxon "{label}"',
  NEW_HOLIDAY_CREATE: 'New Holiday created successfully',
  HOLIDAY_UPDATE: 'Holiday updated successfully',
  HOLIDAY_DELETE: 'Holiday deleted successfully',
  INVALID_PRODUCT_ID: 'Invalid Product Id',
  INVALID_PRODUCT_VARIANT_ID: 'Invalid Product Variant Id',
  INVALID_PRODUCT_WEIGHT: 'Invalid Weight for Product Id',
  INVALID_PRODUCT_BIN: 'Invalid Bin for Product Id',
  INVALID_SHIPMENT_PRODUCTS_COUNT: 'Invalid Products count for Order Picking Shipment ID',
  NEW_VARIANT_CREATE: 'New Variant created successfully',
  VARIANT_UPDATE: 'Variant updated successfully',
  VARIANT_DELETE: 'Variant deleted successfully',
  TAXON_ID_COPY_CLIPBOARD: 'Taxon Id copied to the clipboard',
  PROMOTION_DELETE: 'Promotion deleted successfully',
  INVALID_PROMOTION_ID: 'Invalid Promotion Id',
  NEW_PROMOTION_CREATE: 'New Promotion created successfully',
  ACCOUNT_INACTIVE: 'Your account is in an inactive state',
  INVALID_OPERATIONAL_EMPLOYEE_REQUEST: 'Invalid Operational Employee',
  STATUS_ALREADY_ONLINE: 'Status is already online',
  STATUS_ALREADY_OFFLINE: 'Status is already offline',
  PROMOTION_UPDATE: 'Promotion updated successfully',
  DELIVERY_FEE_UPDATE: 'Delivery Fee updated successfully',
  INVALID_ORDER_CANCELLATION_REASON_ID: 'Invalid Order Cancellation Reason Id',
  NAME_EN_EXIST: 'Name (EN) already exists',
  NAME_AR_EXIST: 'Name (AR) already exists',
  CANCELLATION_REASON_EN_EXIST: 'Cancellation Reason (EN) already exists',
  CANCELLATION_REASON_AR_EXIST: 'Cancellation Reason (AR) already exists',
  NEW_ORDER_CANCELLATION_CREATE: 'New Order Cancellation Reason created successfully',
  ORDER_CANCELLATION_UPDATE: 'Order Cancellation Reason updated successfully',
  ORDER_CANCELLATION_DELETE: 'Order Cancellation Reason deleted successfully',
  NEW_CUISINE_CREATE: 'New Cuisine created successfully',
  CUISINE_UPDATE: 'Cuisine updated successfully',
  CUISINE_UNDELETABLE: 'Remove the Recipes associated with the Cuisine',
  CUISINE_DELETE: 'Cuisine deleted successfully',
  INVALID_CUISINE_ID: 'Invalid Cuisine Id',
  INVALID_ORDER_ID: 'Invalid Order Id',
  INVALID_RECIPE_ID: 'Invalid Recipe Id',
  NEW_RECIPE_CREATE: 'New Recipe created successfully',
  RECIPE_DELETE: 'Recipe deleted successfully',
  RECIPE_UPDATE: 'Recipe updated successfully',
  NEW_INGREDIENT_ADD: 'New Ingredient added successfully',
  INGREDIENT_UPDATE: 'Ingredient updated successfully',
  INGREDIENT_DELETE: 'Ingredient deleted successfully',
  INVALID_INGREDIENT_ID: 'Invalid Ingredient Id',
  DELIVERY_REGION_EXIST: 'Delivery Region for this Supermarket already exists',
  NEW_DELIVERY_REGION: 'New Delivery Region created successfully',
  DELIVERY_REGION_UPDATE: 'Delivery Region updated successfully',
  DELIVERY_REGION_DELETE: 'Delivery Region deleted successfully',
  POLYGON_OVERLAP: 'This Region is overlapping with another Supermarket of the same Delivery Type',
  NEW_ZONE_CREATE: 'New Zone created successfully',
  ZONE_UPDATE: 'Zone updated successfully',
  INVALID_ZONE_ID: 'Invalid Zone Id',
  ZONE_NAME_EXIST: 'Zone Name already exists',
  ZONE_DELETE: 'Zone deleted successfully',
  NEW_BIN_CREATE: 'New Bin created successfully',
  INVALID_BIN_ID: 'Invalid Bin Id',
  LOCATION_POSITION_REPEATED: 'Position should be unique for each Location within a Zone',
  WMS_LOCATION_UNIQUE: 'Location should be unique within a Zone',
  BIN_UPDATE: 'Bin updated successfully',
  BIN_DELETE: 'Bin deleted successfully',
  NEW_LOCATIONS_CREATE: 'New Location(s) created successfully',
  ADD_LOCATION_ERROR: 'Please add the Location',
  INVALID_LOCATION_ID: 'Invalid Location Id',
  INVALID_PRODUCT_LOCATION_ID: 'Invalid Product Location Id',
  INVALID_STAGING_LOCATION_ID: 'Invalid Staging Location Id',
  LOCATION_UPDATE: 'Location updated successfully',
  LOCATION_DELETE: 'Location deleted successfully',
  NEW_PURCHASE_ORDER_CREATE: 'New Purchase Order created successfully',
  INVALID_PURCHASE_ORDER_ID: 'Invalid Purchase Order Id',
  PURCHASE_ORDER_CANCEL: 'Purchase Order cancelled successfully',
  REPORT_SUCCESS: 'Reported successfully',
  NO_QC_FAILED_PRODUCT_TO_REPORT: 'No QC Failed product to report',
  RECEIVING_DELETE: 'Receiving deleted successfully',
  RECEIVING_PRODUCT_NOT_IN_PO: 'Receiving Product(s) do not exist in Purchase Order',
  INVALID_RECEIVING_ID: 'Invalid Receiving Id',
  NEW_RECEIVING_CREATE: 'New Receiving created successfully',
  WMS_LOCATION_NOT_EXIST: 'Location does not exist',
  PLATE_NUMBER_EXIST: 'Plate # already exists',
  VEHICLE_ASSIGNED_TO_DRIVERS: 'Vehicle is already assigned to drivers ({label})',
  VEHICLE_DRIVER_ID_NOT_EXIST: 'Driver Id ({label}) does not exist',
  VEHICLE_NOT_ASSIGNED_TO_DRIVER: 'No Vehicle is assigned to this driver',
  VEHICLE_NOT_FOUND: 'Vehicle not found',
  PRODUCT_VARIANT_REQUIRED: 'Product Variant is required',
  STOCK_LOCATION_NOT_EXIST: 'Stock Location does not exist',
  INVALID_STOCK_ID: 'Invalid Stock Id',
  SRC_DES_LOCATION_NOT_SAME: 'Source and destination Location should not be same',
  ZONE_LOCATION_NOT_EXIST_SUPERMARKET: '{label} Zone/Location does not exist in this Supermarket',
  LOCATION_NOT_EXIST_ZONE_SUPERMARKET: '{label} Location does not exist in this Zone/Supermarket',
  INVALID_VEHICLE_ID: 'Invalid Vehicle Id',
  NEW_VEHICLE_CREATE: 'New Vehicle created successfully',
  VEHICLE_UPDATE: 'Vehicle updated successfully',
  VEHICLE_DELETE: 'Vehicle deleted successfully',
  INVALID_IMAGE_URL_VEHICLE_HEALTH_EVIDENCE: 'Invalid image url(s) for Vehicle health evidence',
  INVALID_PUTAWAY_ID: 'Invalid Putaway Id',
  PUTAWAY_ALREADY_COMPLETED: 'Putaway is already completed',
  PUTAWAY_CANNOT_SET_COMPLETE: 'Putaway can not be set to complete',
  PUTAWAY_EXPIRED_DELETED: 'Putaway expired or deleted',
  PICKED_UNITS_NOT_GREATER_PUTAWAY_UNITS: 'Picked units should not be greater than Putaway units',
  PUTAWAY_NOT_STARTED: 'Putaway not started yet',
  INVALID_DESTINATION_LOCATION: 'Invalid Destination Location',
  INVALID_SOURCE_LOCATION: 'Invalid Source Location',
  NEW_PUTAWAY_CREATE: 'New Putaway Assignment created successfully',
  PUTAWAY_ALREADY_CANCELLED: 'Putaway is already cancelled',
  PUTAWAY_ALREADY_REPORTED: 'Putaway is already reported',
  PUTAWAY_NOT_CANCELLED: 'Putaway can not be cancelled at this stage',
  PUTAWAY_NOT_REASSIGNED: 'Putaway can not be re-assigned at this stage',
  PUTAWAY_CANCEL: 'Putaway Assignment cancelled successfully',
  PUTAWAY_REASSIGN: 'Job has been reassigned',
  INVALID_ACCESS_TOKEN: 'Invalid access token',
  INVALID_OAUTH_TYPE: 'Invalid auth type',
  VALIDATION_FAILED: 'Validate failed',
  SPIKE_ADS_ERROR: 'Something went wrong, unable to retrieve ads from Spike',
  USER_CANT_PLACE_ORDER: 'user_cant_place_order',
  INSUFFICIENT_LOYALTY_BALANCE: 'insufficient_loyalty_balance',
  LOYALTY_PAYMENT_FAILED: 'loyalty_payment_failed',
  ORDER_CANCEL: 'Order cancelled successfully',
  ORDER_SHIPMENT_NOT_EXIST: 'Order Shipment does not exist',
  ORDER_PRODUCT_NOT_EXIST: 'Order Product does not exist',
  ORDER_PRODUCT_QUANTITY_IS_UNCHANGED: 'Order Product quantity is unchanged',
  PRODUCT_ID_EXCEED_MAXIMUM_LIMITS: 'Product ID {label} exceeded weight limit',
  SUPERMARKET_ID_SHIPMENT_EXCEED_LIQUID_LIMITS: `Supermarket ID {label} shipment exceeded liquid weight limit`,
  ORDER_PRODUCT_ALREADY_EXIST: 'Product already exists in this Order',
  SHIPMENT_MODIFICATION_NOT_ALLOWED: 'You are not allowed to modify shipment at this stage',
  ORDER_ITEM_ADD: 'New Order Item added successfully',
  ORDER_ITEM_UPDATE: 'Order Item updated successfully',
  ORDER_ITEM_DELETE: 'Order Item deleted successfully',
  ORDER_SHIPMENT_SHOULD_HAVE_ATLEAST_ONE_PRODUCT: 'Order shipment should have at least one product',
  ORDER_SHIPMENT_NOT_MET_MINIMUM_CART_TOTAL:
    'The shipment for supermarket ({label}) should have a minimum cart total amount of {amount}',
  ORDER_SHIPMENT_INVALID_DELIVERY_CHARGES:
    'Incorrect delivery charges for the shipment of Supermarket ({label}), please re-calculate',
  ORDER_PRODUCT_ALREADY_ASSIGNED: 'Already assigned products can not be selected',
  MAXIMUM_QUANTITY_LIMIT_REACHED: 'You have reach maximum quantity limit',
  ORDER_REPLACEMENT_PRODUCT_NOT_ALLOWED: 'Order Replacement Product(s) is not allowed',
  SMS_SENDING_FAILED: 'SMS sending failed',
  USER_MOBILE_NOT_VERIFIED: 'Please verify your mobile number before placing an order',
  STORE_PICKUP_HAVE_MULTIPLE_SHIPMENT: 'Pickup orders can have products from one store only',
  STORE_PICKUP_DELIVERY_TYPE_WRONG: 'Invalid delivery type for Pickup order',
  DELIVERY_ZONE_NAME_EXIST: 'Delivery Zone Name already exists',
  DUPLICATE_DELIVERY_DISTRICT: 'District ({label}) is already associated with another Delivery Zone',
  NEW_DELIVERY_ZONE_CREATE: 'New Delivery Zone created successfully',
  INVALID_DELIVERY_ZONE_ID: 'Invalid Delivery Zone Id',
  DELIVERY_ZONE_DELETE: 'Delivery Zone deleted successfully',
  INVALID_PICKED_QUANTITY_PRODUCT_ID: 'Invalid Picked Quantity for Product Id',
  PRODUCT_OUT_OF_STOCKS_AT_LOCATION: 'Picked product is out of stock at Location Id',
  DELIVERY_ZONE_UPDATE: 'Delivery Zone updated successfully',
  INVALID_IMAGE_URL: 'Invalid image url',
  INVALID_IMAGE_URL_REASON_ID: 'Invalid image url(s) for Reason Id',
  INVALID_REPORT_REASON_ID: 'Invalid Report Reason Id',
  PAYMENT_OPTION_NOT_AVAILABLE: 'Payment Option ({label}) not available',
  COD_OPTION_NOT_AVAILABLE: 'COD Option not available for the product',
  BIN_ID_USED_IN_MULTIPLE_SHIPMENT: 'Bin Id ({label}) is used in multiple shipments',
  DISPATCH_LOCATION_NOT_AVAILABLE:
    'There are no dispatch locations within the supermarket, please create a dispatch zone with at least one location',
  INVALID_DRIVER_ID: 'Invalid driver ID',
  BIN_QUANTITY_EXCEEDS_VEHICLE_CAPACITY: 'Bin quantity exceeds vehicle capacity',
  NEW_DELIVERY_BATCH_CREATE: 'New Delivery Batch created successfully',
  DELIVERY_BATCH_UPDATE: 'Delivery Batch updated successfully',
  DELIVERY_BATCH_REASSIGN: 'Driver updated successfully',
  DELIVERY_BATCH_CANCEL: 'Delivery Batch cancelled successfully',
  DELIVERY_BATCH_COMPLETE: 'Delivery Batch completed successfully',
  INVALID_ORDER_PICKING_SHIPMENT_BIN_ID: 'Invalid Order Picking Shipment Bin Id',
  INVALID_ORDER_PICKING_SHIPMENT_PRODUCT_ID: 'Invalid Order Picking Shipment Product Id',
  INVALID_DELIVERY_BATCH_ID: 'invalid_delivery_batch_id',
  DELIVERY_BATCH_NOT_REASSIGNED: 'Delivery Batch can not be re-assigned at this stage',
  DELIVERY_BATCH_ALREADY_CANCELLED: 'Delivery Batch is already cancelled',
  DELIVERY_BATCH_NOT_CANCELLED: 'Delivery Batch can not be cancelled at this stage',
  DISPATCH_JOB_EXPIRED_DELETED: 'Order delivery batch expired or deleted',
  DISPATCH_JOB_ALREADY_COMPLETED: 'Order delivery batch is already completed',
  DISPATCH_JOB_ALREADY_CANCELLED: 'Order delivery batch is already cancelled',
  DISPATCH_JOB_ALREADY_IN_PROGRESS: 'Order delivery batch is already in progress',
  DISPATCH_JOB_NOT_STARTED: 'Order delivery batch is not started yet',
  INVALID_STAGGING_DISPATCH_JOB_ID: 'Invalid Stagger job Id',
  STAGGING_DISPATCH_CANNOT_REASSIGN: 'Stagger job can not be re-assigned at this stage',
  STAGGING_DISPATCH_REASSIGN: 'Staging-Dispatch job reassigned successfully',
  UNKNOWN_RETURNED_BIN: 'Returned Bin ({bin_id}) does not belong to this batch',
  RETURNED_BIN_INVALID_LOCATION: 'Invalid Location ({location_id}) for the returned Bin ({bin_id})',
  ORDER_ADJUSTED_BY_PROMOTION: 'Order adjusted by Promotion ({promotion_name})',
  DISPATCH_JOB_ADMIN_CANT_COMPLETE: "Dispatch Job can't be completed manually at this stage",
  PAYMENT_REFUND_ERROR: 'Failed to refund the payment',
  ORDER_PAYMENT_NOT_ELIGIBLE_FOR_GIFT_CARDS:
    'Order with Gift Cards are not eligible for payment via {payment_option_name}',
  INVALID_CUSTOMER_ID: 'Invalid Customer Id',
  INVALID_PHILOSOPHIC_CREDENTIALS: 'Invalid philosophic credentials',
  PHILOSOPHIC_AUTH_FAIL: 'Philosophic authentication failed',
  INVALID_PHILOSOPHIC_TRANSACTION_ID: 'Invalid transaction ID for gift card activation',
  UNABLE_TO_PROCESS_PHILOSPHIC_REQUEST: 'Unable to process Philosophic request',
  UNABLE_TO_CHARGE_GIFT_CARD_AMOUNT: 'Unable to charge gift card amount',
  PRODUCT_LOCATION_AND_STOCK_FAILED: 'Product location and stock upload failed to complete',
  INVALID_CHECKOUT_CARD_ID: 'Invalid Checkout card id',
  ALREADY_MAPPED_REASON: '{label} already exists and mapped to another {mapped_to}',
  UNABLE_TO_PROCESS_VALUS_REQUEST: 'Unable to process valus request',
  REFUND_SUCCESS: 'The refund has been accepted, the status will update once the amount is refunded',
  INVALID_REFUND_ID: 'Invalid Refund Id',
  INVALID_PRODUCT_REPORT_ID: 'Invalid Product Report Id',
  PRODUCT_REPORT_RESOLVE_SUCCESS: 'Product report resolved successfully',
  SUPERMARKET_INVENTORY_MODIFIER_NOT_EXIST:
    'Inventory Modifiers do not exist for product (ID: {product_id}), of the receiving supermarket',
  SUPERMARKET_ON_HOLIDAY:
    '{supermarket} Supermarket can not deliver on {deliveryDate}, since it is {holiday} holiday today',
  SUPERMARKET_CANNOT_DELIVER_EXPRESS_ORDER: '{supermarket} Supermarket can not deliver express orders currently',
  INADEQUATE_BALANCE_IN_E_WALLET: 'Inadequate balance in the e-wallet',
  INVALID_REFERRAL_CODE: 'Invalid referral code',
  PRINTER_ID_NOT_EXIST: 'Please set the printer ID first for the supermarket',
  PRINT_JOB_REFERNCE_NOT_EXIST: 'Print Job reference does not exist',
  PRINTER_NOT_ONLINE: 'The printer is not online',
  NO_PRINTER_FOUND: 'No such printer found',
  SHIPMENT_RESET_SUCCESS: 'Shipment reset successfully',
  SHIPMENT_RESET_FAILED: 'Failed to reset Shipment',
  SHIPMENT_RESCHEDULE_SUCCESS: 'Shipment rescheduled successfully',
  SHIPMENT_RESET_FAIL_CONTAINS_GIFT_CARD_CATEGORY: 'Unable to reset, shipment contains a gift card item',
  ORDER_PICKING_BATCH_NOT_EXISTS: 'order_picking_batch_not_exists',
  DISPATCH_JOB_SHIPMENT_NO_LONGER_EXIST: 'dispatch_job_shipment_no_longer_exist',
  DISPATCH_JOB_SHIPMENT_NOT_ARRIVED: 'dispatch_job_shipment_not_arrived',
  DISPATCH_JOB_RETURNED_ITEMS_EMPTY: 'dispatch_job_returned_items_empty',
  DISPATCH_JOB_RETURNED_ITEM_INVALID_PRODUCT: 'Returned item ({product_id}) does not belong to this shipment',
  DISPATCH_JOB_RETURNED_ITEM_INVALID_QUANTITY: 'dispatch_job_returned_item_invalid_quantity',
  STAGGER_JOB_NO_LONGER_ASSIGNED: 'stagger_job_no_longer_assigned',
  ORDER_PICKING_SHIPMENT_NO_LONGER_EXISTS: 'order_picking_shipment_no_longer_exists',
  SHIPMENT_BINS_PRODUCTS_REMOVED_AND_MISSING: 'shipment_bins_products_removed_and_missing',
  SHIPMENT_BINS_PRODUCTS_REMOVED: 'shipment_bins_products_removed',
  PICK_MISSING_SHIPMENT_BINS_PRODUCTS: 'pick_missing_shipment_bins_products',
  INVALID_USER_GIFT_CARD_BALANCE: 'check_your_gift_card_balance',
  MAX_ALLOWED_WEIGHT_EXCEEDED: 'max_allowed_weight_exceeded',
  MIN_REQUIRED_WEIGHT_NOT_REACHED: 'min_required_weight_not_reached',
  UNABLE_TO_MODIFY_GIFT_CARD_ITEM: 'Unable to modify gift card item',
  ORDER_RECEIVED: 'Order Received!',
  CATEGORY_CREATE: 'New Category created successfully',
  CATEGORY_DELETE: 'Category delete successfully',
  CATEGORY_UPDATE: 'Category updated successfully',
  INVALID_CATEGORY_ID: 'Invalid Category Id',
  ZATCHA_ERROR: 'error_creating_zatcha_record',
  CSV_FILE_DOWNLOAD_ERROR: "Error downloading CSV File"
};
