export default {
  QUEUE_RETRY_DELAY: 1000,
  QUEUE_MAX_RETRY_ATTEMPTS: 3,
  ATTEMPT_COUNT_ON_INITIALIZATION: 0,
  ORDER_QUEUE: 'ORDER-QUEUE',
  ORDER_QUEUE_SERVICE_PROVIDER: 'ORDER_QUEUE_SERVICE',
  PRODUCT_QUEUE: 'PRODUCT-QUEUE',
  PRODUCT_QUEUE_SERVICE_PROVIDER: 'PRODUCT_QUEUE_SERVICE',
  DEAD_LETTER_EXCHANGE_NAME: 'dead_letter_exchange'
};
