import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from './../common.interface';

export interface IManufacturer extends IBaseAudit {
  name: string;
  code: string;
}

export interface IManufacturerListItem extends IManufacturer {
  id: number;
}

export const manufacturerSelect = {
  ...BaseSelect,
  code: true
};

export type IManufacturerListData = {
  page: number;
  code: string;
  name: string;
};

export interface IManufacturerListPayload extends IManufacturerListData {
  [index: string | number]: string | string[] | number | number[];
}

export enum ManufacturerGridFilterEnum {
  PAGE = 'page',
  CODE = 'code',
  NAME = 'name'
}
