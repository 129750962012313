import { Pipe, PipeTransform } from '@angular/core';

import { ITimeSlot } from '@ecommerce/common-types';

@Pipe({ name: 'formatTimeSlot' })
export class FormatTimeSlotPipe implements PipeTransform {
  transform(timeSlot: ITimeSlot): string {
    if (!timeSlot?.start_time || !timeSlot.end_time) return '';

    const startTimeMeridiem: string = parseInt(timeSlot.start_time?.split(':')[0]) >= 12 ? ' PM' : ' AM';
    const endTimeMeridiem: string = parseInt(timeSlot.end_time?.split(':')[0]) >= 12 ? ' PM' : ' AM';

    return timeSlot.start_time + startTimeMeridiem + ' - ' + timeSlot.end_time + endTimeMeridiem;
  }
}
