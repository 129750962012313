import { IEmployeeData } from './employee.interface';
import { IAssignedJobsCount } from './common.interface';

export interface IWmsPackerWithJobsCount {
  packer: IEmployeeData;
  stagger_jobs_count?: number;
}

interface IWmsPackerWithJobsCountData {
  supermarket_id: number;
  page: number;
  name: string;
  availability: string;
}

export interface IWmsPackerWithJobsCountListPayload extends IWmsPackerWithJobsCountData {
  [index: string]: string | number;
}

export interface IGrpcGetAvailablePackersReq {
  supermarket_ids: number[];
}

export interface IGrpcGetAvailablePackersRes {
  packers: IEmployeeData[];
}

export interface IPackerAssignedJobs {
  stagger_job: IAssignedJobsCount;
}
