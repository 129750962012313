import { IBaseAudit } from '../auditable.interface';
import { IOrder } from './order.interface';
import { IStoreCredit } from './store-credit.interface';

export interface ICashback extends IBaseAudit {
  order_id?: number;
  promotion_action_id?: number;
  promotion_code_id?: number;
  store_credit_id?: number;
  credit_expiry_offset?: number;
  label?: string;
  amount: number;
  eligible?: boolean;
  expires_at?: Date;
  order?: IOrder;
  store_credit?: IStoreCredit;
}
