import { IBaseAudit } from '../auditable.interface';
import { ISuperMarket } from './super-market.interface';

export interface ITimeSlot extends IBaseAudit {
  start_time: string;
  end_time: string;
  country_id: number;
  delivery_option_id: number;
  day_of_week: DaysOfWeekEnum;
  is_enabled?: boolean;
  delivery_capacities?: IDeliveryCapacity[];
}

export interface ITimeSlotBaseList {
  id: number;
  start_time: string;
  end_time: string;
}

export interface ITimeSlotBaseListPayload {
  supermarket_id: number;
  delivery_date: string;
}

export const timeSlotSelect = {
  id: true,
  start_time: true,
  end_time: true,
  is_enabled: true,
  delivery_capacities: {
    id: true,
    supermarket_id: true,
    time_slot_id: true,
    capacity: true,
    is_enabled: true
  }
};

export interface IDeliveryCapacity extends IBaseAudit {
  supermarket_id: number;
  time_slot_id: number;
  capacity: number;
  is_enabled: boolean;
  time_slot?: ITimeSlot;
  supermarket?: ISuperMarket;
}

export enum DaysOfWeekEnum {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday'
}

export const daysOfWeekOptions = [
  {
    id: DaysOfWeekEnum.SUNDAY,
    name: 'Sunday'
  },
  {
    id: DaysOfWeekEnum.MONDAY,
    name: 'Monday'
  },
  {
    id: DaysOfWeekEnum.TUESDAY,
    name: 'Tuesday'
  },
  {
    id: DaysOfWeekEnum.WEDNESDAY,
    name: 'Wednesday'
  },
  {
    id: DaysOfWeekEnum.THURSDAY,
    name: 'Thursday'
  },
  {
    id: DaysOfWeekEnum.FRIDAY,
    name: 'Friday'
  },
  {
    id: DaysOfWeekEnum.SATURDAY,
    name: 'Saturday'
  }
];

export interface ITimeSlotListPayload {
  [index: string]: number | string | boolean | number[];
}

export interface ITimeSlotFilterList extends ITimeSlot {
  checked: boolean;
}

export interface IGrpcOperationalEmployeeLogRequest {
  employeeId: number;
  type: string;
}

export interface IGrpcOperationalEmployeeLogResponse extends IGrpcOperationalEmployeeLogRequest {
  id: number;
  time: Date;
}

export const timeSlotBaseListSelect = {
  id: true,
  supermarket_id: true,
  time_slot: {
    id: true,
    start_time: true,
    end_time: true,
    is_enabled: true,
    day_of_week: true
  }
};
