import { IBaseAudit } from '../auditable.interface';

export interface IOrderCancellationReason extends IBaseAudit {
  reason_en: string;
  reason_ar: string;
  visible: boolean;
}

export interface IOrderCancellationReasonListItem extends IOrderCancellationReason {
  id: number;
}

export interface IConsumerOrderCancellationReason {
  id: number;
  reason: string;
}

export const orderCancellationReasonSelect = {
  id: true,
  reason_en: true,
  reason_ar: true,
  visible: true
};

export const orderCancellationReasonConsumerListSelect = {
  id: true,
  reason_en: true
};

export const orderCancellationReasonBaseListSelect = {
  id: true,
  reason_en: true,
  reason_ar: true
};

export type IOrderCancellationReasonListData = {
  page: number;
  reason_en: string;
  reason_ar: string;
};

export interface IOrderCancellationReasonListPayload extends IOrderCancellationReasonListData {
  [index: string | number]: string | number;
}

export enum OrderCancellationReasonGridFilterEnum {
  PAGE = 'page',
  REASON_EN = 'reason_en',
  REASON_AR = 'reason_ar'
}
