import { FindOperator } from 'typeorm';

import { IBaseAudit } from '../auditable.interface';

export interface IDeliveryZone extends IBaseAudit {
  name: string;
  districts?: IDeliveryZoneDistrict[];
}

export interface IDeliveryZoneDistrict extends IBaseAudit {
  zone_id?: number;
  name: string;
  delivery_zone?: IDeliveryZone;
}

export interface IDistrictNameFindOperator {
  name: FindOperator<string>;
}

export const deliveryZoneSelect = {
  id: true,
  name: true,
  districts: {
    id: true,
    zone_id: true,
    name: true
  }
};

export const deliveryDistrictSelect = {
  id: true,
  name: true,
  zone_id: true,
  delivery_zone: {
    id: true,
    name: true
  }
};

export interface IDeliveryZoneListData {
  page: number;
  id: string;
  name: number;
  district_name: string;
}

export interface IDeliveryZoneListPayload extends IDeliveryZoneListData {
  [index: string]: string | number;
}

export enum DeliveryZoneGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  NAME = 'name',
  DISTRICT_NAME = 'district_name'
}
