import { IBaseAudit } from '../auditable.interface';

export interface IJobEntity extends IBaseAudit {
  job_type: string;
  completed_at: Date;
}

export const jobEntityListSelect = {
  id: true,
  job_type: true,
  completed_at: true,
  created_at: true
};
