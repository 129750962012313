import { IBaseAudit } from './auditable.interface';
import { IUser } from './user.interface';

export interface ICheckoutCard extends IBaseAudit {
  name_on_card: string;
  instrument_id: string;
  last_digits?: string;
  card_type: string;
  expiry_month: number;
  expiry_year: number;
  scheme: string;
  scheme_local: string;
  user_id: number;
  bin?: string;
  user?: IUser;
}

export interface ICheckoutPage {
  checkout_public_key: string;
  api_base_url: string;
}

export interface ICheckoutPaymentRequest {
  source: Partial<ICheckoutPaymentSource>;
  processing_channel_id: string;
  amount: number;
  currency: string;
  success_url: string;
  failure_url: string;
  reference?: string;
  metadata?: ICheckoutPaymentMetadata;
  '3ds'?: ICheckoutPayment3dsRequest;
}

export interface ICheckoutPayment3dsRequest {
  enabled: boolean;
  attempt_n3d?: boolean;
}

export interface ICheckoutPaymentMetadata {
  [key: string]: string;
}

export interface ICheckoutPaymentRefundRequest {
  amount: number;
  reference?: string;
  metadata?: ICheckoutPaymentMetadata;
}

export interface ICheckoutErrorResponse {
  error_type?: string;
}

export interface ICheckoutPaymentRefundResponse {
  action_id?: string;
  reference?: string;
  _links?: ICheckoutPaymentLinks;
  metadata?: ICheckoutPaymentMetadata;
  error?: ICheckoutErrorResponse;
}

export interface ICheckoutPaymentDetailResponse {
  id: string;
  requested_on: string;
  amount: number;
  currency: string;
  payment_type: string;
  reference: string;
  description: string;
  approved: boolean;
  expires_on: string;
  status: string;
  balances: ICheckoutPaymentDetailBalances;
  eci: string;
  scheme_id: string;
  actions: ICheckoutPaymentDetailAction[];
  _links: ICheckoutPaymentLinks;
}

export interface ICheckoutPaymentDetailBalances {
  total_authorized: number;
  total_voided: number;
  available_to_void: number;
  total_captured: number;
  available_to_capture: number;
  total_refunded: number;
  available_to_refund: number;
}

export interface ICheckoutPaymentDetailAction {
  id: string;
  type: string;
  response_code: string;
  response_summary: string;
}

export interface ICheckoutPaymentResponse {
  id?: string;
  action_id?: string;
  amount?: number;
  currency?: string;
  approved?: boolean;
  status?: string;
  auth_code?: string;
  response_code?: string;
  response_summary?: string;
  balances?: ICheckoutPaymentBalances;
  risk?: ICheckoutPaymentRisk;
  source?: ICheckoutPaymentSource;
  processed_on?: string;
  scheme_id?: string;
  processing?: ICheckoutPaymentProcessing;
  expires_on?: string;
  '3ds'?: ICheckout3dsPaymentResponse;
  _links?: ICheckoutPaymentLinks;
  error?: ICheckoutErrorResponse;
}

export interface ICheckout3dsPaymentResponse {
  downgraded: boolean;
  enrolled: string;
}

export interface ICheckoutPaymentLinks {
  self: ICheckoutPaymentHref;
  actions: ICheckoutPaymentHref;
  redirect: ICheckoutPaymentHref;
  authorize: ICheckoutPaymentHref;
  refund: ICheckoutPaymentHref;
  payment: ICheckoutPaymentHref;
}

export interface ICheckoutPaymentHref {
  href: string;
}

interface ICheckoutPaymentBalances {
  total_authorized: number;
  total_voided: number;
  available_to_void: number;
  total_captured: number;
  available_to_capture: number;
  total_refunded: number;
  available_to_refund: number;
}

interface ICheckoutPaymentRisk {
  flagged: boolean;
  score: number;
}

interface ICheckoutPaymentSource {
  id: string;
  type: CheckoutPaymentTypeEnum;
  cvv: string;
  phone: ICheckoutPaymentPhone;
  expiry_month: number;
  expiry_year: number;
  scheme: string;
  last4: string;
  fingerprint: string;
  bin: string;
  card_type: string;
  card_category: string;
  issuer_country: string;
  product_id: string;
  product_type: string;
  avs_check: string;
  cvv_check: string;
  payment_account_reference: string;
  token?: string;
}

interface ICheckoutPaymentPhone {
  country_code: string;
  number: string;
}

interface ICheckoutPaymentProcessing {
  acquirer_transaction_id: string;
  retrieval_reference_number: string;
  merchant_category_code: string;
  scheme_merchant_id: string;
  aft: boolean;
}

export interface ICheckoutInstrumentRequest {
  type: CheckoutPaymentTypeEnum;
  token: string;
  account_holder: ICheckoutPaymentAccountHolder;
  customer: Partial<ICheckoutInstrumentCustomer>;
}

interface ICheckoutPaymentAccountHolder {
  first_name: string;
  last_name: string;
  phone: ICheckoutPaymentPhone;
}

interface ICheckoutInstrumentCustomer {
  id: string;
  email: string;
  name: string;
  phone: ICheckoutPaymentPhone;
  default: boolean;
}

export interface ICheckoutInstrumentResponse {
  id: string;
  type: CheckoutPaymentTypeEnum;
  fingerprint: string;
  expiry_month: number;
  expiry_year: number;
  scheme: string;
  scheme_local: string;
  last4: string;
  bin: string;
  card_type: string;
  card_category: string;
  issuer: string;
  issuer_country: string;
  customer: ICheckoutInstrumentCustomer;
}

export interface ICheckoutPaymentCompletePage {
  api_base_url: string;
  error_message: string;
}

export interface ICheckoutWebhookRes {
  id: string;
  type: WebhookEventTypeEnum;
  version: string;
  created_on: string;
  data: ICheckoutWebhookData;
}

export interface ICheckoutWebhookData {
  id: string;
  action_id: string;
  reference: string;
  amount: number;
  payment_actions: string;
  refund: string;
}

export interface ICheckoutWebhookEventData {
  payment_id: string;
  type: WebhookEventTypeEnum;
}

export interface IGrpcInitiateOrderPickingPaymentReq {
  user_id: number;
  user_card_id: number;
}

export interface IGrpcGetCheckoutCardDetailReq {
  user_card_id: number;
}

export interface IGrpcGetCheckoutCardDetailRes {
  checkout_card_detail: ICheckoutCard;
}

export enum CheckoutPaymentTypeEnum {
  ID = 'id',
  TOKEN = 'token',
  CARD = 'card'
}

export enum WebhookEventTypeEnum {
  PAYMENT_CAPTURED = 'payment_captured',
  PAYMENT_REFUNDED = 'payment_refunded',
  PAYMENT_REFUND_DELINED = 'payment_refund_declined'
}

export const checkoutCardsSelect = {
  id: true,
  name_on_card: true,
  last_digits: true,
  bin: true,
  expiry_month: true,
  expiry_year: true,
  scheme: true,
  scheme_local: true,
  card_type: true
};
