export default {
  SUCCESS_CODE_600: '600',
  ERROR_CODE_601: '601',
  ERROR_CODE_616: '616',
  ERROR_CODE_617: '617',
  ERROR_CODE_630: '630',
  ERROR_CODE_680: '680',
  ERROR_CODE_650: '650',
  ERROR_CODE_1001: '1001'
};
