import { IBaseAudit } from '../auditable.interface';
import { ICheckoutErrorResponse } from '../checkout.interface';
import { ICommonOption } from '../common.interface';
import { IProduct } from '../product/product.interface';
import { ITaxation } from '../product/taxation.interface';
import { IOrder } from './order.interface';
import { IStoreCredit, IStoreCreditTransaction } from './store-credit.interface';

export interface IOrderPayment {
  id?: number;
  order_id: number;
  shipment_id?: number;
  payment_option_id?: number;
  transaction_id?: string;
  source_id?: number;
  source_type?: string;
  error_msg?: string;
  amount: number;
  currency: string;
  status: string;
  approved: boolean;
  order?: IOrder;
}

export interface IOrderPaymentRefund extends IBaseAudit {
  order_id: number;
  order_payment_id?: number;
  transaction_id?: string;
  refund_action_id?: string; // Checkout payment platform 'action_id' of order payment refund transaction
  currency: string;
  amount: number;
  status: OrderPaymentRefundStatusEnum;
  type: OrderPaymentRefundTypeEnum;
  source_type?: OrderPaymentSourceTypeEnum;
  refunded_at?: Date;
  approved_by?: number;
  approved_at?: Date;
  order?: IOrder;
  error_msg?: string;
  order_payment?: IOrderPayment;
}

export interface IInitiateOrderPaymentReq {
  instrument_id: string;
  amount: number;
  currency: string;
  scheme_local: string;
  cvv?: string;
  user_id?: number;
}

export interface IInitiateOrderPaymentRes {
  payment_id: string;
  redirection_url: string;
  amount?: number;
  currency?: string;
  status?: string;
  approved?: boolean;
  error?: ICheckoutErrorResponse;
}

export interface IOrderEWalletTransactions {
  storeCreditOrderPayments: Partial<IOrderPayment>[];
  updatedStoreCredits: IStoreCredit[];
  storeCreditTransactions: IStoreCreditTransaction[];
  orderTotalAfterEwalletCharged: number;
}

export interface IGrpcProcessOrderRefundReq {
  user_id: number;
  user_card_id?: number;
}

export interface IInitiateOrderPaymentRefundReq {
  payment_id: string;
  amount: number;
  currency: string;
}

export interface IGrpcCalculateOrderRefundReq {
  country_id: number;
  product_ids: number[];
}

export interface IGrpcCalculateOrderRefundRes {
  products: IProduct[];
  taxations: ITaxation[];
}

export interface IGrpcProcessCheckoutRefundReq {
  order: IOrder;
}

export interface IRefundListData {
  page: number;
  id: string;
  order_id: number;
  status: string;
}

export interface IRefundListPayload extends IRefundListData {
  [index: string]: string | number;
}

export enum RefundGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  ORDER_ID = 'order_id',
  STATUS = 'status',
  SOURCE_TYPE = 'source_type'
}

export enum OrderPaymentSourceTypeEnum {
  CREDIT_CARD = 'CreditCard',
  STORE_CREDIT = 'StoreCredit',
  LOYALTY_POINTS = 'LoyaltyPoints'
}

export enum OrderPaymentStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export enum OrderPaymentRefundStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REFUNDED = 'refunded',
  ADJUSTED = 'adjusted',
  FAILED = 'failed'
}

export enum OrderPaymentRefundTypeEnum {
  ORDER_CANCEL = 'order_cancel',
  ORDER_PICKING = 'order_picking'
}

export const refundStatusOptions: ICommonOption<OrderPaymentRefundStatusEnum>[] = [
  {
    id: OrderPaymentRefundStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: OrderPaymentRefundStatusEnum.APPROVED,
    name: 'Approved'
  },
  {
    id: OrderPaymentRefundStatusEnum.REFUNDED,
    name: 'Refunded'
  },
  {
    id: OrderPaymentRefundStatusEnum.ADJUSTED,
    name: 'Adjusted'
  },
  {
    id: OrderPaymentRefundStatusEnum.FAILED,
    name: 'Failed'
  }
];

export const orderPaymentSourceTypeOptions: ICommonOption<OrderPaymentSourceTypeEnum>[] = [
  {
    id: OrderPaymentSourceTypeEnum.CREDIT_CARD,
    name: 'Credit Card'
  },
  {
    id: OrderPaymentSourceTypeEnum.STORE_CREDIT,
    name: 'Store Credit'
  },
  {
    id: OrderPaymentSourceTypeEnum.LOYALTY_POINTS,
    name: 'Loyalty Points'
  }
];

export const orderPaymentRefundSelect = {
  id: true,
  order_id: true,
  status: true,
  amount: true,
  currency: true,
  source_type: true
};
