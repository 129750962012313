import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoggedInGuard } from './common/auth/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'unauthorize',
    loadChildren: () => import('./auth/unauthorize/unauthorize.module').then((m) => m.UnAuthorizeModule)
  },
  {
    path: '',
    loadChildren: () => import('./module/auth-layout/auth-layout.module').then((m) => m.AuthLayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
