import { ICommonOption } from '../common.interface';
import { IBaseAudit } from '../auditable.interface';
import { IEmployeeData, OperationalEmployeeRoleEnum } from '../employee.interface';
import { IProductVariant } from '../product/product-variant.interface';
import { IProduct } from '../product/product.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IWmsLocation } from './wms-location.interface';
import { IWmsZone } from './wms-zone.interface';
import { BaseSelect } from './../common.interface';
import { UserTypeEnum } from '../user.interface';
import { IWmsProductReport } from './wms-product-report.interface';

export interface IWmsPutaway extends IBaseAudit {
  supermarket_id: number;
  product_id: number;
  assigned_to: number;
  status: WmsPutawayStatusEnum;
  src_zone_id: number;
  units: number;
  picked_units: number;
  dest_zone_id: number;
  product_variant_id?: number;
  src_location_id?: number;
  dest_location_id?: number;
  product_report_id?: number;
  putaway_at?: Date;
  picker: IEmployeeData;
  product_variant?: IProductVariant;
  src_zone?: IWmsZone;
  src_location?: IWmsLocation;
  dest_zone?: IWmsZone;
  dest_location?: IWmsLocation;
  product?: IProduct;
  supermarket: ISuperMarket;
  assigned_at?: Date;
  product_report?: IWmsProductReport;
}

export interface IUserGrpcValidateAssignedTo {
  employeeId: number;
  supermarketId: number;
  assignedTo: number;
  assignedToUserType: UserTypeEnum;
  assignedToUserRole: OperationalEmployeeRoleEnum;
}

export interface IUserGrpcValidateAssignedToResponse {
  isValid: boolean;
}

export interface IWmsPutawayPayload extends IWmsPutaway {
  src_stock_id?: number;
  dest_stock_id?: number;
}

export interface IWmsPutawayListData {
  page: number;
  assigned_to: number;
  supermarket_id: number;
  id: string;
  units: string;
  status: string;
  created_at: string;
  product_name: string;
}

export interface IWmsPutawayListPayload extends IWmsPutawayListData {
  [index: string]: string | number;
}

export interface IWmsPutawayReassignPayload {
  assigned_to: number;
}

export enum WmsPutawayStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  REPORTED = 'reported',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export enum WmsPutawayGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  UNITS = 'units',
  STATUS = 'status',
  PRODUCT_NAME = 'product_name',
  SUPERMARKET_ID = 'supermarket_id',
  ASSIGNED_TO = 'assigned_to'
}

export const assignedJobsPutawayZoneSelect = {
  ...BaseSelect,
  stocks: {
    location_id: true,
    product_id: true,
    product_variant_id: true,
    units: true
  }
};

export const assignedJobsPutawayLocationSelect = {
  id: true,
  aisle: true,
  bay: true,
  level: true,
  division: true
};

export const assignedJobsPutawaySelect = {
  id: true,
  product_id: true,
  status: true,
  units: true,
  picked_units: true,
  product_variant_id: true,
  putaway_at: true,
  src_location_id: true,
  src_zone: assignedJobsPutawayZoneSelect,
  src_location: assignedJobsPutawayLocationSelect,
  dest_zone: assignedJobsPutawayZoneSelect,
  dest_location: assignedJobsPutawayLocationSelect
};

export const putawayStatusOptions: ICommonOption<WmsPutawayStatusEnum>[] = [
  {
    id: WmsPutawayStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: WmsPutawayStatusEnum.IN_PROGRESS,
    name: 'In Progress'
  },
  {
    id: WmsPutawayStatusEnum.COMPLETED,
    name: 'Completed'
  },
  {
    id: WmsPutawayStatusEnum.CANCELLED,
    name: 'Cancelled'
  }
];

export const wmsPutawayListSelect = {
  id: true,
  supermarket_id: true,
  product_id: true,
  assigned_to: true,
  status: true,
  src_zone_id: true,
  units: true,
  picked_units: true,
  dest_zone_id: true,
  product_variant_id: true,
  src_location_id: true,
  dest_location_id: true,
  product_report_id: true,
  putaway_at: true,
  created_at: true,
  assigned_at: true
};
