import { IBaseAudit } from '../auditable.interface';
import { IRecipe } from './recipe.interface';

export interface ICuisine extends IBaseAudit {
  name_en: string;
  name_ar: string;
  position: number;
  recipes?: IRecipe[];
  recipe_list?: IRecipe[];
  is_deletable?: boolean;
}

export interface ICuisineListItem extends ICuisine {
  id: number;
}

export const cuisineListSelect = {
  id: true,
  name_en: true,
  name_ar: true,
  position: true
};

export const cuisineBaseListSelect = {
  id: true,
  name_en: true
};
