import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ApiService {
  public constructor(private http: HttpClient) {}

  public get<T>(path: string, params: HttpParams = new HttpParams()) {
    return this.http.get<T>(path, { params });
  }

  public post<T, T1>(path: string, body: T1) {
    return this.http.post<T>(path, body);
  }

  public put<T, T1>(path: string, body: T1) {
    return this.http.put<T>(path, body);
  }

  public delete<T>(path: string) {
    return this.http.delete<T>(path);
  }
}
