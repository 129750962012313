import { IBaseAudit } from './auditable.interface';

export interface IPaymentOption extends IBaseAudit {
  name?: string;
  code?: string;
}

export enum PaymentOptionCodeEnum {
  CREDIT = 'credit',
  APAY = 'a-pay',
  COD = 'cod',
  EWLT = 'e-wlt',
  LOYPTS = 'loy-pts'
}
