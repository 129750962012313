import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';
import { IEmployeeData } from '../employee.interface';
import { ICashback } from './cashback.interface';
import { IUserGiftCard } from './gift-card.interface';

export interface IStoreCredit extends IBaseAudit {
  user_id?: number;
  category_id?: number;
  created_by_id?: number;
  amount: number;
  amount_used: number;
  amount_authorized: number;
  currency?: string;
  memo?: string;
  type_id?: number;
  user_gift_card_id?: number;
  invalidated_at?: Date | null;
  expires_at?: Date;
  meta?: Record<string, unknown>;
  category?: IStoreCreditCategory[];
  type?: IStoreCreditType[];
  cashback?: ICashback[];
  store_credit_transactions?: IStoreCreditTransaction[];
  employee?: IEmployeeData;
  user_gift_card?: IUserGiftCard;
}

export interface IStoreCreditType extends IBaseAudit {
  name: string;
  priority: number;
}

export interface IStoreCreditCategory extends IBaseAudit {
  name: string;
  available_to_admin: boolean;
}

export interface IStoreCreditUpdateReason extends IBaseAudit {
  name: string;
}

export interface IStoreCreditTransaction extends IBaseAudit {
  store_credit_id: number;
  action: StoreCreditTransactionActionEnum;
  amount?: number;
  user_total_amount: number; // balance of user's e-wallet after performing a transaction
  authorization_code: string;
  originator_type?: StoreCreditTransactionOriginatorTypeEnum;
  originator_id?: number;
  update_reason_id?: number;
  currency?: string;
  store_credit?: IStoreCredit;
  store_credit_update_reasons?: IStoreCreditUpdateReason[];
}

export interface IUserStoreCreditsBalance {
  available_balance: number;
}

export interface IUserReferralEarnings {
  referral_earnings: number;
}

type IStoreCreditTransactionListData = {
  page: number;
  originator: string;
};

export interface IStoreCreditTransactionListPayload extends IStoreCreditTransactionListData {
  [index: string]: string | number;
}

export enum StoreCreditTransactionActionEnum {
  ADJUSTMENT = 'adjustment', // adjustment by admin panel
  ALLOCATION = 'allocation', // allocation is when manually store credit is created by admin users using bulk create tools
  INVALIDATE = 'invalidate', // invalidated due to expiry reached
  AUTHORIZE = 'authorize', // authorized for payment on an order
  ELIGIBLE = 'eligible', // ?
  VOID = 'void', // refunded an authorized payment
  CREDIT = 'credit', // credited
  CAPTURE = 'capture' // captured
}

export enum StoreCreditTransactionOriginatorTypeEnum {
  CASHBACK = 'Cashback',
  USER = 'User',
  USER_GIFT_CARD = 'UserGiftCard',
  REFUND = 'Refund',
  PAYMENT = 'Payment',
  REFERRAL = 'Referral'
}

export enum StoreCreditTypeNameEnum {
  NON_EXPIRING = 'Non Expiring',
  EXPIRING = 'Expiring'
}

export enum StoreCreditCategoryNameEnum {
  GIFT_CARD_CREDIT = 'Gift Card Credit',
  PAYMENT_REFUND_CREDIT = 'Payment Refund Credit',
  REFERRER_CREDIT = 'Referrer Credit',
  REFEREE_CREDIT = 'Referee Credit'
}

export enum StoreCreditUpdateReasonNameEnum {
  RECHARGED_OFFLINE = 'Gift card recharge offline',
  USED_OFFLINE = 'Used by offline order'
}

export const storeCreditTransactionOriginatorTypeOptions: ICommonOption<StoreCreditTransactionOriginatorTypeEnum>[] = [
  {
    id: StoreCreditTransactionOriginatorTypeEnum.CASHBACK,
    name: 'Cashback'
  },
  {
    id: StoreCreditTransactionOriginatorTypeEnum.USER,
    name: 'User'
  },
  {
    id: StoreCreditTransactionOriginatorTypeEnum.USER_GIFT_CARD,
    name: 'User Gift Card'
  },
  {
    id: StoreCreditTransactionOriginatorTypeEnum.REFUND,
    name: 'Refund'
  },
  {
    id: StoreCreditTransactionOriginatorTypeEnum.PAYMENT,
    name: 'Payment'
  }
];

export const storeCreditSelect = {
  id: true,
  amount: true,
  amount_used: true,
  amount_authorized: true,
  invalidated_at: true,
  created_by_id: true,
  currency: true,
  expires_at: true,
  created_at: true,
  type_id: true
};
