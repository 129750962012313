import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsToDuration' })
export class SecondsToDurationPipe implements PipeTransform {
  transform(seconds: number): string {
    if (!seconds) return '0';

    const hours: number = Math.floor(seconds / 60 / 60);
    const minutes: number = Math.floor(seconds / 60 - hours * 60);

    if (hours >= 1) {
      const hoursFormat: string = hours.toString().padStart(2, '0') + ' Hours';
      const minsFormat: string = minutes > 0 ? Math.round(minutes).toString().padStart(2, '0') + ' Minutes' : '';

      return `${hoursFormat} ${minsFormat}`;
    } else if (minutes >= 1) {
      const minsFormat: string = minutes.toString().padStart(2, '0') + ' Minutes';
      const secFormat: string = (seconds - minutes * 60).toString().padStart(2, '0') + ' Seconds';

      return `${minsFormat} ${secFormat}`;
    } else {
      return `${seconds.toString().padStart(2, '0')} Seconds`;
    }
  }
}
