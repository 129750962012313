import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from './../common.interface';

export interface IOptionType extends IBaseAudit {
  name_en: string;
  name_ar: string;
  presentation_en: string;
  presentation_ar: string;
  option_values?: IOptionValue[];
}

export interface IOptionValue extends IBaseAudit {
  name: string;
  presentation_en: string;
  presentation_ar: string;
  position: number;
  option_type_id?: number;
}

export const optionTypeSelect = {
  id: true,
  name_en: true,
  name_ar: true,
  presentation_en: true,
  presentation_ar: true
};

export const optionValueSelect = {
  ...BaseSelect,
  presentation_en: true,
  presentation_ar: true
};

export type IOptionTypeData = {
  page: number;
  name_en: string;
  name_ar: string;
};

export interface IOptionTypeListPayload extends IOptionTypeData {
  [index: string | number]: string | string[] | number | number[];
}

export interface IOptionTypeBaseListPayload {
  [index: string]: number | string;
}

export enum OptionTypeGridFilterEnum {
  PAGE = 'page',
  NAME_EN = 'name_en',
  NAME_AR = 'name_ar'
}
