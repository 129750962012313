import { IPaymentOption } from '../payment-option.interface';
import { IInventoryModifiers } from '../product/inventory-modifiers.interface';
import { BaseSelect } from './../common.interface';

export interface IAvailableSupermarket {
  id: number;
  name: string;
  description: string;
  latitude: number;
  longitude: number;
  currency: string;
  currency_precision: number;
  regular_delivery_fee: number;
  express_delivery_fee: number;
  minimum_total_amount_for_free_delivery: number;
  minimum_cart_total: number;
  time_slots_lead_time: number;
  payment_options?: IPaymentOption[];
}

export interface IPickupStore {
  id: number;
  name: string;
  description: string;
  address: string;
  latitude: number;
  longitude: number;
  opening_time: string;
  close_time: string;
  currency: string;
  currency_precision: number;
  minimum_cart_total: number;
  time_slots_lead_time: number;
  payment_options?: IPaymentOption[];
}

export interface GetSupermarketsInventoryModifierWhere {
  supermarket_ids: number[];
  product_id: number;
}

export interface IGetSupermarketsInventoryModifier {
  inventory_modifiers_where: GetSupermarketsInventoryModifierWhere[];
}

export interface GetSupermarketsInventoryModifierRes {
  inventory_modifiers: IInventoryModifiers[];
}

export const availableSupermarketSelect = {
  delivery_region: true,
  delivery_type: { name: true },
  supermarket_id: true,
  delivery_type_id: true,
  supermarket: {
    ...BaseSelect,
    description: true,
    latitude: true,
    longitude: true,
    minimum_cart_total: true,
    minimum_total_amount_for_free_delivery: true,
    express_delivery_fee: true,
    regular_delivery_fee: true,
    time_slots_lead_time: true,
    country_map: {
      currency: { name: true },
      currency_precision: true
    },
    super_markets_payments: {
      id: true,
      delivery_option_id: true,
      payment_option: {
        ...BaseSelect,
        code: true
      }
    }
  }
};

export const supermarketPickupStoresListSelect = {
  ...BaseSelect,
  description: true,
  latitude: true,
  longitude: true,
  address: true,
  opening_time: true,
  close_time: true,
  minimum_cart_total: true,
  time_slots_lead_time: true,
  country_map: {
    name: true,
    currency_precision: true,
    currency: { name: true }
  },
  city: { name: true },
  super_markets_payments: {
    delivery_option_id: true,
    payment_option: {
      ...BaseSelect,
      code: true
    }
  }
};

export const availableSupermarketByCountrySelect = {
  ...BaseSelect,
  address: true,
  opening_time: true,
  close_time: true,
  country_code: true,
  mobile_number: true,
  time_zone: true,
  latitude: true,
  longitude: true,
  country_map: { name: true },
  city: { name: true }
};
