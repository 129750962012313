import { Pipe, PipeTransform } from '@angular/core';

interface IEnumOption<T> {
  id: T;
  name: string;
}

@Pipe({ name: 'enumToName' })
export class EnumToNamePipe implements PipeTransform {
  transform<T>(value: T, optionsArray: IEnumOption<T>[]): string {
    const foundOption: IEnumOption<T> | undefined = optionsArray.find((option): boolean => option.id === value);
    return foundOption?.name ?? String(value);
  }
}
