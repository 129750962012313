export interface IFieldQueue {
  content_type: string;
  key: string;
  locale: string;
}

export interface IResponseLabel {
  key: string;
  value: string;
}
