import { IAuditable } from '../auditable.interface';
import { IProduct } from './product.interface';

export interface INutritionalFacts extends IAuditable {
  product_id: number;
  quantity?: string;
  quantity_ar?: string;
  calories?: string;
  total_fat?: string;
  saturated_fat?: string;
  trans_fat?: string;
  cholesterol?: string;
  total_carbohydrates?: string;
  sugar?: string;
  dietary_fiber?: string;
  protein?: string;
  salt?: string;
  sodium?: string;
  iron?: string;
  calcium?: string;
  vitamin_a?: string;
  vitamin_c?: string;
  vitamin_d?: string;
  ph?: string;
  product?: IProduct;
}

export interface INutritionalFactsCsv {
  sku: string;
  quantity_en: string;
  quantity_ar: string;
  calories: string;
  total_fat: string;
  saturated_fat: string;
  trans_fat: string;
  cholesterol: string;
  total_carbohydrates: string;
  sugar: string;
  dietary_fiber: string;
  protein: string;
  salt: string;
  sodium: string;
  iron: string;
  calcium: string;
  vitamin_a: string;
  vitamin_c: string;
  vitamin_d: string;
  ph: string;
}

export const nutritionalFactsCsvLabels = {
  sku: 'SKU',
  quantity_en: 'Quantity (EN)',
  quantity_ar: 'Quantity (AR)',
  calories: 'Calories',
  total_fat: 'Total Fat',
  saturated_fat: 'Saturated Fat',
  trans_fat: 'Trans Fat',
  cholesterol: 'Cholesterol',
  total_carbohydrates: 'Total Carbohydrates',
  sugar: 'Sugar',
  dietary_fiber: 'Dietary Fiber ',
  protein: 'Protein',
  salt: 'Salt',
  sodium: 'Sodium',
  iron: 'Iron',
  calcium: 'Calcium',
  vitamin_a: 'Vitamin A',
  vitamin_c: 'Vitamin C',
  vitamin_d: 'Vitamin D',
  ph: 'PH'
};
