export default {
  FIREBASE_PROVIDER: 'FIREBASE_PROVIDER',
  USER_RPC_PROVIDER: 'USER_RPC_PROVIDER',
  USER_PACKAGE: 'user',
  USER_RPC_SERVICE: 'UserRpcService',
  PRODUCT_RPC_PROVIDER: 'PRODUCT_RPC_PROVIDER',
  PRODUCT_PACKAGE: 'product',
  PRODUCT_RPC_SERVICE: 'ProductRpcService',
  AUTH_RPC_PROVIDER: 'AUTH_RPC_PROVIDER',
  AUTH_PACKAGE: 'auth',
  AUTH_RPC_SERVICE: 'AuthRpcService',
  ORDER_RPC_PROVIDER: 'ORDER_RPC_PROVIDER',
  ORDER_PACKAGE: 'order',
  ORDER_RPC_SERVICE: 'OrderRpcService',
  PAYMENT_GATEWAY_RPC_PROVIDER: 'PAYMENT_GATEWAY_RPC_PROVIDER',
  PAYMENT_GATEWAY_PACKAGE: 'paymentGateway',
  PAYMENT_GATEWAY_RPC_SERVICE: 'PaymentGatewayRpcService',
  PHILOSOPHIC_PROVIDER: 'PHILOSOPHIC_PROVIDER'
};
