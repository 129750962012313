export interface IAuditable {
  id?: number;
  is_deleted?: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export interface IBaseAudit {
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface IBaseAuditWithoutId {
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}
