import { ICategory } from './category.interface';
import { IBaseAudit } from '../auditable.interface';
import { ITaxon, taxonListSelect } from './taxon.interface';
import { BaseSelect } from './../common.interface';

export interface ITaxonomy extends IBaseAudit {
  name: string;
  category_id: number;
  position?: number;
  taxons?: ITaxon[];
  category?: ICategory;
}

export interface ITaxonomyDetail extends ITaxonomy {
  taxon?: ITaxon;
}

export interface ICategoryTaxonDepartment {
  id: number;
  name: string;
  pretty_name: string;
  image_url: string;
}

export interface ICategoryTaxon {
  category: ICategory;
  departments?: ICategoryTaxonDepartment[];
}

export const taxonomySelect = {
  ...BaseSelect,
  category_id: true,
  position: true,
  taxons: true,
  category: {
    ...BaseSelect
  }
};

export const taxonomyTaxonSelect = {
  ...taxonListSelect,
  taxon_groups: true,
  products: true,
  taxons: {
    ...taxonListSelect,
    taxon_groups: true,
    products: true,
    taxons: {
      ...taxonListSelect,
      taxon_groups: true,
      products: true,
      taxons: taxonListSelect
    }
  }
};

export const categoryTaxonSelect = {
  ...BaseSelect,
  category: {
    ...BaseSelect,
    name_ar: true
  },
  taxons: {
    ...BaseSelect,
    name_ar: true,
    pretty_name: true,
    pretty_name_ar: true,
    parent_id: true,
    taxons: {
      ...BaseSelect,
      name_ar: true,
      pretty_name: true,
      pretty_name_ar: true,
      icon_url: true
    }
  }
};
