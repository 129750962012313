import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from '../common.interface';
import { IMulterFile } from '../multer.interface';
import { IProduct } from './product.interface';
import { ITaxonGroupMembership } from './taxon-group.interface';
import { ITaxonomy } from './taxonomy.interface';

export interface ITaxon extends IBaseAudit {
  name: string;
  name_ar?: string;
  position: number;
  parent_id?: number;
  taxonomy_id: number;
  visible: boolean;
  featured?: boolean;
  permalink?: string;
  permalink_ar?: string;
  pretty_name?: string;
  pretty_name_ar?: string;
  icon_url?: string;
  home_background_image_url?: string;
  background_image_en_url?: string;
  background_image_ar_url?: string;
  product_listing_background_image_en_url?: string;
  product_listing_background_image_ar_url?: string;
  product_tag_image_en_url?: string;
  product_tag_image_ar_url?: string;
  start_date?: Date;
  end_date?: Date;
  start_time?: string;
  end_time?: string;
  product_text_tag_en?: string;
  product_text_tag_ar?: string;
  tag_to_show: string;
  visible_only_for_subscriber?: boolean;
  meta_title?: string;
  meta_title_ar?: string;
  meta_description?: string;
  meta_description_ar?: string;
  meta_keywords?: string;
  meta_keywords_ar?: string;
  parent_taxon?: ITaxon;
  products?: IProduct[];
  products_count?: number;
  taxonomy?: ITaxonomy;
  taxons?: ITaxon[];
  taxon_groups?: ITaxonGroupMembership[];
  taxon_groups_count?: number;
  taxon_countries?: ITaxonCountry[];
}

export interface ITaxonPathList {
  id: number;
  name: string;
  pretty_name: string;
  position?: number;
}

export interface ITaxonCountry {
  taxon_id: number;
  country_id: number;
}

export interface ITaxonFile {
  icon?: IMulterFile[];
  home_background_image?: IMulterFile[];
  background_image_en?: IMulterFile[];
  background_image_ar?: IMulterFile[];
  product_listing_background_image_en?: IMulterFile[];
  product_listing_background_image_ar?: IMulterFile[];
  product_tag_image_en?: IMulterFile[];
  product_tag_image_ar?: IMulterFile[];
}

export interface IFeaturedTaxon {
  id: number;
  name: string;
  pretty_name: string;
  image_url?: string;
  childern?: IFeaturedTaxon[];
}

export enum TagToShowEnum {
  DONT_SHOW = "don't show",
  IMAGE = 'image',
  TEXT = 'text'
}

export const tagToShowOptions = [
  {
    id: TagToShowEnum.DONT_SHOW,
    name: "Don't Show"
  },
  {
    id: TagToShowEnum.IMAGE,
    name: 'Image'
  },
  {
    id: TagToShowEnum.TEXT,
    name: 'Text'
  }
];

export const taxonSelect = {
  ...BaseSelect,
  permalink: true,
  start_date: true,
  end_date: true,
  start_time: true,
  end_time: true,
  product_text_tag_en: true,
  product_text_tag_ar: true,
  tag_to_show: true,
  visible: true,
  visible_only_for_subscriber: true,
  featured: true,
  meta_title: true,
  meta_description: true,
  meta_keywords: true,
  icon_url: true,
  home_background_image_url: true,
  background_image_en_url: true,
  background_image_ar_url: true,
  product_listing_background_image_en_url: true,
  product_listing_background_image_ar_url: true,
  product_tag_image_en_url: true,
  product_tag_image_ar_url: true,
  taxon_countries: {
    country_id: true
  }
};

export const taxonListSelect = {
  ...BaseSelect,
  name_ar: true,
  position: true,
  parent_id: true,
  taxonomy_id: true,
  visible: true,
  featured: true
};

export const taxonPathSelect = {
  ...BaseSelect,
  name_ar: true,
  pretty_name: true,
  pretty_name_ar: true
};

export const taxonTranslationSelect = {
  name: true,
  name_ar: true,
  permalink: true,
  permalink_ar: true,
  meta_title: true,
  meta_title_ar: true,
  meta_description: true,
  meta_description_ar: true,
  meta_keywords: true,
  meta_keywords_ar: true
};

export const taxonProductsListSelect = {
  ...BaseSelect,
  sku: true,
  images: {
    id: true,
    order: true,
    file_name: true
  },
  taxons: {
    ...BaseSelect,
    pretty_name: true
  },
  product_taxons: { taxon_id: true }
};

export const taxonChildListSelect = {
  id: true,
  taxons: {
    id: true,
    taxons: {
      id: true,
      taxons: { id: true }
    }
  }
};

export const taxonChildListRelation = {
  taxons: {
    taxons: {
      taxons: true
    }
  }
};

export const taxonParentListRelation = {
  parent_taxon: {
    parent_taxon: {
      parent_taxon: true
    }
  }
};

export const taxonListByGroupKeySelect = {
  ...BaseSelect,
  name_ar: true,
  pretty_name: true,
  pretty_name_ar: true,
  icon_url: true
};

const taxonPrettyNameSelect = {
  id: true,
  pretty_name: true,
  pretty_name_ar: true
};

export const taxonChildPrettySelect = {
  ...taxonPrettyNameSelect,
  taxons: {
    ...taxonPrettyNameSelect,
    taxons: {
      ...taxonPrettyNameSelect,
      taxons: {
        ...taxonPrettyNameSelect
      }
    }
  }
};

export const taxonParentPrettySelect = {
  ...taxonPrettyNameSelect,
  parent_taxon: {
    ...taxonPrettyNameSelect,
    parent_taxon: {
      ...taxonPrettyNameSelect,
      parent_taxon: {
        ...taxonPrettyNameSelect
      }
    }
  }
};

export const taxonBasicDetails = {
  ...BaseSelect,
  visible: true,
  icon_url: true
};

export const featuredTaxonsListSelect = {
  ...taxonBasicDetails,
  name_ar: true,
  pretty_name: true,
  pretty_name_ar: true,
  position: true,
  taxons: {
    ...taxonBasicDetails,
    name_ar: true,
    position: true
  }
};

export const taxonChildListTillTwoLevelSelect = {
  id: true,
  name: true,
  position: true,
  taxons: {
    id: true,
    name: true,
    name_ar: true,
    home_background_image_url: true,
    position: true
  }
};

export type TaxonAlgoliaPropertyType = 'id' | 'pretty_name' | 'pretty_name_ar';
