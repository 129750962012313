import { IKeyMapping } from './key-mapping.interface';
import { UserTypeEnum } from './user.interface';

export interface INotification {
  title: string;
  body: string;
  imageUrl?: string;
}

export interface IPushNotification {
  notification: INotification;
  data?: IKeyMapping<string>;
}

export interface IUserPushNotification {
  user_id: number;
  user_type: UserTypeEnum;
  notification: INotification;
  data?: IKeyMapping<string>;
}

export interface IGrpcSendPushNotificationReq {
  user_push_notification: IUserPushNotification;
}
