import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiService } from './index';

import { ILatestOrders, IResponse } from '@ecommerce/common-types';

@Injectable({ providedIn: 'root' })
export class LatestOrderService {
  private _lastOrderId?: number;

  public constructor(private readonly api: ApiService) {}

  get lastOrderId(): number | undefined {
    return this._lastOrderId;
  }

  set lastOrderId(value: number | undefined) {
    this._lastOrderId = value;
  }

  public getLatestOrders(): Observable<IResponse<ILatestOrders>> {
    const params: HttpParams = new HttpParams({ fromObject: { last_order_id: this.lastOrderId ?? '' } });
    return this.api.get<IResponse<ILatestOrders>>('orders/latest', params);
  }
}
