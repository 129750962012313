import { IBaseAudit } from '../auditable.interface';
import { BaseSelect } from './../common.interface';

export interface ICategory extends IBaseAudit {
  name: string;
  name_ar?: string;
  image_path?: string;
  is_new?: boolean;
  has_departments?: boolean;
  position?: number;
}

export const categorySelect = {
  ...BaseSelect
};

export const createCategoryListSelect = (additionalProps = {}) => {
  return {
    ...BaseSelect,
    image_path: true,
    is_new: true,
    has_departments: true,
    ...additionalProps
  };
};
