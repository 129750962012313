import { IKeyMapping } from './key-mapping.interface';

export interface ISpikeAdsResponse {
  status: string;
  lang: string;
  adItems: ISpikeAds[];
}

export interface ISpikeAds {
  ad_Id: string;
  placement_Id: string;
  placementGroup_Id: string;
  serving_priority: number;
  ad_metaData: IKeyMapping<string>[];
  width: number;
  height: number;
  responsive: boolean;
  ad_title: string;
  alt_text: string;
  target: string;
  tracking_pixel_url: string;
  body: string;
  redirect_url: string;
  destination_url: string;
  refresh_url: string;
  refresh_time_ms: number;
  image_url: string;
  lang: string;
  per_user_limit: number;
  daily_limit: number;
  viewable_url: string;
  eligible_url: string;
  aquisition_url: string;
  unique_delivery: boolean;
  adStartDate: string;
  adEndDate: string;
  adTargetAgeGroup: string;
  adTargetGender: string;
  adTargetGeoLocation: string;
  adTargetGeoRadius: number;
  campaignStartDate: string;
  campaignEndDate: string;
}

export interface ISpikeAdsRequest {
  placementID: string;
  platform?: string;
  extraData?: string;
  dk?: string;
  ageGroup?: AgeGroupEnum;
  gender?: string;
  longitude?: number;
  latitude?: number;
  groupPlacements?: boolean;
  count?: number;
}

export enum AgeGroupEnum {
  BETWEEN_18_20 = '1',
  BETWEEN_30_46 = '2',
  BETWEEN_46_60 = '3',
  BETWEEN_ABOVE_60 = '4'
}
