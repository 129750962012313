import { IBaseAudit } from './auditable.interface';
import { ICommonOption } from './common.interface';

export interface IPromotion extends IPromotionDetails {
  rules?: IPromotionRules[];
  actions?: IPromotionActions[];
}

export interface IPromotionDetails extends IBaseAudit {
  name_en: string;
  name_ar: string;
  description_en?: string;
  description_ar?: string;
  code_en?: string;
  code_ar?: string;
  no_of_code?: number;
  per_code_usage_limit?: number;
  usage_limit: number;
  starts_at?: Date;
  expires_at?: Date;
  apply_automatically: boolean;
  is_visible: boolean;
  match_policy: PromotionMatchPolicyEnum;
  status?: PromotionStatusEnum;
}

export interface IPromotionRules {
  promotion_id: number;
  type: PromotionRuleTypeEnum;
  rule: string;
  promotion?: IPromotion;
}

export interface IPromotionActions extends IBaseAudit {
  promotion_id: number;
  type: PromotionActionTypeEnum;
  action: string;
  promotion?: IPromotion;
}

export interface IPromotionActionDetail {
  base_calculator?: string;
  max_discount?: number;
  percentage?: number;
  expiry_by?: PromotionActionExpiryByEnum;
  expiry_days?: number;
  expiry_date?: string;
}

export interface IActivePromotions {
  promotions: IConsumerPromotion[];
  order_count: number;
}

export interface IConsumerPromotion extends IPromotion {
  name: string;
  overall_usage: number;
}

export interface IPromotionListData {
  page: number;
  name_en: string;
  name_ar: string;
  code_en: string;
  code_ar: string;
  starts_at: string;
  expires_at: string;
  status: string;
}

export interface IPromotionListPayload extends IPromotionListData {
  [index: string]: string | number;
}

export interface IGrpcGetPromotionsReq {
  promotion_ids: number[];
}

export interface IGrpcGetPromotionsRes {
  promotions: IPromotion[];
}

export interface IGrpcGetPromotionActionReq {
  promotion_action_id: number;
}

export interface IGrpcGetPromotionActionRes {
  promotion_action: IPromotionActions;
}

export enum PromotionRuleTypeEnum {
  FIRST_ORDER = 'first_order',
  N_USES_PER_USER = 'n_uses_per_user',
  EMAIL_DOMAIN_MATCHES = 'email_domain_matches',
  NTH_ORDER = 'nth_order',
  TAXONS = 'taxons',
  CART_TOTAL = 'cart_total',
  PAYMENT_METHODS = 'payment_methods',
  SUPERMARKETS = 'supermarkets',
  COUNTRIES = 'countries',
  CARD = 'card'
}

export enum PromotionRulesDisplayEnum {
  FIRST_ORDER = 'First Order',
  N_USES_PER_USER = 'N Uses Per User',
  EMAIL_DOMAIN_MATCHES = 'Email Domain Matches',
  NTH_ORDER = 'Nth Order',
  TAXONS = 'Taxon(s)',
  CART_TOTAL = 'Cart Total',
  PAYMENT_METHOD = 'Payment Method',
  CARD = 'Card',
  SUPERMARKETS = 'Supermarket(s)',
  COUNTRY = 'Country'
}

export enum PromotionMatchPolicyEnum {
  ALL = 'all',
  ANY = 'any'
}

export enum PromotionActionTypeEnum {
  WHOLE_ORDER_ADJUSTMENT = 'order_adjustment',
  FREE_SHIPPING = 'free_shipping',
  FREE_SAMPLE = 'free_sample',
  WHOLE_ORDER_CASHBACK = 'order_cashback'
}

export enum PromotionActionDisplayTypesEnum {
  WHOLE_ORDER_ADJUSTMENT = 'Create Whole-Order Adjustment',
  FREE_SHIPPING = 'Free Shipping',
  FREE_SAMPLE = 'Free Sample',
  WHOLE_ORDER_CASHBACK = 'Create Whole-Order Cashback'
}

export enum PromotionBaseCalculatorTypes {
  GROUP_TOTAL = 'Group Total'
}

export enum PromotionActionExpiryByEnum {
  DATE = 'Date',
  DAYS = 'Days'
}

export enum OrderTaxonRuleEnum {
  AT_LEAST_ONE = 'At least one',
  ALL = 'All',
  NONE = 'None'
}

export enum CartTotalCriteriaEnum {
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUAL_TO = 'Greater than or Equal to'
}

export interface IRuleFirstOrder {
  new_customer: boolean;
}

export interface IRulesNUsesPerOrder {
  n_times: number;
}

export interface IRulesEmailDomains {
  email_domains: string[];
}

export interface IRulesNthOrder {
  user_nth_order: number;
}

export interface IRulesTaxons {
  taxon_ids: number[];
  order_must_contain: OrderTaxonRuleEnum;
  total_item: number;
}

export interface IRulesCartTotal {
  criteria_meet: CartTotalCriteriaEnum;
  amount: number;
  currency_id: number;
}

export interface IRulesPaymentMethods {
  payment_method_ids: number[];
}

export interface IRulesSupermarkets {
  supermarket_ids: number[];
}

export interface IRulesCountries {
  country_ids: number[];
}

export interface IRulesCard {
  bins: string[];
}

export interface IActionOrderAdjustment {
  base_calculator: PromotionBaseCalculatorTypes;
  max_discount?: number;
  percentage?: number;
}

export interface IActionFreeShipping {
  free_shipping_charge: boolean;
}

export interface IActionFreeSample {
  quantity: number;
  product: string;
}

export interface IActionOrderCashback extends IActionOrderAdjustment {
  expiry_by?: PromotionActionExpiryByEnum;
  expiry_days?: number;
  expiry_date?: Date;
}

export interface IRulesPayload {
  [PromotionRuleTypeEnum.FIRST_ORDER]?: IRuleFirstOrder;
  [PromotionRuleTypeEnum.N_USES_PER_USER]?: IRulesNUsesPerOrder;
  [PromotionRuleTypeEnum.EMAIL_DOMAIN_MATCHES]?: IRulesEmailDomains;
  [PromotionRuleTypeEnum.NTH_ORDER]?: IRulesNthOrder;
  [PromotionRuleTypeEnum.TAXONS]?: IRulesTaxons;
  [PromotionRuleTypeEnum.CART_TOTAL]?: IRulesCartTotal;
  [PromotionRuleTypeEnum.PAYMENT_METHODS]?: IRulesPaymentMethods;
  [PromotionRuleTypeEnum.SUPERMARKETS]?: IRulesSupermarkets;
  [PromotionRuleTypeEnum.COUNTRIES]?: IRulesCountries;
  [PromotionRuleTypeEnum.CARD]?: IRulesCard;
}

export interface ICreateRulesPayload extends IRulesPayload {
  rule_type?: string;
  match_policy?: PromotionMatchPolicyEnum;
}

export interface IEditRulesPayload {
  match_policy: PromotionMatchPolicyEnum;
  promotion_rules?: IRulesPayload;
}

export interface IActionsPayload {
  [PromotionActionTypeEnum.WHOLE_ORDER_ADJUSTMENT]: IActionOrderAdjustment;
  [PromotionActionTypeEnum.FREE_SHIPPING]: IActionFreeShipping;
  [PromotionActionTypeEnum.FREE_SAMPLE]: IActionFreeSample;
  [PromotionActionTypeEnum.WHOLE_ORDER_CASHBACK]: IActionOrderCashback;
}

export interface IPromotionPayload extends IPromotionDetails {
  promotion_rules?: IRulesPayload;
  promotion_actions?: IActionsPayload;
}

export interface ITimestampFormatted {
  startDateFormatted: string;
  endDateFormatted: string;
}

export interface IPromotionValidationData {
  supermarkets: ICommonOption<number>[];
  countries: ICommonOption<number>[];
}

export enum PromotionStatusEnum {
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  EXPIRED = 'expired'
}

export enum PromotionGridFilterEnum {
  PAGE = 'page',
  NAME_EN = 'name_en',
  NAME_AR = 'name_ar',
  CODE_EN = 'code_en',
  CODE_AR = 'code_ar',
  STARTS_AT = 'starts_at',
  EXPIRES_AT = 'expires_at',
  STATUS = 'status'
}

export type PromotionRuleType =
  | IRuleFirstOrder
  | IRulesCountries
  | IRulesSupermarkets
  | IRulesPaymentMethods
  | IRulesEmailDomains
  | IRulesTaxons
  | IRulesCartTotal
  | IRulesNthOrder
  | IRulesNUsesPerOrder
  | IRulesCard;

export type PromotioActionType = IActionOrderAdjustment | IActionFreeShipping;

export const promotionSelect = {
  id: true,
  name_en: true,
  name_ar: true,
  code_en: true,
  code_ar: true,
  starts_at: true,
  expires_at: true
};

export const promotionDetailSelect = {
  id: true,
  name_en: true,
  name_ar: true,
  description_en: true,
  description_ar: true,
  code_en: true,
  code_ar: true,
  no_of_code: true,
  per_code_usage_limit: true,
  usage_limit: true,
  starts_at: true,
  expires_at: true,
  apply_automatically: true,
  is_visible: true,
  match_policy: true,
  rules: {
    type: true,
    rule: true
  }
};

export const promotionDetailActionSelect = {
  type: true,
  action: true
};

export const activePromotionsSelect = {
  id: true,
  name_en: true,
  name_ar: true,
  apply_automatically: true,
  rules: true,
  actions: true,
  match_policy: true
};

export const promotionActionSelect = {
  id: true,
  action: true,
  promotion: {
    id: true,
    name_en: true
  }
};
