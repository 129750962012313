import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';

export interface IBinCategory extends IBaseAudit {
  code: string;
  category: string;
}

export interface IBinCategoryListItem extends IBinCategory {
  id: number;
}

export const BinCategorySelect = {
  id: true,
  code: true,
  category: true
};

export const BinCategoryListSelect = {
  id: true,
  code: true,
  category: true
};

export type IBinCategoryListData = {
  page: number;
  code: string;
  category: string;
};

export interface IBinCategoryListPayload extends IBinCategoryListData {
  [index: string | number]: string | number;
}

export enum BinCategoryEnum {
  FROZEN = 'frozen',
  CASE = 'case',
  CHILLED = 'chilled',
  AMBIENT = 'ambient'
}

export enum BinCategoryGridFilterEnum {
  PAGE = 'page',
  CODE = 'code',
  CATEGORY = 'category'
}

export const binCategoryOptions: ICommonOption<BinCategoryEnum>[] = [
  {
    id: BinCategoryEnum.FROZEN,
    name: 'Frozen'
  },
  {
    id: BinCategoryEnum.CASE,
    name: 'Case'
  },
  {
    id: BinCategoryEnum.CHILLED,
    name: 'Chilled'
  },
  {
    id: BinCategoryEnum.AMBIENT,
    name: 'Ambient'
  }
];
