import { IBaseAudit } from '../auditable.interface';
import { ICountry } from '../location/country.interface';
import { BaseSelect } from './../common.interface';

export enum TaxTypeEnum {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed'
}

export interface ITaxation extends IBaseAudit {
  name: string;
  code: string;
  country_id: number;
  country?: ICountry;
  type: TaxTypeEnum;
  value: number;
  global: boolean;
}

export interface ITaxListItem extends ITaxation {
  id: number;
}

export interface IGrpcGetTaxationByCountryIdReq {
  country_id: number;
}

export interface IGrpcGetTaxationByCountryIdRes {
  taxations: ITaxation[];
}

export type ITaxationListData = {
  page: number;
  name: string;
  country_id: number | [];
  code: string;
  type: string[];
  value: number;
  global: boolean;
};

export const taxationListSelect = {
  ...BaseSelect,
  code: true,
  country_id: true,
  type: true,
  value: true,
  global: true
};

export const customerTaxationListSelect = {
  ...BaseSelect,
  code: true,
  type: true,
  value: true,
  global: true
};

export interface ITaxationListPayload extends ITaxationListData {
  [index: string | number]: string | string[] | number | boolean | number[];
}

export enum TaxationGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  COUNTRY_ID = 'country_id',
  CODE = 'code',
  TYPE = 'type',
  VALUE = 'value'
}
