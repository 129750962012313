export default {
  CONTENTFUL_REDIS_EXPIRE: 3600,
  CONTENTFUL_LOGIN_URL: 'https://be.contentful.com/login',
  CONTENT_MODEL: {
    API_LABEL: 'apiTranslationLabel',
    EMAIL_CONTENT_LABEL: 'emailTranslationLabel',
    COUNTRY_TRANSLATION: 'country',
    CITY_TRANSLATION: 'city',
    DISTRICT_TRANSLATION: 'district',
    SUPERMARKET_TRANSLATION: 'supermarket',
    WAREHOUSE_TRANSLATION: 'warehouse',
    FAQ: 'faq',
    FAQ_CATEGORY: 'faqCategory'
  }
};
