import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'simplifyText' })
export class SimplifyTextPipe implements PipeTransform {
  transform(value: string, splitter: string = '-'): string {
    if (!value) return '';
    else {
      return value
        .split(splitter)
        .map((subString: string): string => this.captaliseWord(subString))
        .join(' ');
    }
  }

  private captaliseWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}
