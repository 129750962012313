import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { ApiService } from '../index';

import {
  ILogin,
  IResponse,
  IUpdateUser,
  IUserForgotPassword,
  IUserLogin,
  IUserChangePassword
} from '@ecommerce/common-types';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public constructor(private api: ApiService) {}

  public adminLogin(payload: IUserLogin): Observable<IResponse<ILogin>> {
    return this.api.post<IResponse<ILogin>, IUserLogin>('auth/login', payload);
  }

  public forgotPassword(payload: IUserForgotPassword): Observable<IResponse<IUpdateUser>> {
    return this.api.post<IResponse<IUpdateUser>, IUserForgotPassword>('auth/forgot-password', payload);
  }

  public changepassword(payload: IUserChangePassword): Observable<IResponse<IUpdateUser>> {
    return this.api.post<IResponse<IUpdateUser>, IUserChangePassword>('auth/change-password', payload);
  }

  public validateToken(): Observable<IResponse<string>> {
    return this.api.get<IResponse<string>>('auth/validate-token');
  }

  public refreshToken(): Observable<IResponse<string>> {
    return this.api.get<IResponse<string>>('auth/refresh-token');
  }
}
