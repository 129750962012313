import { Component, Input } from '@angular/core';

@Component({
  selector: 'ecomm-input-info-tooltip',
  templateUrl: './input-info-tooltip.component.html'
})
export class InputInfoTooltipComponent {
  @Input() public tooltipText = '';
  @Input() public iconStyleClass = '';
}
