import JSZip from 'jszip';

export interface IZipUploadEvent {
  progress: number;
  error?: string;
}

export enum IBulkUploadValidationTypes {
  VALID = 'valid',
  EMPTY = 'empty',
  INVALID = 'invalid',
  EXISTING = 'existing',
  EXISTING_SKU = 'existing_sku'
}

export interface IUploadValidate<T> extends IZipUploadEvent {
  type: IBulkUploadValidationTypes;
  reasons: string[];
  csv_row: number;
  is_duplicate?: boolean;
  is_circular_sku?: boolean;
  data: T;
  job_entity_id?: number;
}

export interface IExtraLabel {
  decimal_places?: string;
  contains?: string;
  date_time_format?: string;
  is_time?: boolean;
  start_date?: string;
  date?: string;
  length?: number;
  range_start?: number;
  range_end?: number;
  supermarket_id?: number;
  mapped_to?: string;
}

export interface IBulkUploadListFromUrl<T> {
  zip: JSZip;
  list: T[];
}

export enum ReasonType {
  EMPTY = 'empty',
  NOT_EXIST = 'not_exist',
  DATE_FORMAT = 'date',
  NOSPACE = 'no_space',
  DATE_GREATER_THAN = 'date_greater_than',
  DATE_LESS_THAN = 'date_less_than',
  ALPHA_ONLY = 'alpha',
  NUMERIC_ONLY = 'numeric_only',
  RANGE = 'range',
  ALPHANUMERIC_ONLY = 'alphanumeric',
  ALPHANUMERIC_WITH_SPECIAL_CHAR_SPACE = 'alphanumeric_with_special_char_space',
  ARABIC_ENGLISH_ALPHANUMERIC_WITH_SPECIAL_CHAR_SPACE = 'arabic_english_alphanumeric_with_special_char_space',
  ARABIC_ALPHANUMERIC_WITH_SPACE = 'arabic_alphanumeric_with_space',
  ARABIC_ALPHANUMERIC_WITH_SPECIAL_CHAR_SPACE = 'arabic_alphanumeric_with_special_char_space',
  ALPHANUMERIC_WITH_SPACE = 'alphanumeric_with_space',
  DECIMAL_PLACES = 'decimal_places',
  CONTAINS = 'contains',
  FILE_NOT_FOUND = 'file_not_found',
  DUPLICATE_LABEL = 'duplicate_label',
  ALREADY_EXIST_LABEL = 'already_exist',
  CHARACTERS_LENGTH = 'characters_length',
  MAX_COUNT = 'max_count',
  BUNDLE_OF_BUNDLE = 'bundle_of_bundle',
  ALREADY_MAPPED = 'already_mapped'
}

export enum JobType {
  BULK_PRODUCT_UPLOAD = 'Bulk Product Upload',
  BULK_PRODUCT_UPDATE = 'Bulk Product Update',
  NUTRITIONAL_FACTS_UPLOAD = 'Nutritional Facts',
  METADATA_UPLOAD = 'Metadata',
  INVENTORY_MODIFIERS_UPLOAD = 'Inventory Modifiers',
  BUNDLE_PRODUCTS_UPLOAD = 'Bundle products',
  PRODUCT_TAXONS_UPLOAD = 'Product Taxons',
  OFFLINE_PRODUCTS_UPLOAD = 'Offline Products',
  PRODUCT_LOCATION_AND_STOCK_UPLOAD = 'Product Location And Stock'
}

export const bulkUploadDateFormat = 'YYYY/MM/DD';
export const bulkUploadTimeFormat = 'HH:mm';
export const inventoryModifierDateFormat = 'YYYY-MM-DD';
export const inventoryModifierTimeFormat = 'HH:mm:ss';
