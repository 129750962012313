import { IBaseAudit } from '../auditable.interface';
import { IOrderShipment } from './order-shipment.interface';
import { IWmsBin } from '../warehouse-management/wms-bin.interface';

export interface IShipmentReturnedItem extends IBaseAudit {
  order_shipment_id: number;
  bin_id?: number;
  product_id: number;
  returned_quantity: number;
  image_url: string;
  reason?: string;
  return_location_id?: number;
  driver_id: number;
  dropped_at?: Date;
  processed_by?: number;
  processed_at?: Date;
  processing_comments?: string;
  processing_image_url?: string;
  verified_by?: number;
  verified_at?: Date;
  verification_comments?: string;
  verification_image_url?: string;
  damaged_quantity?: number;
  fit_quantity?: number;
  order_shipment?: IOrderShipment;
  bin?: IWmsBin;
}

export interface IDispatchJobShipmentReturnedItem {
  product_id: number;
  bin_id?: number;
  returned_quantity: number;
  image_url: string;
}
