import { IBaseAudit } from './auditable.interface';

export interface IHoldReason extends IBaseAudit {
  reason: string;
  reason_ar: string;
}

export const holdReasonBaseListSelect = {
  id: true,
  reason: true
};
