export default {
  SUPERMARKET_TYPE: `A supermarket type defines the possible operations & actions against the supermarket.
    Store: A normal hypermarket, where inventory status validation is not applied for orders.
    Dark Store: A dedicated store for online customers with fully integrated warehouse management & stock tracking.
    Hub: A hub is a bigger warehouse used to stock replenishment for Dark Stores.
    Auto Store: A store where third-party system is applied to perform picking and stock storage.`,
  SUPERMARKET_VISIBLE: `This field controls the visibility of the store on the customer application. If a store is set to invisible, the customer won't be able to make orders against it`,
  SUPERMARKET_DELIVERY_OPTIONS: 'Delivery options supported by this store',
  SUPERMARKET_DELIVERY_TYPES: `There are two types of home delivery:
    Express: Order is processed and delivered as soon as it is received.
    Regular/Schedule: The user will be able to select pre-defined time slots and can select future days.`,
  SUPERMARKET_MINIMUM_CART_TOTAL: 'Minimum cart total for users to have before they will be allowed to checkout',
  SUPERMARKET_MINIMUM_TOTAL_AMOUNT_FOR_FREE_DELIVERY:
    'This is the minimum amount to reach for free delivery. Once the cart total reaches this amount, the delivery will be free',
  SUPERMARKET_TIMESLOTS_LEAD_TIME:
    'Lead time in hours for booking a schedule/regular delivery option. This allows the admins to set a buffer time for processing the delivery batch',
  SUPERMARKET_REGULAR_DELIVERY_FEE:
    'Regular/Schedule delivery fee, this will be overwritten by promotions if applicable',
  SUPERMARKET_EXPRESS_DELIVERY_FEE: 'Express delivery fee, this will be overwritten by promotions if applicable',
  SUPERMARKET_OPENING_TIME: 'Store opening time for pickup',
  SUPERMARKET_CLOSING_TIME: 'Store closing time for pickup',
  SUPERMARKET_AVG_PER_WEIGHTED_PICK: 'Average seconds required to pick weighted items',
  SUPERMARKET_AVG_PER_NON_WEIGHTED_PICK:
    'Average seconds required to pick non-weighted items. This is used to calculate the estimated time of completion (ETC) for picking',
  SUPERMARKET_BUFFER_TIME:
    'Time required (in seconds) to start & finish the picking job. This is included in calculating the estimated time of completion (ETC)',
  SUPERMARKET_MAX_PICKS_PER_PICKER: 'The maximum number of picks a picker can be assigned at a time',
  SUPERMARKET_LABEL_PRINTER_ID: 'Printer ID from PrintNode'
};
