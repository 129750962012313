import { IBaseAudit } from '../auditable.interface';
import { IKeyMapping } from '../key-mapping.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { ICreateTranslation, ITranslationData } from '../translation/translation.interface';
import { ICountry } from './country.interface';
import { BaseSelect } from './../common.interface';

export interface ICity extends IBaseAudit {
  name: string;
  abbreviation: string;
  country_id: number;
  translation_id?: string;
  latitude?: number;
  longitude?: number;
  country?: ICountry;
  super_markets?: ISuperMarket[];
  // districts?: IDistrict[];
  // district_list?: IDistrict[];
  city_image_url?: string;
  is_visible?: boolean;
}

export interface ICityListItem extends ICity {
  id: number;
  // is_deletable: boolean;
}

export const CityListSelect = {
  id: true,
  name: true,
  country_id: true,
  abbreviation: true,
  city_image_url: true,
  is_visible: true,
  latitude: true,
  longitude: true,
  country: {
    name: true
  }
};

export type ICityListData = {
  page: number;
  name: string;
  abbreviation: string;
  country: string;
  is_visible: string[];
};

export interface ICityListPayload extends ICityListData {
  [index: string | number]: string | string[] | number | number[];
}

export interface ICityBaseListData {
  country_ids: string;
}

export interface ICityBaseListPayload extends ICityBaseListData {
  [index: string]: string;
}

export const citySelect = {
  ...BaseSelect,
  country: {
    ...BaseSelect
  }
};

export interface ICityTranslation extends ITranslationData {
  name?: string;
}

export interface ICreateCityTranslation extends ICreateTranslation {
  name?: IKeyMapping<string>;
}

export enum CityGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  COUNTRY = 'country',
  IS_VISIBLE = 'is_visible',
  ABBREVIATION = 'abbreviation'
}

export const cityBaseListSelect = {
  ...BaseSelect,
  latitude: true,
  longitude: true
};
