import { IBaseAudit } from './auditable.interface';
import { BaseSelect } from './common.interface';

export enum DELIVERY_TYPE { // Delivery  Option type
  STORE_PICKUP = 'store_pickup',
  HOME_DELIVERY = 'home_delivery',
  OTHER = 'other'
}

export interface IDeliveryType extends IBaseAudit {
  name: string;
}

export interface IDeliveryRegion extends IBaseAudit {
  delivery_area: string;
  delivery_type_id: number;
  supermarket_id: number;
}

export interface IGrpcDeliveryType {
  deliveryTypes: IDeliveryType[];
}

export enum DeliveryTypeEnum {
  REGULAR = 'Regular',
  EXPRESS = 'Express'
}

export enum DeliveryTypeIdEnum {
  REGULAR = 1,
  EXPRESS = 2
}

export const deliveryTypeSelect = {
  ...BaseSelect
};
