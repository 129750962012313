import { IPaymentOption } from '../payment-option.interface';

export interface ISuperMarketPayment {
  id?: number;
  name?: string;
  super_market_id?: number;
  payment_id?: number;
  delivery_option_id?: number;
  payment_option?: IPaymentOption;
}
