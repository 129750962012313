import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { RootService } from '../../../common/service/root/root.service';
import { BulkUploadService } from '../../../common/service/product/bulk-upload.service';
import { ServerSendEvent } from '../../../common/service/product/server-event.service';

import {
  constants,
  IEventStreamError,
  IFileValidationResponse,
  IResponse,
  zipFileValidator
} from '@ecommerce/common-types';

@Component({
  selector: 'ecomm-zip-file-uploader',
  templateUrl: './zip-file-uploader.component.html'
})
export class ZipFileUploaderComponent implements OnInit, OnDestroy {
  @Input() public acceptFileType = '.zip';
  @Input() public zipFileTypes: string[] = [];
  @Input() public event!: string;
  @Output() public progressPercentage: EventEmitter<number> = new EventEmitter();
  @Output() public fileUploaded: EventEmitter<string> = new EventEmitter();
  public listenServerEvent?: Subscription;
  public uploadProgressValue = 0;
  public uploadedFileName = '';
  public fileUploadErrMsg = '';
  public fileTypeLabel = '';
  public fileSelected = false;

  constructor(
    private rootService: RootService,
    private serverSendEvent: ServerSendEvent,
    private bulkUploadService: BulkUploadService
  ) {}

  public ngOnInit(): void {
    this.fileTypeLabel = this.acceptFileType === '.csv' ? 'CSV' : 'ZIP';
  }

  public ngOnDestroy(): void {
    this.closeServerEvent();
  }

  public async onFileSelect(files: File[]): Promise<void> {
    const file: File = files[0];
    const fileType = `.${file?.name.split('.').pop()}`;
    this.fileUploadErrMsg = '';
    this.fileSelected = true;
    let isFileValid = true;

    if (this.acceptFileType !== fileType) {
      isFileValid = false;
      this.fileUploadErrMsg = this.fileTypeLabel === 'ZIP' ? constants.INVALID_ZIP_FILE : constants.INVALID_CSV_FILE;
    } else if (this.fileTypeLabel === 'ZIP') {
      const zipFileValidation: IFileValidationResponse = await zipFileValidator(file, this.zipFileTypes);
      this.fileUploadErrMsg = zipFileValidation.error;
      isFileValid = zipFileValidation.valid;
    }

    if (isFileValid) {
      this.subscribeUploadEvent();
      this.uploadedFileName = file?.name ?? 'NA';
      this.uploadFile(file);
    } else {
      this.closeServerEvent();
    }
  }

  public removeUploadedFile(): void {
    this.progressPercentage.emit(0);
    this.uploadProgressValue = 0;
    this.fileSelected = false;
    this.closeServerEvent();
  }

  private subscribeUploadEvent(): void {
    this.listenServerEvent = this.bulkUploadService.listenServerEvents(this.event).subscribe({
      next: (response: string): void => {
        this.uploadProgressValue = Number(response);
        this.progressPercentage.emit(this.uploadProgressValue);

        if (this.uploadProgressValue === 100) this.closeServerEvent();
      },
      error: (error: IEventStreamError): void => {
        this.removeUploadedFile();
        this.sendError({ message: error.errorMessage });
      }
    });
  }

  private uploadFile(file: File): void {
    this.bulkUploadService.uploadFile(file, this.event).subscribe({
      next: (response: IResponse<string>): void => {
        if (response.code === 201) this.fileUploaded.emit(response.data);
        else this.closeServerEvent();
      },
      error: (error): void => {
        this.removeUploadedFile();
        this.sendError(error);
      }
    });
  }

  private sendError(error: { message: string }): void {
    const message: string = error?.message ?? constants.DEFAULT_ERROR_MESSAGE;
    this.rootService.showToast(message, true);
  }

  private closeServerEvent(): void {
    this.listenServerEvent?.unsubscribe();
    this.serverSendEvent.closeServerEvents();
  }
}
