import { IBaseAudit } from '../auditable.interface';
import { IKeyMapping } from '../key-mapping.interface';
import { ICity } from '../location/city.interface';
import { ICountry } from '../location/country.interface';
import { ISuperMarketPayment } from './super-market-payment.interface';
import { ICreateTranslation, ITranslationData } from '../translation/translation.interface';
import { deliveryTypeSelect, IDeliveryType } from '../delivery-type.interface';
import { deliveryOptionSelect, IDeliveryOption } from '../delivery-option.interface';
import { IEmployeeSupermarket } from '../employee.interface';
import { IDeliveryCapacity } from './time-slot.interface';
import { ISuperMarketDeliveryRegion } from './supermarket-delivery-region.interface';
import { BaseSelect } from './../common.interface';
import { IHoliday } from '../holiday.interface';

export interface ISuperMarket extends IBaseAudit {
  name: string;
  country_id: number;
  city_id: number;
  time_slots_lead_time: number;
  latitude: number;
  longitude: number;
  regular_delivery_fee: number;
  express_delivery_fee: number;
  minimum_cart_total?: number;
  description?: string;
  address?: string;
  opening_time?: string;
  close_time?: string;
  country_code?: string;
  mobile_number?: string;
  type?: SupermarketTypeEnum;
  minimum_total_amount_for_free_delivery?: number;
  translation_id?: string;
  call_mask?: boolean;
  is_visible?: boolean;
  is_checked?: boolean;
  cityId?: number;
  avg_time_per_weighted_pick?: number;
  avg_time_per_non_weighted_pick?: number;
  buffer_time?: number;
  max_picks_per_picker?: number;
  allow_to_call_customer?: boolean;
  time_zone?: string;
  country?: ICountry;
  country_map?: ICountry;
  city?: ICity;
  label_printer_id?: number;
  delivery_options?: IDeliveryOption[];
  delivery_types?: IDeliveryType[];
  store_pickup_payment_options?: ISuperMarketPayment[];
  home_delivery_payment_options?: ISuperMarketPayment[];
  super_markets_payments?: ISuperMarketPayment[];
  employees?: IEmployeeSupermarket[];
  delivery_capacities?: IDeliveryCapacity[];
  delivery_regions?: ISuperMarketDeliveryRegion[];
  holidays?: IHoliday[];
  //district_id?: number;
  //districts: IDistrict[];
  //districts_grid?: IDistrict[];
}

export interface ISuperMarketListItem extends ISuperMarket {
  id: number;
}

export interface ISuperMarketListPayload extends ISuperMarketListData {
  [index: string | number]: string | string[] | number | number[];
}

export interface ISuperMarketBaseListPayload {
  [index: string]: number | string | number[] | string[] | boolean;
}

export interface ISuperMarketTranslation extends ITranslationData {
  name?: string;
}

export interface ICreateSuperMarketTranslation extends ICreateTranslation {
  name?: IKeyMapping<string>;
}

export interface ISuperMarketFilterList extends ISuperMarket {
  checked: boolean;
}

export interface IEditDeliveryFeePayload {
  supermarket_ids: number[];
  regular_delivery_fee?: number;
  express_delivery_fee?: number;
}

export interface IGrpcEmployeeSupermarket {
  employeeId: number;
  supermarketIds?: number[];
}

export interface IGrpcSupermarket {
  supermarkets: ISuperMarket[];
}

export interface IGrpcGetSupermarketByIdReq {
  supermarket_ids: number[];
}

export interface IGrpcGetSupermarketByIdRes {
  supermarkets: ISuperMarket[];
}

export interface ISupermarketSchedule {
  supermarket_id: number;
  date: string;
  is_holiday: boolean;
  holiday_name?: string;
  time_slots: ISupermarketScheduleTimeSlot[];
}

export interface ISupermarketScheduleTimeSlot {
  id: number;
  start_time: string;
  end_time: string;
  total_capacity: number;
  remaining_capacity: number;
}

export type ISuperMarketListData = {
  page: number;
  name: string;
  description: string;
  country: string;
  city: string;
  district: string;
  is_visible: string[];
};

export interface IGrpcGetSupermarketName {
  supermarkets: ISuperMarket[];
}

export enum FilterField {
  COUNTRY_ID = 'country_id',
  CITY_ID = 'city_id'
}

export enum SupermarketTypeEnum {
  STORE = 'store',
  DARK_STORE = 'dark_store',
  HUB = 'hub',
  AUTO_STORE = 'auto_store'
}

export enum SuperMarketGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  COUNTRY = 'country',
  CITY = 'city',
  IS_VISIBLE = 'is_visible',
  DESCRIPTION = 'description'
}

export const supermarketTypeOptions = [
  {
    id: SupermarketTypeEnum.STORE,
    name: 'Store'
  },
  {
    id: SupermarketTypeEnum.DARK_STORE,
    name: 'Dark Store'
  },
  {
    id: SupermarketTypeEnum.HUB,
    name: 'Hub'
  },
  {
    id: SupermarketTypeEnum.AUTO_STORE,
    name: 'Auto Store'
  }
];

export const SuperMarketListSelect = {
  ...BaseSelect,
  description: true,
  country_id: true,
  country_map: {
    name: true,
    currency_precision: true,
    currency: {
      name: true
    }
  },
  city_id: true,
  city: {
    name: true
  },
  // districts: {
  //   id: true,
  //   name: true
  // },
  country_code: true,
  mobile_number: true,
  super_markets_payments: {
    payment_id: true,
    delivery_option_id: true,
    payment_option: {
      name: true
    }
  },
  address: true,
  latitude: true,
  longitude: true,
  regular_delivery_fee: true,
  express_delivery_fee: true,
  time_slots_lead_time: true,
  type: true,
  minimum_total_amount_for_free_delivery: true,
  avg_time_per_weighted_pick: true,
  avg_time_per_non_weighted_pick: true,
  buffer_time: true,
  max_picks_per_picker: true,
  allow_to_call_customer: true,
  call_mask: true,
  is_visible: true,
  minimum_cart_total: true,
  opening_time: true,
  close_time: true,
  label_printer_id: true,
  delivery_options: deliveryOptionSelect,
  delivery_types: deliveryTypeSelect
};

export const supermarketBaseListSelect = {
  ...BaseSelect
};

export const addOrderSupermarketSelect = {
  id: true,
  name: true,
  type: true,
  city_id: true,
  country_map: {
    id: true,
    time_zone: true,
    currency_precision: true,
    currency: { name: true }
  },
  minimum_cart_total: true,
  minimum_total_amount_for_free_delivery: true,
  express_delivery_fee: true,
  regular_delivery_fee: true,
  delivery_options: { id: true },
  delivery_types: {
    ...BaseSelect
  },
  super_markets_payments: {
    payment_id: true,
    delivery_option_id: true,
    payment_option: {
      name: true,
      code: true
    }
  },
  delivery_capacities: {
    id: true,
    capacity: true,
    is_enabled: true,
    time_slot_id: true,
    time_slot: {
      is_enabled: true,
      delivery_option_id: true,
      start_time: true,
      end_time: true,
      day_of_week: true
    }
  },
  delivery_regions: {
    supermarket_id: true,
    delivery_type_id: true,
    delivery_region: true
  }
};

export const customerTimeSlotListSelect = {
  id: true,
  supermarket_id: true,
  is_enabled: true,
  capacity: true,
  supermarket: {
    id: true,
    country_map: { time_zone: true }
  },
  time_slot: {
    id: true,
    start_time: true,
    end_time: true,
    is_enabled: true,
    delivery_option_id: true,
    day_of_week: true
  }
};
