import { IBaseAudit } from '../auditable.interface';

export interface IBrand extends IBaseAudit {
  code?: string;
  name_en: string;
  name_ar?: string;
}

export interface IBrandTranslation {
  name_ar?: string;
}

export interface IBrandListItem extends IBrand {
  id: number;
}

export type IBrandListData = {
  page: number;
  code: string;
  name_en: string;
  name_ar: string;
};

export interface IBrandListPayload extends IBrandListData {
  [index: string | number]: string | string[] | number | number[];
}

export const brandListSelect = {
  id: true,
  code: true,
  name_en: true,
  name_ar: true
};

export enum BrandGridFilterEnum {
  PAGE = 'page',
  CODE = 'code',
  NAME_EN = 'name_en',
  NAME_AR = 'name_ar'
}
