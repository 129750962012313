export interface IKeyMapping<T = string> {
  [key: string]: T;
}

export interface IIdMapping<T = string> {
  [key: number]: T;
}

export interface IKeyMappingList<T = string> {
  [key: string]: [T];
}
