import { IBaseAudit } from '../auditable.interface';
import { IOrder } from './order.interface';

export interface IOrderAdjustment extends IBaseAudit {
  order_id: number;
  source_type: OrderAdjustmentSourceEnum;
  source_id: number;
  adjustable_type: OrderAdjustmentTypeEnum;
  adjustable_id: number;
  adjustable_reason_id: number;
  amount: number;
  label: string;
  promotion_code_id: number;
  eligible: boolean;
  included: boolean;
  finalized: boolean;
  free_sample: boolean;
  order_adjustment_reason?: IOrderAdjustmentReason;
  order?: IOrder;
  label_ar: string;
}

export interface IOrderAdjustmentReason extends IBaseAudit {
  name: string;
  code: string;
  active: boolean;
}

export enum OrderAdjustmentSourceEnum {
  PAYMENT = 'Payment',
  PROMOTION_ACTION = 'PromotionAction',
  SUBCRIPTION_PLAN_ACCOUNT_PERIOD = 'SubscriptionPlanAccountPeriod',
  TAX_RATE = 'TaxRate',
  UNIT_CANCEL = 'UnitCancel'
}

export enum OrderAdjustmentTypeEnum {
  LINE_ITEM = 'LineItem',
  ORDER = 'Order',
  SHIPMENT = 'Shipment'
}
