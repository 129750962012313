import { IAuditable } from '../auditable.interface';
import { IDeliveryOption } from '../delivery-option.interface';
import { IDeliveryType } from '../delivery-type.interface';
import { ICountry } from '../location/country.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { BinCategorySelect, IBinCategory } from './bin-category.interface';
import { IBrand } from './brand.interface';
import { ICategory } from './category.interface';
import { IInventoryModifiers } from './inventory-modifiers.interface';
import { IManufacturer } from './manufacturer.interface';
import { INutritionalFacts } from './nutritional-facts.interface';
import { IProductBarcode } from './product-barcode.interface';
import { IProductDeliveryOption } from './product-delivery-option.interface';
import { IProductDeliveryType } from './product-delivery-type.interface';
import { IProductImage } from './product-image.interface';
import { IProductVariant, IProductVariantImage } from './product-variant.interface';
import { ISupplier } from './supplier.interface';
import { ITaxation } from './taxation.interface';
import { ITaxon } from './taxon.interface';
import { ICommonName, BaseSelect } from '../common.interface';
import { IKeyMapping } from '../key-mapping.interface';

export interface IProduct extends IAuditable {
  name: string;
  sku: string;
  slug?: string;
  price?: number;
  short_description: string;
  long_description: string;
  discount?: string;
  is_wishlisted?: boolean;
  name_ar: string;
  short_description_ar?: string;
  long_description_ar?: string;
  default_taxon_id?: number;
  available_on: Date;
  electronic_product_code?: number;
  height?: number;
  width?: number;
  max_weight_per_order?: number;
  moq_limit?: number;
  default_weight_count?: number;
  depth?: number;
  weight?: number;
  weight_increment?: number;
  size?: string;
  issue_loyalty_points: boolean;
  cod_enabled: boolean;
  promotable: boolean;
  refundable: boolean;
  deactivate: boolean;
  bundled?: boolean;
  perform_liquid_weight_check?: boolean;
  crushable?: boolean;
  is_gluten_free?: boolean;
  subscription_only?: boolean;
  country_id: number;
  category_id: number;
  bin_category_id?: number;
  tax_category_id?: number;
  supplier_id?: number;
  manufacturer_id?: number;
  brand_id?: number;
  meta_title?: string;
  meta_keywords?: string;
  meta_keywords_ar?: string;
  meta_description?: string;
  meta_description_ar?: string;
  validity_tag?: string;
  validity_tag_ar?: string;
  loyalty_point?: number;
  loyalty_synced_at?: Date;
  loyalty_reward_cost?: number;
  loyalty_sync_key?: string;
  images?: IProductImage[];
  brand?: IBrand;
  delivery_type?: IProductDeliveryType[];
  delivery_option?: IProductDeliveryOption[];
  bin_category?: IBinCategory;
  taxation?: ITaxation;
  supplier?: ISupplier;
  manufacturer?: IManufacturer;
  nutritional_facts?: INutritionalFacts;
  inventory_modifiers?: IInventoryModifiers[];
  country?: ICountry;
  category?: ICategory;
  barcodes?: IProductBarcode[];
  child_products?: IBundleProducts[];
  taxons?: ITaxon[];
  product_taxons?: IProductTaxon[];
  delivery_types?: IDeliveryType[];
  delivery_options?: IDeliveryOption[];
  variants?: IProductVariant[];
  is_deleted?: boolean;
  is_taxable?: boolean;
}

export interface IProductDetail {
  id: number;
  name: string;
  sku: string;
  short_description: string;
  long_description: string;
  bundled: boolean;
  refundable: boolean;
  is_gluten_free: boolean;
  deactivate: boolean;
  cod_enabled: boolean;
  is_taxable?: boolean;
  weight: number;
  weight_increment?: number;
  default_weight_count: number;
  max_weight_per_order?: number;
  perform_liquid_weight_check?: boolean;
  moq_limit: number;
  category: ICommonName;
  delivery_types: ICommonName[];
  delivery_option: ICommonName[];
  brand: ICommonName;
  nutritional_facts: {
    id?: number;
    quantity?: string;
    quantity_ar?: string;
    calories?: string;
    total_fat?: string;
    saturated_fat?: string;
    trans_fat?: string;
    cholesterol?: string;
    total_carbohydrates?: string;
    sugar?: string;
    dietary_fiber?: string;
    protein?: string;
    salt?: string;
    sodium?: string;
    iron?: string;
    calcium?: string;
    vitamin_a?: string;
    vitamin_c?: string;
    vitamin_d?: string;
    ph?: string;
  };
  inventory_modifiers: IKeyMapping<IInventoryModifiers>;
  variants: IProductDetailVariant[];
  images: { urls: IKeyMapping<string> }[];
  child_products: IProductDetailChildProduct[];
  taxon_en?: IKeyMapping<string[]>;
  taxon_ar?: IKeyMapping<string[]>;
  taxon_ids?: IKeyMapping<number[]>;
}

interface IProductVariantOptionValue extends ICommonName {
  presentation: string;
}

export interface IProductDetailVariant {
  id: number;
  option_type: ICommonName;
  option_value: IProductVariantOptionValue;
  image: IProductVariantImage;
}

export interface IProductDetailChildProduct {
  quantity: number;
  child_product: {
    id?: number;
    name: string;
    short_description: string;
    images?: { urls: IKeyMapping<string> }[];
    inventory_modifiers?: IKeyMapping<IInventoryModifiers>;
  };
}

export interface IBundleProducts {
  master_product_id: number;
  child_product_id: number;
  quantity: number;
  child_product?: IProduct;
}

export interface ISimilatProductDetail {
  id: number;
  name: string;
  price: number;
  short_description: string;
  images: { urls: IKeyMapping<string> }[];
}

export interface IProductTaxon {
  product_id: number;
  taxon_id: number;
  taxon?: ITaxon;
}

export interface IProductTaxonIds {
  product_id: number;
  taxon_ids: number[];
  default_taxon_id?: number;
}

export interface IGrpcProductBarcodeRes {
  barcodes: IProductBarcode[];
}

export interface IGrpcGetProductBarcodesReq {
  productIds: number[];
}

export interface IProductUploadCsv extends IAuditable {
  sku: string;
  product_name_en: string;
  product_name_ar: string;
  long_description_en: string;
  long_description_ar: string;
  short_description_en: string;
  short_description_ar: string;
  taxon_id: string;
  available_date: string;
  electronic_product_code: string;
  height_cm: string;
  width_cm: string;
  max_grams_per_order: string;
  moq_limit: string;
  default_weight_count: string;
  depth_cm: string;
  weight_kg: string;
  weight_increment: string;
  size: string;
  issue_loyalty_points?: string;
  perform_liquid_weight_check: string;
  crushable: string;
  gluten_free: string;
  cod_enabled: string;
  promotable: string;
  refundable: string;
  deactivate: string;
  bin_category_code: string;
  tax_category_code: string;
  brand_code: string;
  delivery_type: string;
  delivery_method: string;
  supplier_code: string;
  manufacturer_code: string;
  delivery_type_ids?: number[];
  delivery_option_ids?: number[];
  supplier_id?: number;
  manufacturer_id?: number;
  bin_category_id?: number;
  tax_category_id?: number;
  brand_name?: string;
  brand_id?: number;
  validity_tag_en: string;
  validity_tag_ar: string;
  image_1: string;
  image_2: string;
  image_3: string;
  image_4: string;
  image_5: string;
  image_6: string;
  image_7: string;
  barcode_1: string;
  barcode_2: string;
  barcode_3: string;
  barcode_4: string;
  barcode_5: string;
}

export interface IProductUpdateCsv {
  sku: string;
  product_name_en?: string;
  product_name_ar?: string;
  long_description_en?: string;
  long_description_ar?: string;
  short_description_en?: string;
  short_description_ar?: string;
  taxon_id?: string;
  available_date?: string;
  electronic_product_code?: string;
  height_cm?: string;
  width_cm?: string;
  max_grams_per_order?: string;
  moq_limit: string;
  default_weight_count: string;
  depth_cm?: string;
  weight_kg?: string;
  weight_increment?: string;
  size?: string;
  issue_loyalty_points?: string;
  perform_liquid_weight_check?: string;
  crushable?: string;
  gluten_free?: string;
  cod_enabled?: string;
  promotable?: string;
  refundable?: string;
  deactivate?: string;
  bin_category_code?: string;
  tax_category_code?: string;
  brand_code?: string;
  supplier_id?: number;
  manufacturer_id?: number;
  bin_category_id?: number;
  tax_category_id?: number;
  brand_name?: string;
  brand_id?: number;
  delivery_type?: string;
  delivery_type_ids?: number[];
  delivery_method?: string;
  delivery_option_ids?: number[];
  supplier_code?: string;
  manufacturer_code?: string;
  validity_tag_en?: string;
  validity_tag_ar?: string;
  image_1?: string;
  image_2?: string;
  image_3?: string;
  image_4?: string;
  image_5?: string;
  image_6?: string;
  image_7?: string;
  barcode_1?: string;
  barcode_2?: string;
  barcode_3?: string;
  barcode_4?: string;
  barcode_5?: string;
}

export interface IBulkProductUploadPayload<T> {
  country_id?: number;
  category_id?: number;
  supermarket_ids?: number[];
  supermarket_id?: number;
  zone_id?: number;
  action?: string;
  event: string;
  job_entity_id?: number;
  list: T[];
}

export interface IMetadataCsv {
  sku: string;
  meta_title: string;
  meta_keywords_en: string;
  meta_keywords_ar: string;
  meta_description_en: string;
  meta_description_ar: string;
}

export interface IProductLocationAndStockCsv {
  sku: string;
  aisle: string;
  position: number;
  bay?: string;
  level?: string;
  division?: string;
  stock?: number;
}

export interface IDeeplinkCsv {
  sku: string;
  deeplink_1?: string;
  deeplink_2?: string;
}

export interface IBundleProductsCsv {
  sku: string;
  bundle_sku: string;
  bundle_sku_list: IBundleSkuCsv[];
}

export interface IProductTaxonsCsv {
  sku: string;
  taxon_ids: string;
  product_taxon_list: number[];
}

export interface IBundleSkuCsv {
  child_product_id: number;
  quantity: number;
}

export type IProductListData = {
  page: number;
  sku: string;
  name: string;
  brand: string;
  country: string;
  category: string;
  barcode: string;
  deactivate: string[];
};

export interface IProductListPayload extends IProductListData {
  [index: string]: string | string[] | number;
}

export interface IGrpcProductDetailRequest {
  countryId?: number;
  deliveryTypeIds?: number[];
  deliveryOptionIds?: number[];
  superMarketIds?: number[];
}

export interface IGrpcProductDetailResponse {
  country?: ICountry;
  deliveryType?: IDeliveryType[];
  deliveryOption?: IDeliveryOption[];
  superMarket?: ISuperMarket[];
}

export interface IProductBaseList {
  id: number;
  name?: string;
  sku?: string;
}

export interface IGrpcGetPutawayProductReq {
  productIds: number[];
  productVariantIds: number[];
}

export interface IGrpcGetPutawayProductRes {
  products: IProduct[];
  productVariants: IProductVariant[];
}

export interface IGrpcGetProductNameReq {
  name: string;
}

export interface IGrpcGetProductsRes {
  products: IProduct[];
}

export interface IProductIndexAndId {
  id: number;
  index: number;
}

export interface ISupermarketProductRquest {
  limit: number;
}

export interface IUpdateProductLoyaltyPointPayload {
  loyalty_synced_at: Date;
  loyalty_point: number;
  loyalty_sync_key?: string;
}

export interface IGrpcGetLmsProductsReq {
  product_ids: number[];
}

export interface IGrpcGetLmsProductsRes {
  products: IProduct[];
}

export interface IGrpcGetProductWithInventoryModifiersReq {
  id: number;
  supermarket_id: number;
}

export interface IGrpcGetProductWithInventoryModifiersRes {
  product: IProduct;
}

export enum ProductTaxonsActionEnum {
  ADD = 'add',
  DELETE = 'delete'
}

export enum ProductGridFilterEnum {
  PAGE = 'page',
  SKU = 'sku',
  NAME = 'name',
  BRAND = 'brand',
  COUNTRY = 'country',
  CATEGORY = 'category',
  BARCODE = 'barcode',
  DEACTIVATE = 'deactivate'
}

export const productUploadCsvLabels = {
  sku: 'SKU',
  product_name_en: 'Product Name (EN)',
  product_name_ar: 'Product Name (AR)',
  long_description_en: 'Long Description (EN)',
  long_description_ar: 'Long Description (AR)',
  short_description_en: 'Short Description (EN)',
  short_description_ar: 'Short Description (AR)',
  taxon_id: 'Taxon ID',
  available_date: 'Available Date',
  electronic_product_code: 'Electronic Product Code',
  height_cm: 'Height (cm)',
  width_cm: 'Width (cm)',
  max_grams_per_order: 'Max Grams per Order',
  moq_limit: 'MOQ Limit',
  default_weight_count: 'Default Weight Count',
  depth_cm: 'Depth (cm)',
  weight_kg: 'Weight (kg)',
  weight_increment: 'Weight Increment',
  size: 'Size',
  issue_loyalty_points: 'Issue Loyalty Points',
  perform_liquid_weight_check: 'Perform Liquid Weight Check',
  crushable: 'Crushable',
  gluten_free: 'Gluten Free',
  cod_enabled: 'COD enabled',
  promotable: 'Promotable',
  refundable: 'Refundable',
  deactivate: 'Deactivate',
  bin_category_code: 'Bin Category Code',
  tax_category_code: 'Tax Category Code',
  brand_code: 'Brand Code',
  delivery_type: 'Delivery Type',
  delivery_method: 'Delivery Method',
  barcode_1: 'Barcode 1',
  barcode_2: 'Barcode 2',
  barcode_3: 'Barcode 3',
  barcode_4: 'Barcode 4',
  barcode_5: 'Barcode 5',
  supplier_code: 'Supplier Code',
  manufacturer_code: 'Manufacturer Code',
  validity_tag_en: 'Validity Tag (EN)',
  validity_tag_ar: 'Validity Tag (AR)',
  image_1: 'Image 1',
  image_2: 'Image 2',
  image_3: 'Image 3',
  image_4: 'Image 4',
  image_5: 'Image 5',
  image_6: 'Image 6',
  image_7: 'Image 7'
};

export const metadataUploadCsvLabels = {
  sku: 'SKU',
  meta_title: 'Meta Title',
  meta_keywords_en: 'Meta Keywords (EN)',
  meta_keywords_ar: 'Meta Keywords (AR)',
  meta_description_en: 'Meta Description (EN)',
  meta_description_ar: 'Meta Description (AR)'
};

export const bundleProductsCsvLabels = {
  sku: 'SKU',
  bundle_sku: 'Bundle SKU',
  quantity: 'Quantity'
};

export const productTaxonsCsvLabels = {
  sku: 'SKU',
  taxon_ids: 'Taxon IDs'
};

export const productLocationAndStockCsvLabels = {
  sku: 'SKU',
  aisle: 'Aisle',
  bay: 'Bay',
  level: 'Level',
  division: 'Division',
  position: 'Position',
  stock: 'Stock'
};

export const zipFileTypes: string[] = ['csv', 'png', 'jpg', 'jpeg'];

export const ProductTaxonsActionOptions = [
  {
    id: ProductTaxonsActionEnum.ADD,
    name: 'Add Taxons'
  },
  {
    id: ProductTaxonsActionEnum.DELETE,
    name: 'Delete Taxons'
  }
];

const categorySelect = {
  category: {
    name: true,
    name_ar: true
  }
};

const brandSelect = {
  brand: {
    name_en: true,
    name_ar: true
  }
};

const inventoryModifiersSelect = {
  inventory_modifiers: {
    id: true,
    product_id: true,
    supermarket_id: true,
    action_price: true,
    sale_price: true,
    sale_start_date: true,
    sale_end_date: true,
    sale_start_time: true,
    sale_end_time: true,
    availability_start_date: true,
    availability_end_date: true,
    availability_start_time: true,
    availability_end_time: true,
    track_stock: true,
    stock_quantity: true,
    reserved_quantity: true
  }
};

const nutritionalFactsSelect = {
  nutritional_facts: {
    id: true,
    quantity: true,
    quantity_ar: true,
    calories: true,
    total_fat: true,
    saturated_fat: true,
    trans_fat: true,
    cholesterol: true,
    total_carbohydrates: true,
    sugar: true,
    dietary_fiber: true,
    protein: true,
    salt: true,
    sodium: true,
    iron: true,
    calcium: true,
    vitamin_a: true,
    vitamin_c: true,
    vitamin_d: true,
    ph: true
  }
};

const imagesSelect = {
  images: {
    id: true,
    file_name: true,
    order: true
  }
};

export const productBaseListSelect = {
  ...BaseSelect,
  sku: true
};

export const productListSelect = {
  ...BaseSelect,
  price: true,
  loyalty_point: true,
  short_description: true,
  discount: true,
  is_wishlisted: true,
  images: { file_name: true }
};

export const productDetailUserSelect = {
  ...BaseSelect,
  name_ar: true,
  sku: true,
  short_description: true,
  short_description_ar: true,
  long_description: true,
  long_description_ar: true,
  bundled: true,
  refundable: true,
  deactivate: true,
  cod_enabled: true,
  is_gluten_free: true,
  weight: true,
  weight_increment: true,
  max_weight_per_order: true,
  default_weight_count: true,
  perform_liquid_weight_check: true,
  moq_limit: true,
  is_taxable: true,
  delivery_type: { delivery_type_id: true },
  delivery_option: { delivery_option_id: true },
  ...categorySelect,
  ...brandSelect,
  ...nutritionalFactsSelect,
  ...inventoryModifiersSelect,
  ...imagesSelect,
  variants: {
    id: true,
    option_type: {
      id: true,
      name_en: true,
      name_ar: true
    },
    option_value: {
      ...BaseSelect,
      presentation_en: true,
      presentation_ar: true
    },
    images: {
      id: true,
      url: true
    }
  },
  child_products: {
    child_product_id: true,
    quantity: true,
    child_product: {
      ...BaseSelect,
      name_ar: true,
      short_description: true,
      short_description_ar: true,
      ...imagesSelect,
      ...inventoryModifiersSelect
    }
  }
};

export const productDetailUserRelation = {
  images: true,
  brand: true,
  delivery_type: true,
  delivery_option: true,
  nutritional_facts: true,
  inventory_modifiers: true,
  category: true,
  variants: {
    option_type: true,
    option_value: true,
    images: true
  },
  child_products: {
    child_product: {
      images: true,
      inventory_modifiers: true
    }
  }
};

export const productByBarcodeUserSelect = {
  ...BaseSelect,
  sku: true,
  short_description: true,
  long_description: true,
  bundled: true,
  refundable: true,
  deactivate: true,
  cod_enabled: true,
  is_gluten_free: true,
  is_taxable: true,
  weight: true,
  weight_increment: true,
  max_weight_per_order: true,
  default_weight_count: true,
  perform_liquid_weight_check: true,
  moq_limit: true,
  delivery_type: { delivery_type_id: true },
  delivery_option: { delivery_option_id: true },
  ...categorySelect,
  ...brandSelect,
  ...inventoryModifiersSelect,
  ...imagesSelect,
  variants: {
    id: true,
    option_type: {
      id: true,
      name_en: true
    },
    option_value: {
      ...BaseSelect,
      presentation_en: true
    },
    images: {
      id: true,
      url: true
    }
  }
};

export const productByBarcodeUserRelation = {
  images: true,
  brand: true,
  category: true,
  delivery_type: true,
  delivery_option: true,
  inventory_modifiers: true,
  variants: {
    option_type: true,
    option_value: true,
    images: true
  }
};

export const similarProductSelect = {
  ...BaseSelect,
  price: true,
  short_description: true,
  ...imagesSelect
};

export const productOrderSelect = {
  ...productBaseListSelect,
  price: true,
  category_id: true,
  bin_category: {
    code: true,
    category: true
  }
};

export const productPaginatedListSelect = {
  ...BaseSelect,
  sku: true,
  country_id: true,
  deactivate: true,
  brand: { name_en: true },
  ...imagesSelect,
  category: {
    id: true,
    name: true
  },
  barcodes: {
    name: true,
    barcode: true
  },
  is_taxable: true
};

export const productDetailSelect = {
  ...BaseSelect,
  sku: true,
  slug: true,
  is_taxable: true,
  loyalty_point: true,
  short_description: true,
  long_description: true,
  name_ar: true,
  short_description_ar: true,
  long_description_ar: true,
  electronic_product_code: true,
  height: true,
  width: true,
  max_weight_per_order: true,
  depth: true,
  weight: true,
  weight_increment: true,
  size: true,
  issue_loyalty_points: true,
  cod_enabled: true,
  promotable: true,
  refundable: true,
  deactivate: true,
  bundled: true,
  perform_liquid_weight_check: true,
  crushable: true,
  is_gluten_free: true,
  country_id: true,
  category: {
    id: true,
    name: true
  },
  meta_title: true,
  meta_keywords: true,
  meta_keywords_ar: true,
  meta_description: true,
  meta_description_ar: true,
  moq_limit: true,
  default_weight_count: true,
  validity_tag: true,
  validity_tag_ar: true,
  ...imagesSelect,
  brand: { name_en: true },
  delivery_type: { delivery_type_id: true },
  delivery_option: { delivery_option_id: true },
  bin_category: { ...BinCategorySelect },
  taxation: { ...BaseSelect },
  supplier: { ...BaseSelect },
  manufacturer: { ...BaseSelect },
  ...nutritionalFactsSelect,
  ...inventoryModifiersSelect,
  barcodes: {
    id: true,
    barcode: true,
    created_at: true,
    updated_at: true
  },
  child_products: {
    child_product_id: true,
    quantity: true,
    child_product: {
      ...BaseSelect,
      sku: true
    }
  },
  taxons: {
    id: true,
    pretty_name: true
  },
  variants: {
    id: true,
    option_type: {
      id: true,
      name_en: true
    },
    option_value: {
      ...BaseSelect,
      presentation_en: true,
      presentation_ar: true
    },
    barcodes: {
      barcode: true
    },
    images: {
      id: true,
      url: true
    }
  }
};

export const productDetailRelations = {
  images: true,
  brand: true,
  delivery_type: true,
  delivery_option: true,
  bin_category: true,
  supplier: true,
  manufacturer: true,
  taxation: true,
  nutritional_facts: true,
  inventory_modifiers: true,
  barcodes: true,
  child_products: { child_product: true },
  taxons: true,
  variants: {
    option_type: true,
    option_value: true,
    barcodes: true,
    images: true
  },
  category: true
};

export const productByBarcodeSelect = {
  ...BaseSelect,
  sku: true,
  weight: true,
  default_weight_count: true,
  weight_increment: true,
  max_weight_per_order: true,
  country_id: true,
  bundled: true,
  ...imagesSelect,
  barcodes: { barcode: true },
  inventory_modifiers: {
    id: true,
    action_price: true
  },
  bin_category: { ...BinCategorySelect },
  variants: {
    id: true,
    option_type: {
      id: true,
      name_en: true
    },
    option_value: {
      ...BaseSelect,
      presentation_en: true,
      presentation_ar: true
    },
    images: {
      id: true,
      url: true
    }
  },
  child_products: {
    child_product_id: true,
    quantity: true,
    child_product: {
      id: true,
      sku: true,
      weight: true,
      default_weight_count: true,
      weight_increment: true,
      barcodes: {
        id: true,
        barcode: true
      }
    }
  }
};

export const productByBarcodeRelation = {
  images: true,
  barcodes: true,
  inventory_modifiers: true,
  bin_category: true,
  variants: {
    option_type: true,
    option_value: true,
    images: true
  },
  child_products: {
    child_product: { barcodes: true }
  }
};

export const getPutawayProductSelect = {
  ...BaseSelect,
  name_ar: true,
  ...imagesSelect,
  barcodes: { barcode: true }
};

export const addOrderProductSelect = {
  id: true,
  weight: true,
  weight_increment: true,
  max_weight_per_order: true,
  perform_liquid_weight_check: true,
  moq_limit: true,
  cod_enabled: true,
  default_taxon_id: true,
  loyalty_point: true,
  variants: { id: true },
  inventory_modifiers: {
    supermarket_id: true,
    action_price: true,
    sale_price: true,
    sale_start_date: true,
    sale_end_date: true,
    sale_start_time: true,
    sale_end_time: true,
    stock_quantity: true
  }
};

export const productWithInventoryModifiersSelect = {
  ...productBaseListSelect,
  category_id: true,
  loyalty_point: true,
  inventory_modifiers: {
    supermarket_id: true,
    action_price: true,
    sale_price: true,
    sale_start_date: true,
    sale_end_date: true,
    sale_start_time: true,
    sale_end_time: true,
    stock_quantity: true,
    track_stock: true
  }
};
