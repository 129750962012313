import { IBaseAudit } from './auditable.interface';
import { IEmployee } from './employee.interface';
import { IPermission, IPermissionListItem, IPermissionToken, permissionTokenSelect } from './permission.interface';
import { BaseSelect } from './common.interface';

export interface IRole extends IBaseAudit {
  name: string;
  permissions?: IPermission[];
  employees?: IEmployee[];
}

export interface IRoleListItem extends IRole {
  id: number;
  is_deletable: boolean;
  permissions?: IPermissionListItem[];
}

export interface IRolePermission {
  role_id: number;
  permission_id: number;
  role?: IRole;
  permission?: IPermission;
}

export const roleSelect = {
  ...BaseSelect
};

export interface ICreateRole {
  name: string;
  permission_ids: number[];
}

export type IRoleListPayload = {
  page: number;
  name?: string;
};

export const roleTokenSelect = {
  id: true,
  updated_at: true,
  permissions: permissionTokenSelect
};

export interface IRoleToken {
  id: number;
  updated_at: Date;
  permissions: IPermissionToken[];
}

export enum RoleGridFilterEnum {
  PAGE = 'page',
  NAME = 'name'
}
