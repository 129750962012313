import { IBaseAudit } from '../auditable.interface';
import { IKeyMapping } from '../key-mapping.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { ICreateTranslation, ITranslationData } from '../translation/translation.interface';
import { ICity } from './city.interface';
import { BaseSelect } from './../common.interface';

export interface IDistrictDeliveryZone {
  latitude?: string;
  longitude?: string;
  delivery_zone?: string;
}

export interface IDistrict extends IBaseAudit, IDistrictDeliveryZone {
  name: string;
  abbreviation?: string;
  city_id?: number;
  translation_id?: string;
  city?: ICity;
  district_image_url?: string;
  is_visible?: boolean;
  super_markets?: ISuperMarket[];
  is_deletable?: boolean;
  is_associated?: boolean;
}

export interface IDistrictListItem extends IDistrict {
  id: number;
  is_deletable: boolean;
}

export type IDistrictListData = {
  page: number;
  name: string;
  abbreviation: string;
  city: string;
  is_visible: string[];
};

export interface IDistrictListPayload extends IDistrictListData {
  [index: string | number]: string | string[] | number | number[];
}

export interface IDistrictBaseListData {
  city_id: number;
}

export interface IDistrictBaseListPayload extends IDistrictBaseListData {
  [index: string]: number | number[];
}

export const DistrictListSelect = {
  ...BaseSelect,
  city_id: true,
  abbreviation: true,
  district_image_url: true,
  is_visible: true,
  latitude: true,
  longitude: true,
  delivery_zone: true,
  city: {
    name: true
  }
};

export interface IDistrictTranslation extends ITranslationData {
  name?: string;
}

export interface ICreateDistrictTranslation extends ICreateTranslation {
  name?: IKeyMapping<string>;
}
