export interface IValidateOrderItem {
  product_id: number;
  product_variant_id: number;
  supermarket_id: number;
  requested_quantity: number;
  quoted_price: number;
  available_quantity?: number;
  current_price?: number;
  is_sale_price?: boolean;
  track_stock?: boolean;
}
