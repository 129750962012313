import moment, { Moment } from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'localDate' })
export class LocalDatePipe implements PipeTransform {
  transform(value: string, format: string, isTimeFormat: boolean = false): string {
    if (!value) return '';

    const date: Moment = isTimeFormat ? moment(value, format) : moment(value);

    return date.isValid() ? date.local().format(format) : '';
  }
}
