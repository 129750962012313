import { IBaseAudit } from '../auditable.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';

export interface IAisleShelf extends IBaseAudit {
  aisle?: string;
  shelf?: string;
  barcode?: string;
  category_id?: number;
  supermarket_id?: number;
  supermarket?: ISuperMarket;
}

export const aisleShelfSelect = {
  id: true,
  aisle: true,
  shelf: true,
  barcode: true,
  category_id: true
};
