import { IBaseAudit } from '../auditable.interface';

export interface IInvoice extends IBaseAudit {
  public_id: string;
  shipment_id: number;
  type: InvoiceTypeEnum;
  total_tax: number;
  total_amount: number;
  total_allowance: number;
  invoice_pdf_url: string;
  invoice_zatca?: IInvoiceZatca;
}

export enum InvoiceTypeEnum {
  MAIN = 'main',
  RETURN = 'return'
}

export interface IInvoiceZatca extends IBaseAudit {
  invoice_id: number;
  total_tax: number;
  total_amount: number;
  signed_xml_url: string;
  invoice_hash: string;
  qr_code: string;
  // uuid:string;
  report_response?: string;
  reported_at?: Date;
  status: InvoiceZatcaStatusEnum;
}

export interface IInvoiceGeneratedZatca {
  signed_invoice_string: string;
  invoice_hash: string;
  qr: string;
}

export enum InvoiceZatcaStatusEnum {
  PENDING = 'pending',
  REPORTED = 'reported',
  FAILED = 'failed'
}
