export default {
  FIRST_NAME_ERROR_MESSAGE: 'First Name should contain only alphabets and space',
  LAST_NAME_ERROR_MESSAGE: 'Last Name should contain only alphabets and space',
  VALID_EMAIL_ERROR_MESSAGE: 'common_valid_email_error_message',
  INVALID_EMAIL_ERROR_MESSAGE: 'common_invalid_email_error_message',
  PASSWORD_UPPERCASE_ERROR_MESSAGE: 'common_pass_contain_upper_case_error_message',
  PASSWORD_LOWERCASE_ERROR_MESSAGE: 'common_pass_contain_lower_case_error_message',
  PASSWORD_SYMBOL_ERROR_MESSAGE: 'common_pass_contain_symbol_error_message',
  PASSWORD_NUMBER_ERROR_MESSAGE: 'common_pass_contain_number_error_message',
  CONFIRM_PASSWORD_ERROR: 'common_confirm_pass_not_same_new_pass_error_message',
  MOBILE_NUMBER_ERROR: 'common_valid_mobile_number_error_message',
  NAME_ALPHABETS_ERROR: 'Name should contain only alphabets',
  ALPHABETS_ERROR: '{label} should contain only alphabets',
  ALPHANUM_ERROR: '{label} should contain only alphabets and numbers',
  ALPHANUM_SPACE_ERROR: '{label} should contain only alphabets, numbers and spaces',
  NAME_ALPHANUM_SPACE_ERROR: 'Name should contain only alphabets, numbers and spaces',
  ALPHANUM_SPACE_DASH_ERROR: '{label} should contain only alphabets, numbers, spaces, and dashes',
  ALPHANUM_SPECIAL_CHAR_ERROR: '{label} should contain only alphabets, numbers, and special characters',
  ALPHANUM_SPECIAL_CHAR_SPACE_ERROR: '{label} should contain only alphabets, numbers, special characters, and spaces',
  ALPHA_DASH_ERROR: '{label} should contain only alphabets and dashes',
  ARABIC_ALPHANUM_ERROR: '{label} should contain only Arabic alphabets and numbers',
  ARABIC_ALPHANUM_SPACE_ERROR: '{label} should contain only Arabic alphabets, numbers, and spaces',
  ARABIC_ALPHANUM_SPECIAL_CHAR_SPACE_ERROR:
    '{label} should contain only Arabic alphabets, numbers, special characters, and spaces',
  ARABIC_ENGLISH_ALPHANUM_SPECIAL_CHAR_SPACE_ERROR:
    '{label} should contain only Arabic/English alphabets, numbers, special characters, and spaces',
  ROLE_ID_MANDATORY_ERROR: 'ROLE ID is required',
  LENGTH_LESS_THAN_REASON: '{label} should not be less than {length}',
  LENGTH_MORE_THAN_REASON: '{label} should not be more than {length}',
  DATE_AFTER_OR_SAME_FIELD: 'The {label1} date should not be before the {label2} date',
  NOT_EMPTY_OR_ZERO: '{label} should not be an empty string or 0'
};
