import { IBaseAudit } from '../auditable.interface';
import { ICurrency } from '../currency.interface';
import { IKeyMapping } from '../key-mapping.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import {
  ICreateTranslation,
  ITranslation,
  ITranslationData,
  ITranslationField
} from '../translation/translation.interface';
import { ICity } from './city.interface';
import { BaseSelect } from './../common.interface';

export interface ICountry extends IBaseAudit {
  name?: string;
  iso_country_code?: string;
  currency_id?: number;
  currency?: ICurrency;
  currency_precision?: number;
  default_super_market_id?: number;
  translation_id?: string;
  time_zone?: string;
  super_market?: ISuperMarket;
  cities?: ICity[];
  translations?: ICountryTranslation[];
  city_list?: ICity[];
  flag_url?: string;
  latitude?: number;
  longitude?: number;
  is_visible?: boolean;
}

export interface ICountryTranslation extends ITranslation {
  country_id: number;
}

export interface ICountryGrpc {
  id: number;
  currency_precision?: number;
}

export interface ICountryListItem extends ICountry {
  id: number;
  is_deletable: boolean;
}

export interface ICountryCode {
  countryDialCode: string;
  name: string;
}

export type ICountryListData = {
  page: number;
  name: string;
  iso_country_code: string;
  super_market: string;
  currency: number[];
  latitude: number;
  longitude: number;
  is_visible: string[];
};

export interface IGrpcCountry {
  countries: ICountry[];
}

export interface IGrpcCity {
  countries: ICity[];
}

export interface ICountryListPayload extends ICountryListData {
  [index: string | number]: string | string[] | number | number[];
}

export interface ICountryTranslations extends ITranslationData {
  name?: string;
}

export interface ICreateCountryTranslation extends ICreateTranslation {
  name?: IKeyMapping<string>;
}

export const countryTranslationFields: ITranslationField[] = [{ key: 'name', label: 'Name' }];

export const countrySelect = {
  ...BaseSelect,
  latitude: true,
  longitude: true,
  flag_url: true
};

export const CountryListSelect = {
  ...BaseSelect,
  iso_country_code: true,
  currency_precision: true,
  flag_url: true,
  latitude: true,
  longitude: true,
  is_visible: true,
  currency: {
    ...BaseSelect
  },
  super_market: {
    ...BaseSelect
  }
};

export const countryBaseListSelect = {
  ...BaseSelect,
  currency_precision: true,
  iso_country_code: true,
  latitude: true,
  longitude: true
};

export enum CountryGridFilterEnum {
  PAGE = 'page',
  NAME = 'name',
  CURRENCY = 'currency',
  IS_VISIBLE = 'is_visible',
  SUPER_MARKET = 'super_market',
  ISO_COUNTRY_CODE = 'iso_country_code'
}
