import jwtDecode from 'jwt-decode';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { IUserToken } from '@ecommerce/common-types';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const token: string | null = localStorage.getItem('x-auth-token');

    if (token) {
      const user: IUserToken = jwtDecode<IUserToken>(token);

      if (user) {
        this.router.navigate(['/users']);
        return false;
      }
    }

    return true;
  }
}
