import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';

export interface IWmsBin extends IBaseAudit {
  supermarket_id: number;
  type: WmsBinType;
  no_of_bins?: number;
  barcode_label?: string;
  super_market?: ISuperMarket;
  is_selected?: boolean;
}

export interface IWmsBinListData {
  page: number;
  id: string;
  supermarket_id: number;
  type: string;
}

export interface IWmsBinListPayload extends IWmsBinListData {
  [index: string]: string | number;
}

export interface IWmsDownloadBinBarcodePayload {
  [index: string]: string | number | number[];
}

export enum WmsBinType {
  BATCH_PICKING = 'batch-picking',
  PICKING = 'picking',
  DELIVERY = 'delivery',
  STORAGE = 'storage'
}

export enum WmsBinGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  SUPERMARKET_ID = 'supermarket_id',
  TYPE = 'type'
}

export const wmsBinTypeOptions: ICommonOption<WmsBinType>[] = [
  {
    id: WmsBinType.BATCH_PICKING,
    name: 'Batch Picking'
  },
  {
    id: WmsBinType.PICKING,
    name: 'Picking'
  },
  {
    id: WmsBinType.DELIVERY,
    name: 'Delivery'
  },
  {
    id: WmsBinType.STORAGE,
    name: 'Storage'
  }
];

export const wmsBinSelect = {
  id: true,
  supermarket_id: true,
  type: true
};
