import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';

import { IPagination, constants } from '@ecommerce/common-types';

@Component({
  selector: 'ecomm-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnChanges {
  @Input() public totalPage = 1;
  @Input() public activePage = 1;
  @Input() public viewMinPages = false;
  @Input() public viewMaxPages = true;
  @Input() public intermediatePages: number[] = Array.from({ length: 3 }, (_, index: number): number => index + 3);
  @Output() public paginationDetails: EventEmitter<IPagination> = new EventEmitter();
  public initialPage = 1;
  public ellipsisPoints = '...';
  public paginationLimit = constants.PAGINATION_LIMIT;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalPage']?.currentValue) this.totalPage = changes['totalPage'].currentValue;
  }

  public changePage(page: number): void {
    if (page >= 1 && page <= this.totalPage) {
      this.activePage = page;

      if (page === this.intermediatePages[2] && page <= this.totalPage - 3) {
        this.intermediatePages.push(this.intermediatePages[2] + 1);
        this.intermediatePages.shift();
      } else if (page === this.intermediatePages[0] && page >= 4) {
        this.intermediatePages.pop();
        this.intermediatePages.unshift(this.intermediatePages[0] - 1);
      }

      if (page > 4) this.viewMinPages = true;
      else {
        this.viewMinPages = false;
        this.updateintermediatePages(
          Array.from({ length: 3 }, (_, index): number => index + 1),
          2
        );
      }

      if (page > this.totalPage - 4) {
        this.updateintermediatePages(
          Array.from({ length: 3 }, (_, index): number => index + 1),
          this.totalPage - 5
        );
        this.viewMaxPages = false;
      } else this.viewMaxPages = true;

      this.paginationDetails.emit({
        activePage: page,
        viewMinPages: this.viewMinPages,
        viewMaxPages: this.viewMaxPages,
        intermediatePages: this.intermediatePages
      });
    }
  }

  public pivotIntermPages(forwardShift: boolean): void {
    if (forwardShift) this.updateintermediatePages(this.intermediatePages, 2);
    else this.updateintermediatePages(this.intermediatePages, -2);

    this.changePage(this.intermediatePages[1]);
  }

  private updateintermediatePages(array: number[], shiftIndex: number): void {
    this.intermediatePages = array.map((page: number): number => page + shiftIndex);
  }
}
