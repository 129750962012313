export enum SIDEBAR_MENU_ROUTE_LINKS {
  PRODUCTS = '/products',
  DASHBOARD = '/dashboard',
  USERS = '/users',
  SETTINGS = '/settings',
  PROMOTIONS = '/promotions',
  TOOLS = '/tools',
  ORDERS = '/orders',
  DEVELOPER_TOOLS = '/developer-tools',
  KITCHEN = '/kitchen',
  WAREHOUSE_MANAGEMENT = '/warehouse-management',
  FULFILLMENTS = '/fulfillments',
  PRODUCTS_TAXONOMIES = '/products/taxonomies',
  PRODUCTS_OPTION_TYPES = '/products/option-types',
  PRODUCTS_SUPPLIERS = '/products/suppliers',
  PRODUCTS_MANUFACTURERS = '/products/manufacturers',
  PRODUCTS_CATEGORIES = '/products/categories',
  PRODUCTS_TAXON_GROUPS = '/products/taxon-groups',
  PRODUCTS_BRANDS = '/products/brands',
  PRODUCTS_OFFLINE = '/products/offline',
  USERS_CUSTOMERS = '/users/customers',
  USERS_EMPLOYEES = '/users/employees',
  USERS_EMPLOYEE_ROLES = '/users/employee-roles',
  SETTINGS_LOCATION = '/settings/location',
  SETTINGS_LOCATION_COUNTRIES = '/settings/location/countries',
  SETTINGS_LOCATION_CITIES = '/settings/location/cities',
  SETTINGS_LOCATION_SUPER_MARKETS = '/settings/location/supermarkets',
  SETTINGS_LOCATION_WARE_HOUSES = '/settings/location/warehouses',
  SETTINGS_TAXATIONS = '/settings/taxations',
  SETTINGS_BIN_CATEGORIES = '/settings/bin-categories',
  SETTINGS_DELIVERY_FEES = '/settings/delivery-fees',
  SETTINGS_DELIVERY_CAPACITY_MANAGEMENT = '/settings/delivery-capacity-management',
  SETTINGS_DELIVERY_REGIONS = '/settings/delivery-regions',
  SETTINGS_HOLIDAYS = '/settings/holidays',
  SETTINGS_ORDER_CANCELLATION_REASONS = '/settings/order-cancellation-reasons',
  SETTINGS_FLEET_MANAGEMENT = '/settings/fleet-management',
  SETTINGS_FLEET_MANAGEMENT_VEHICLE_MANAGEMENT = '/settings/fleet-management/vehicle-management',
  SETTINGS_FLEET_MANAGEMENT_VEHICLE_HEALTH_EVIDENCE = '/settings/fleet-management/vehicle-health-evidence',
  TOOLS_BULK_PRODUCT_UPLOAD = '/tools/bulk-product-upload',
  TOOLS_BULK_PRODUCT_UPDATE = '/tools/bulk-product-update',
  TOOLS_METADATA = '/tools/metadata',
  TOOLS_INVENTORY_MODIFIER = '/tools/inventory-modifiers',
  TOOLS_NUTRITIONAL_FACTS = '/tools/nutritional-facts',
  TOOLS_BUNDLE_PRODUCTS = '/tools/bundle-products',
  TOOLS_PRODUCT_TAXONS = '/tools/product-taxons',
  TOOLS_PRODUCT_DEEPLINKS = '/tools/product-deeplinks',
  TOOLS_JOB_ENTITIES = '/tools/job-entities',
  TOOLS_PRODUCT_LOCATION_AND_STOCK = '/tools/product-location-and-stock',
  TOOLS_OFFLINE_PRODUCTS = '/tools/offline-products',
  KITCHEN_CUISINES = '/kitchen/cuisines',
  KITCHEN_RECIPES = '/kitchen/recipes',
  WAREHOUSE_CONFIGURATIONS = '/warehouse-management/configurations',
  WAREHOUSE_CONFIGURATION_ZONES = '/warehouse-management/configurations/zones',
  WAREHOUSE_CONFIGURATION_BINS = '/warehouse-management/configurations/bins',
  WAREHOUSE_CONFIGURATION_LOCATIONS = '/warehouse-management/configurations/locations',
  WAREHOUSE_PURCHASE_ORDER = '/warehouse-management/purchase-order',
  WAREHOUSE_RECEIVING = '/warehouse-management/receiving',
  WAREHOUSE_PUT_AWAY_ASSIGNMENT = '/warehouse-management/putaway-assignment',
  WAREHOUSE_STOCK_MANAGEMENT = '/warehouse-management/stock-management',
  FULFILLMENTS_DELIVERY_ZONES = '/fulfillments/delivery-zones',
  FULFILLMENTS_ORDER_PICKING_ASSIGNMENT = '/fulfillments/order-picking-assignment',
  FULFILLMENTS_STAGING_DISPATCH_ASSIGNMENT = '/fulfillments/staging-dispatch-assignment',
  FULFILLMENTS_STAGING_DISPATCH_ASSIGNMENT_BINS = '/fulfillments/staging-dispatch-assignment/bins',
  FULFILLMENTS_STAGING_DISPATCH_ASSIGNMENT_PRODUCTS = '/fulfillments/staging-dispatch-assignment/products',
  FULFILLMENTS_DELIVERY_BATCHES = '/fulfillments/delivery-batches',
  ORDERS_SUPERMARKET_CAPACITY = '/orders/supermarket-capacity',
  ORDERS_REFUNDS = '/orders/refunds',
  ORDERS_SHIPMENTS = '/orders/shipments',
  PRODUCTS_REPORTED_PRODUCTS = '/products/reported-products',
  ORDER_GENERATOR_DEVELOPER_TOOLS = '/developer-tools/order-generator'
}
