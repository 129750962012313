import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

import { IEventMethod } from '@ecommerce/common-types';

@Directive({ selector: '[ecommFileDragDrop]' })
export class FileDragAndDropDirective {
  @Output() private filesChangeEmitter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.border-style') private borderStyle = 'dashed';

  @HostListener('dragover', ['$event']) public onDragOver(event: IEventMethod) {
    event.preventDefault();
    event.stopPropagation();
    this.borderStyle = 'solid';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: IEventMethod) {
    event.preventDefault();
    event.stopPropagation();
    this.borderStyle = 'dashed';
  }

  @HostListener('drop', ['$event']) public onDrop(event: IEventMethod) {
    event.preventDefault();
    event.stopPropagation();
    this.borderStyle = 'dashed';
    const files: Array<File> = event.dataTransfer.files;
    this.filesChangeEmitter.emit(files);
  }
}
