import { IBaseAudit } from '../auditable.interface';
import { IOrderProduct } from '../order/order-product.interface';
import { IProduct } from '../product/product.interface';
import { BaseSelect, ICommonOption } from './../common.interface';

export interface IWmsProductReport extends IBaseAudit {
  supermarket_id: number;
  product_report_reason_id: number;
  reportor_id: number;
  comments?: string;
  resolved_by?: number;
  resolved_at?: Date;
  resolved_comments?: string;
  images: IWmsProductReportImage[];
  product_report_reason?: IWmsProductReportReason;
  order_product?: IOrderProduct;
  reporter_name?: string;
  resolver_name?: string;
  supermarket_name?: string;
  product?: IProduct;
  product_id?: number;
  report_source?: ProductReportSourceTypeEnum;
}

export interface IWmsProductReportImage extends IBaseAudit {
  product_report_id: number;
  image_url: string;
}

export interface IWmsProductReportReason extends IBaseAudit {
  name?: string;
  name_ar?: string;
  type?: WmsProductReportReasonTypeEnum;
  product_report?: IWmsProductReport[];
}

export interface IWmsProductReportBaseList {
  id: number;
  name: string;
}

export interface IResolveProductReportPayload {
  resolved_comments: string;
}

export interface IReportedProductsListData {
  page: number;
  id: string;
  supermarket_id: number;
  comments: string;
  resolved_comments: string;
  reason: string;
  product_name: string;
  resolved_at: string;
  reported_at: string;
}

export interface IReportedProductsListPayload extends IReportedProductsListData {
  [index: string]: string | number;
}

export const wmsProductReportReasonBaseListSelect = {
  ...BaseSelect
};

export enum ReportedProductGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  COMMENTS = 'comments',
  PRODUCT_NAME = 'product_name',
  SUPERMARKET_ID = 'supermarket_id',
  REASON = 'reason',
  RESOLVED_COMMENTS = 'resolved_comments',
  RESOLVED_AT = 'resolved_at',
  REPORTED_AT = 'reported_at'
}

export enum WmsProductReportReasonTypeEnum {
  PUTAWAY = 'putaway',
  PICK = 'pick',
  DISPATCH = 'dispatch'
}

export enum ProductReportSourceTypeEnum {
  RECEIVING = 'receiving',
  PUTAWAY = 'putaway',
  PICKING = 'picking'
}

export enum ProductReasonResolvedCommentTypeEnum {
  PACKAGE_CHANGE = 'package_change',
  NAME_CHANGE = 'name_change',
  NEW_BARCODE = 'new_barcode',
  NEW_SKU = 'new_sku'
}

export const productReportResolvedCommentStatusOptions: ICommonOption<ProductReasonResolvedCommentTypeEnum>[] = [
  {
    id: ProductReasonResolvedCommentTypeEnum.PACKAGE_CHANGE,
    name: 'Package Change'
  },
  {
    id: ProductReasonResolvedCommentTypeEnum.NAME_CHANGE,
    name: 'Name Change'
  },
  {
    id: ProductReasonResolvedCommentTypeEnum.NEW_BARCODE,
    name: 'New Barcode'
  },
  {
    id: ProductReasonResolvedCommentTypeEnum.NEW_SKU,
    name: 'New Sku'
  }
];

export const wmsProductReportSelect = {
  id: true,
  supermarket_id: true,
  comments: true,
  reportor_id: true,
  created_at: true,
  resolved_by: true,
  resolved_at: true,
  resolved_comments: true,
  product_report_reason_id: true,
  product_id: true,
  product_report_reason: {
    name: true,
    type: true
  }
};
