import { ICommonOption } from '../common.interface';

export interface IStagingDispatchAssignmentListData {
  page: number;
  id: string;
  supermarket_id: number;
  assigned_items: number;
  assigned_to: number;
  status: string;
}

export interface IStagingDispatchAssignmentListPayload extends IStagingDispatchAssignmentListData {
  [index: string]: string | number;
}

export enum StagingDispatchAssignmentGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  SUPERMARKET_ID = 'supermarket_id',
  STATUS = 'status',
  ASSIGNED_ITEMS = 'assigned_items',
  ASSIGNED_TO = 'assigned_to'
}

export enum StaggerJobStatusEnum {
  PENDING = 'pending',
  PICKED = 'picked',
  DROPPED = 'dropped'
}

export const staggerJobStatusOptions: ICommonOption<StaggerJobStatusEnum>[] = [
  {
    id: StaggerJobStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: StaggerJobStatusEnum.PICKED,
    name: 'Picked'
  },
  {
    id: StaggerJobStatusEnum.DROPPED,
    name: 'Dropped'
  }
];
