import { DELIVERY_TYPE } from './delivery-type.interface';
import { IPaymentOption } from './payment-option.interface';

export interface IWarehousePayment {
  id?: number;
  name?: string;
  warehouse_id?: number;
  payment_id?: number;
  delivery_type?: DELIVERY_TYPE;
  payment_option?: IPaymentOption;
}
