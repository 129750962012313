import { DeepPartial } from 'typeorm';
import { ICity } from '../location/city.interface';
import { IBaseAudit } from '../auditable.interface';
import { ISuperMarket, SupermarketTypeEnum } from '../supermarket/super-market.interface';
import { IDeliveryOption } from '../delivery-option.interface';
import { ICommonOption, ISort, BaseSelect, LocaleEnum } from '../common.interface';
import { IAddress } from '../location/address.interface';
import { IPaymentOption } from '../payment-option.interface';
import { IUser, IUserToken } from '../user.interface';
import { IOrderCancellationReason } from './order-cancellation-reason.interface';
import { IOrderShipment } from './order-shipment.interface';
import { IOrderProduct, OrderProductReplacementPrefEnum } from './order-product.interface';
import { ILanguage } from '../language.interface';
import { ITimeSlot } from '../supermarket/time-slot.interface';
import { IDeliveryType } from '../delivery-type.interface';
import { ITaxation } from '../product/taxation.interface';
import { ICountry } from '../location/country.interface';
import { ICommonEmailData, INotificationCommonEmailData } from '../notification.interface';
import { IWmsStockMaster } from '../warehouse-management/wms-stock-management.interface';
import { IGrpcInventoryModifierStock } from '../product/inventory-modifiers.interface';
import { IValidateOrderItem } from '../product/cart.interface';
import { IOrderPayment, IOrderPaymentRefund } from './order-payment.interface';
import { IPromotion, IPromotionActions, IPromotionValidationData } from '../promotion.interface';
import {
  IProduct,
  IProductIndexAndId,
  IProductLocationAndStockCsv,
  IProductTaxonIds
} from '../product/product.interface';
import { IOrderAdjustment } from './order-adjustment.interface';
import { IOrderAddress } from './order-address.interface';
import { ICheckoutCard } from '../checkout.interface';
import { IUploadValidate } from '../bulk-upload.interface';
import { ICashback } from './cashback.interface';
import { IEmployeeData } from '../employee.interface';
import { IGetLoyaltyRes, IValusPostRedemptionRes } from '../loyalty-management/loyalty-management-system.interface';
import { IStoreCredit, IStoreCreditTransaction } from './store-credit.interface';
import { IProductVariant } from '../product/product-variant.interface';
import { IIdMapping } from '../key-mapping.interface';

export interface IOrder extends IBaseAudit {
  item_total: number;
  total: number;
  status: OrderStatusEnum;
  user_id: number;
  country_id: number;
  city_id: number;
  payment_total?: number;
  currency: string;
  item_count: number;
  delivery_option_id: number;
  payment_option_id: number;
  language_id: number;
  bill_address_id?: number;
  ship_address_id?: number;
  user_card_id?: number;
  completed_at?: Date;
  cancelled_by?: number;
  cancelled_at?: Date;
  order_cancellation_reason_id?: number;
  order_cancellation_comment?: string;
  order_shipments?: IOrderShipment[];
  order_payments?: IOrderPayment[];
  supermarkets?: ISuperMarket[];
  city?: ICity;
  delivery_option?: IDeliveryOption;
  delivery_type?: IDeliveryType;
  user?: IUser;
  address?: IOrderAddress;
  payment_option?: IPaymentOption;
  order_cancellation_reason?: IOrderCancellationReason;
  language?: ILanguage;
  order_products?: IOrderProduct[];
  order_payment_refund?: IOrderPaymentRefund;
  order_adjustments?: IOrderAdjustment[];
  order_addresses?: IOrderAddress[];
  cashbacks?: ICashback[];
  total_summary?: ITotalSummary;
}

export interface ITotalSummary {
  delivery_charges?: number;
  total_tax?: number;
  store_credit?: number;
  store_credit_used?: number;
  loyalty_points_earned?: number;
}

export interface ILatestOrders {
  latest_order_id: number;
  new_order_ids: number[];
}

export interface ICustomerOrderList extends IOrder {
  is_store_pickup: boolean;
}

export interface IOrderList extends IOrder {
  supermarkets_grid?: ISuperMarket[];
}

export interface IOrderListData extends ISort {
  page: number;
  id: string;
  user_name: string;
  user_id: number;
  created_at: string;
  delivery_option_id: number;
  status: string;
  supermarket_id: number;
  city_id: number;
}

export interface ILatestOrderData {
  last_order_id: string;
}

export interface IOrderStatus {
  name: string;
}

export interface IOrderListPayload extends IOrderListData {
  [index: string]: string | number;
}

export interface ILatestOrderPayload extends ILatestOrderData {
  [index: string]: string;
}

export interface IOrderRedisData {
  list: IOrder[];
  totalCount: number;
}

export interface IGrpcAddOrderShipmentUserRequest {
  supermarket_id: number;
  delivery_type_id: number;
  delivery_date: Date;
  time_slot_id?: number;
  total?: number;
  delivery_charges?: number;
  order_products?: IGrpcOrderProduct[];
}

export interface IGrpcOrderDetailsForPromotionsUserReq {
  user_id: number;
  user_card_id: number;
  order_shipments: IGrpcAddOrderShipmentUserRequest[];
}

export interface IGrpcAddOrderUserRequest {
  user_id: number;
  language_id: number;
  bill_address_id: number;
  delivery_option_id: number;
  payment_option_id: number;
  user_card_id: number;
  order_shipments: IGrpcAddOrderShipmentUserRequest[];
  ship_address_id?: number;
}

export interface IGrpcAddOrderShipmentUserResponse {
  supermarket_id: number;
  delivery_capacity: number;
  supermarket_type: SupermarketTypeEnum;
  time_slot_id: number;
  processing_start_at: Date;
  formatted_time_slot: string;
}

export interface IGrpcAddOrderUserResponse {
  order_shipments: IGrpcAddOrderShipmentUserResponse[];
  country: ICountry;
  city_id: number;
  card_instrument_id: string;
  scheme_local: string;
  is_cod_order: boolean;
  order_contains_gift_cards: boolean;
  bin?: string;
  promotion_validation_data?: IPromotionValidationData;
}

export interface IGrpcShipmentSupermarketsUserResponse {
  supermarket_id: number;
  supermarket_type: SupermarketTypeEnum;
}

export interface IGrpcOrderDetailsForPromotionsUserRes {
  order_shipments: IGrpcShipmentSupermarketsUserResponse[];
  country: ICountry;
  bin?: string;
  promotion_validation_data?: IPromotionValidationData;
}

export interface IGrpcOrderReplacementProduct {
  product_id: number;
}

export interface IGrpcOrderProduct {
  product_id: number;
  supermarket_id?: number;
  product_variant_id?: number;
  category_id: number;
  quantity: number;
  price: number;
  action_price: number;
  sale_price: number;
  supermarket_type?: SupermarketTypeEnum;
  replacement_pref?: OrderProductReplacementPrefEnum;
  stock?: IWmsStockMaster;
  order_replacement_products?: IGrpcOrderReplacementProduct[];
}

export interface IGrpcOrderShipmentProduct {
  supermarket_id: number;
  supermarket_type?: SupermarketTypeEnum;
  order_products: IGrpcOrderProduct[];
}

export interface IGrpcAddOrderProductRequest {
  order_shipments: IGrpcOrderShipmentProduct[];
  stocks: IWmsStockMaster[];
  country_id: number;
  time_zone: string;
  is_cod_order: boolean;
  user_id: number;
  product_ids: number[];
}

export interface IGrpcOrderDetailsForPromotionsProductReq {
  order_shipments: IGrpcOrderShipmentProduct[];
  country_id: number;
  time_zone: string;
  product_ids: number[];
}

export interface IGrpcAddOrderProductResponse {
  taxations: ITaxation[];
  validate_order_items: IValidateOrderItem[];
  promotions?: IPromotion[];
  product_taxons?: IProductTaxonIds[];
  products?: Partial<IProduct[]>;
}

export interface IGrpcOrderDetailsForPromotionsProductRes {
  taxations: ITaxation[];
  promotions?: IPromotion[];
  product_taxons?: IProductTaxonIds[];
}

export interface IGrpcOrderSummaryRequest {
  userId?: number;
  cityIds?: number[];
  superMarketIds?: number[];
  addressId?: number;
  paymentOptionId?: number;
  languageId?: number;
  timeSlotIds?: number[];
  pickerIds?: number[];
}

export interface IGrpcOrderRequest {
  cityIds?: number[];
  superMarketIds?: number[];
  userIds?: number[];
  timeSlotIds?: number[];
  driverIds?: number[];
}

export interface IGrpcOrderDetailResponse {
  deliveryOption?: IDeliveryOption[];
  delivery_type?: IDeliveryType[];
  superMarket?: ISuperMarket[];
  city?: ICity[];
  users?: IUser[];
  time_slots?: ITimeSlot[];
  drivers?: IEmployeeData[];
}

export interface IGrpcOrderSummaryDetailResponse extends IGrpcOrderDetailResponse {
  user?: IUser;
  address?: IAddress;
  language?: ILanguage;
  paymentOption?: IPaymentOption;
  timeSlots?: ITimeSlot[];
  pickers: IEmployeeData[];
}

export interface IGrpcGetOrderProductRequest {
  productIds: number[];
  variantIds?: number[];
}

export interface IGrpcGetOrdersByUserIdsRes {
  orders: IOrder[];
}

export interface IOrderConfirmationEmail extends ICommonEmailData {
  order_id: number;
  mobile_number: string;
  user_mail: string;
  total: number;
  order?: IOrder;
  products?: IProduct[];
  variants?: IProductVariant[];
  taxations?: ITaxation[];
  payment_options?: IPaymentOption[];
  points?: number;
  checkout_card_detail: ICheckoutCard;
  is_order_completion_mail?: boolean;
  is_order_modification_mail?: boolean;
  super_markets: ISuperMarket[];
  loyalty_member_details: IGetLoyaltyRes;
}

export interface IOrderCancellationEmail extends ICommonEmailData {
  order_id: number;
  reason_language_list?: IOrderCancellationReason;
  comment: string;
  order?: IOrder;
  products?: IProduct[];
  taxations?: ITaxation[];
  payment_options?: IPaymentOption[];
  points?: number;
  checkout_card_detail: ICheckoutCard;
}

export interface IOrderModificationEmail extends ICommonEmailData {
  order_id: number;
  product_id: number;
  operation: string;
}

export interface IOrderCancellationAdminPayload {
  order_cancellation_reason_id: number;
  order_cancellation_comment?: string;
}

export interface IOrderDetailCommonEmailContent {
  header_2: string;
  content_2_item_1: string;
  content_2_item_2: string;
  content_2_item_3: string;
  content_2_item_4: string;
  content_2_item_5: string;
  delivery_shipment_content: string;
  sub_total_content: string;
  sub_total_items_content: string;
  sub_total_item_content: string;
  no_shipments_content: string;
  tax_total_content: string;
  delivery_fee_content: string;
  total_content: string;
  order_summary_title_content: string;
  shipment_summary_title_content: string;
  delivery_comments_title_content: string;
  payment_title_content: string;
  footer_content_1_item_1: string;
  footer_content_1_item_2: string;
  footer_content_1_item_3: string;
  footer_content_1_item_4: string;
  footer_content_1_item_5: string;
  footer_content_1_item_6: string;
  footer_content_1_item_7: string;
  footer_content_1_item_8: string;
  footer_content_1_item_11: string;
  footer_content_1_item_12: string;
  footer_content_1_item_13: string;
  cash_coupon_content: string;
  promotion_label?: string;
}

export interface IOrderDetailInvoiceCommonEmailContentEn {
  trn_en?: string;
  crn_en?: string;
  company_legal_name_en?: string;
  company_legal_address_en?: string;
  invoice_tax_header_en?: string;
  invoice_order_header_en?: string;
  invoice_invoice_header_en?: string;
  invoice_order_date_header_en?: string;
  invoice_date_header_en?: string;
  invoice_exp_delivery_date_header_en?: string;
  invoice_customer_information_en?: string;
  invoice_store_information_en?: string;
  invoice_final_summary_text_en?: string;
  refund_information_content_en?: string;
  invoice_sub_total_text_en?: string;
  invoice_delivery_charge_vat_text_en?: string;
  invoice_total_amount_vat_text_en?: string;
  invoice_vat_text_en?: string;
  invoice_total_amount_text_en?: string;
  invoice_vat_amount_text_en?: string;
  invoice_description_header_en?: string;
  invoice_ordered_qty_header_en?: string;
  invoice_delivered_qty_header_en?: string;
  invoice_unit_price_vat_header_en?: string;
  invoice_unit_price_header_en?: string;
  invoice_total_price_header_en?: string;
  invoice_vat_rate_header_en?: string;
  invoice_vat_rate_amount_header_en?: string;
  invoice_promotions_header_en?: string;
  invoice_total_price_vat_header_en?: string;
}

export interface IOrderDetailInvoiceCommonEmailContentAr {
  trn_ar?: string;
  crn_ar?: string;
  company_legal_name_ar?: string;
  company_legal_address_ar?: string;
  invoice_tax_header_ar?: string;
  invoice_order_header_ar?: string;
  invoice_invoice_header_ar?: string;
  invoice_order_date_header_ar?: string;
  invoice_date_header_ar?: string;
  invoice_exp_delivery_date_header_ar?: string;
  invoice_customer_information_ar?: string;
  invoice_store_information_ar?: string;
  invoice_final_summary_text_ar?: string;
  refund_information_content_ar?: string;
  invoice_sub_total_text_ar?: string;
  invoice_delivery_charge_vat_text_ar?: string;
  invoice_total_amount_vat_text_ar?: string;
  invoice_vat_text_ar?: string;
  invoice_total_amount_text_ar?: string;
  invoice_vat_amount_text_ar?: string;
  invoice_description_header_ar?: string;
  invoice_ordered_qty_header_ar?: string;
  invoice_delivered_qty_header_ar?: string;
  invoice_unit_price_vat_header_ar?: string;
  invoice_unit_price_header_ar?: string;
  invoice_total_price_header_ar?: string;
  invoice_vat_rate_header_ar?: string;
  invoice_vat_rate_amount_header_ar?: string;
  invoice_promotions_header_ar?: string;
  invoice_total_price_vat_header_ar?: string;
}

export interface IOrderConfirmationEmailContent extends INotificationCommonEmailData, IOrderDetailCommonEmailContent {
  header_1: string;
  header_3: string;
  club_point_title_content: string;
  club_point_points_content: string;
  club_point_description_content: string;
  footer_content_1_item_10: string;
  invoice_template_en?: IOrderDetailInvoiceCommonEmailContentEn;
  invoice_template_ar?: IOrderDetailInvoiceCommonEmailContentAr;
}

export interface IOrderCancellationEmailContent extends INotificationCommonEmailData, IOrderDetailCommonEmailContent {
  header_1: string;
  header_3: string;
  club_point_title_content: string;
  club_point_points_content: string;
  club_point_description_content: string;
  cancellation_reason_content: string;
  refund_information_title: string;
  refund_information_content_1: string;
  refund_information_content_2: string;
  refund_information_content_3: string;
  refund_information_content_4: string;
  contact_support_button: string;
  footer_content_1_item_9: string;
  shipment_status_content: string;
  shipment_status_data: string;
}

export interface IOrderShipmentDeliveryEmail extends ICommonEmailData {
  order: IOrder;
  products: IProduct[];
  taxations: ITaxation[];
}

export interface IOrderShipmentDeliveryEmailContent
  extends INotificationCommonEmailData,
    IOrderDetailCommonEmailContent {
  subject: string;
  content_1: string;
  header_1: string;
  header_3: string;
  content_2: string;
  footer_content_1_item_10: string;
}

export interface IOrderCache {
  order: IOrder;
  orderAdjustment: Partial<IOrderAdjustment>;
  orderCashback: Partial<ICashback>;
  orderPayments: Partial<IOrderPayment>[];
  updatedStoreCredits: IStoreCredit[];
  storeCreditTransactions: IStoreCreditTransaction[];
  user_token_data: IUserToken;
  locale: LocaleEnum;
  wms_stock_masters: IWmsStockMaster[];
  inventory_modifiers_stock: IGrpcInventoryModifierStock[];
  postRedemptionValusResp: IValusPostRedemptionRes;
  applied_promotion_id?: number;
}

export interface IOrderAdjustmentsAndDiscountTotal {
  orderAdjustments: Partial<IOrderAdjustment>[];
  shipmentsWithAdjustmentValues: DeepPartial<IOrderShipment>[];
  discountAmount: number;
  freeShippingAmount: number;
  freeShippingSupermarketIds: number[];
}

export interface ISaveOrderByPaymentIdReq {
  payment_id: string;
  user_id: number;
  amount: number;
  currency: string;
  status: string;
  approved: boolean;
}

export interface ISaveOrderByPaymentIdRes {
  order_id: number;
}

export interface IGrpcCancelOrderReq {
  supermarket_ids: number[];
  country_id: number;
  payment_option_id: number;
  user_id: number;
  user_card_id?: number;
}

export interface IGrpcCancelOrderRes {
  supermarkets: ISuperMarket[];
  country: ICountry;
  payment_option: IPaymentOption;
  user: IUser;
}

export interface IGrpcGetConsumerOrdersReq {
  user_id: number;
}

export interface IGrpcGetConsumerOrdersRes {
  orders: IOrder[];
}

export interface IValidateOrderProductTaxon {
  productId: number;
  isPromotionTaxonsAddedInProduct: boolean;
  itemTotal: number;
}

export interface IGrpcUnassignOrderPickingBatchesOrBinsAndProductsReq {
  employee_id: number;
  roles: string[];
}

export interface IGrpcUserOrderStatsRes {
  orderTotal: number;
  latestLangId: number;
  totalOrders: number;
  averageOrderValue: number;
  currency: string;
}

export interface IGrpcAddLocationAndStockLocationReq {
  productIndexAndIds: IProductIndexAndId[];
  supermarket_id: number;
  zone_id: number;
  list: IProductLocationAndStockCsv[];
}

export interface IGrpcAddLocationAndStockLocationRes {
  success: boolean;
}

export interface IGrpcCheckLocationsExistsReq {
  supermarket_id: number;
  zone_id: number;
  list: IProductLocationAndStockCsv[];
  productIndexAndIds: IProductIndexAndId[];
  resultArrays: IUploadValidate<IProductLocationAndStockCsv>[];
}

export interface IGrpcCheckLocationsExistsRes {
  resultArray: IUploadValidate<IProductLocationAndStockCsv>[];
}

export interface IOrderDetailsForEmailTemplate {
  order_detail: IOrder;
  payment_options: IPaymentOption[];
  products: IProduct[];
  variants: IProductVariant[];
  taxations: ITaxation[];
  checkout_card_detail: ICheckoutCard;
  supermarkets: ISuperMarket[];
  loyalty_member_details: IGetLoyaltyRes;
}

export interface IShipmentDetailsForDeliveryEmailTemplate {
  products: IProduct[];
  taxations: ITaxation[];
}

export interface ISerializedOrderShipmentRequest {
  addOrderUserGrpcRequest: IGrpcAddOrderUserRequest;
  shipmentsOfExistingOrders: IOrderShipment[];
}

export interface IGeneratedOrderDetailsAndAdjustments {
  orderShipments: DeepPartial<IOrderShipment>[];
  orderTotal: number;
  appliedPromotionId: number;
  cashback: Partial<ICashback>;
  orderAdjustment: Partial<IOrderAdjustment>;
}

export interface IGeneratedOrderShipmentsAndTotals {
  orderShipments: DeepPartial<IOrderShipment>[];
  giftCardExcludedOrderItemsTotal: number;
  orderGiftCardsItemTotal: number;
  totalDeliveryCharges: number;
  shipmentsDeliveryChargesMap: IIdMapping<number>;
}

export interface IAppliedPromotion {
  promotionAmount: number;
  promotionAction: IPromotionActions;
  cashback: Partial<ICashback>;
  orderAdjustment: Partial<IOrderAdjustment>;
  freeShippingSupermarketIds: number[];
}

export interface IApplicablePromotionConsumer {
  promotionId: number;
  promotionAmount: number;
  promotionAction: IPromotionActions;
}

export interface IOrderShipmentTotalsMap {
  shipmentId: number;
  shipmentTotalDiff?: number;
  shipmentPriceDiff?: number;
  shipmentTotalPrice?: number;
}

export interface IOrder3dsRedirectionRes {
  redirection_url: string;
}

export interface IGetAvayaCustomerRes {
  phone_number: string;
}

export type CreateOrderResponseType = IOrder | IOrder3dsRedirectionRes | IValidateOrderItem[];

export enum OrderStatusEnum {
  PENDING = 'pending',
  ADMIN_CANCELLED = 'admin-cancelled',
  CUSTOMER_CANCELLED = 'customer-cancelled',
  PARTIALLY_CANCELLED = 'partially-cancelled',
  PARTIALLY_COMPLETED = 'partially-completed',
  COMPLETED = 'completed'
}

export enum OrderGridFilterEnum {
  ID = 'id',
  USER_NAME = 'user_name',
  CREATED_AT = 'created_at',
  DELIVERY_OPTION_ID = 'delivery_option_id',
  STATUS = 'status',
  SUPERMARKET_ID = 'supermarket_id',
  CITY_ID = 'city_id',
  SORT_BY = 'sortBy',
  SORT = 'sort'
}

export const orderStatusOptionsList: ICommonOption<OrderStatusEnum>[] = [
  {
    id: OrderStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: OrderStatusEnum.ADMIN_CANCELLED,
    name: 'Admin Cancelled'
  },
  {
    id: OrderStatusEnum.CUSTOMER_CANCELLED,
    name: 'Customer Cancelled'
  },
  {
    id: OrderStatusEnum.PARTIALLY_CANCELLED,
    name: 'Partially Cancelled'
  },
  {
    id: OrderStatusEnum.PARTIALLY_COMPLETED,
    name: 'Partially Completed'
  },
  {
    id: OrderStatusEnum.COMPLETED,
    name: 'Completed'
  }
];

export const orderSelect = {
  id: true,
  user_id: true,
  created_at: true,
  delivery_option_id: true,
  status: true,
  city_id: true,
  order_shipments: {
    id: true,
    supermarket_id: true,
    delivery_type_id: true,
    delivery_date: true,
    status: true
  }
};

export const orderDetailSelect = {
  id: true,
  item_total: true,
  total: true,
  status: true,
  user_id: true,
  country_id: true,
  city_id: true,
  currency: true,
  item_count: true,
  delivery_option_id: true,
  payment_option_id: true,
  language_id: true,
  completed_at: true,
  cancelled_by: true,
  cancelled_at: true,
  created_at: true,
  order_cancellation_reason_id: true,
  order_cancellation_comment: true,
  order_products: {
    id: true,
    order_shipment_id: true,
    product_id: true,
    category_id: true,
    quantity: true,
    returned_quantity: true,
    replacement_pref: true,
    price: true,
    delivery_instruction: true,
    status: true,
    picker_id: true,
    product_variant_id: true,
    picked_quantity: true,
    picked_weight: true,
    created_at: true,
    updated_at: true,
    replaced_by: true,
    replacement_of: true,
    product_report: {
      id: true,
      comments: true,
      images: {
        id: true,
        image_url: true
      },
      product_report_reason: {
        id: true,
        name: true
      }
    },
    order_shipment: {
      id: true,
      supermarket_id: true
    },
    order_replacement_products: {
      order_product_id: true,
      product_id: true
    }
  },
  order_shipments: {
    id: true,
    total: true,
    delivery_charges: true,
    tax_total: true
  },
  order_payment_refund: {
    amount: true,
    status: true
  },
  order_addresses: {
    type: true,
    latitude: true,
    longitude: true,
    country: true,
    district: true,
    city: true,
    area: true,
    street_no: true,
    postal_code: true,
    formatted_address: true,
    is_verified: true,
    delivery_instruction: true
  },
  order_adjustments: {
    id: true,
    amount: true,
    label: true
  }
};

export const orderDetailCustomerSelect = {
  id: true,
  total: true,
  status: true,
  currency: true,
  item_count: true,
  delivery_option_id: true,
  payment_option_id: true,
  created_at: true,
  cancelled_at: true,
  country_id: true,
  user_card_id: true,
  order_shipments: {
    id: true,
    supermarket_id: true,
    delivery_type_id: true,
    delivery_date: true,
    total: true,
    tax_total: true,
    status: true,
    delivered_at: true,
    delivery_charges: true,
    time_slot_id: true,
    formatted_time_slot: true,
    order_products: {
      id: true,
      order_shipment_id: true,
      product_id: true,
      category_id: true,
      replacement_of: true,
      quantity: true,
      picked_quantity: true,
      picked_weight: true,
      price: true,
      action_price: true,
      sale_price: true,
      delivery_instruction: true,
      product_variant_id: true,
      product_report_id: true,
      created_at: true,
      status: true,
      replaced_by: true,
      loyalty_point: true,
      returned_quantity: true
    }
  },
  order_addresses: {
    type: true,
    latitude: true,
    longitude: true,
    country: true,
    district: true,
    city: true,
    area: true,
    street_no: true,
    postal_code: true,
    formatted_address: true,
    is_verified: true,
    delivery_instruction: true
  },
  order_adjustments: {
    id: true,
    order_id: true,
    amount: true,
    label: true,
    source_type: true,
    source_id: true
  },
  order_payments: {
    id: true,
    source_id: true,
    source_type: true,
    amount: true,
    currency: true,
    payment_option_id: true
  }
};

export const orderProductDetailsSelect = {
  id: true,
  order_shipment_id: true,
  product_id: true,
  category_id: true,
  quantity: true,
  status: true,
  picker_id: true,
  picked_quantity: true,
  returned_quantity: true
};

export const orderUserSelect = {
  first_name: true,
  last_name: true,
  gender: true,
  email: true,
  country_code: true,
  mobile_number: true
};

export const orderLanguageSelect = {
  ...BaseSelect,
  code: true
};

export const orderCustomerListSelect = {
  id: true,
  created_at: true,
  total: true,
  status: true,
  currency: true,
  item_count: true,
  delivery_option_id: true,
  order_shipments: {
    id: true,
    order_id: true,
    time_slot_id: true,
    formatted_time_slot: true,
    status: true,
    delivery_date: true,
    order_products: {
      id: true,
      order_shipment_id: true,
      product_id: true,
      product_variant_id: true,
      quantity: true,
      status: true
    }
  }
};

export const orderShipmentListSelect = {
  id: true,
  order_id: true,
  supermarket_id: true,
  delivery_type_id: true,
  delivery_date: true,
  total: true,
  tax_total: true,
  status: true,
  driver_id: true,
  delivered_at: true,
  delivery_charges: true,
  time_slot_id: true,
  formatted_time_slot: true
};

export const deliveryBatchOrderShipmentSelect = {
  id: true,
  driver_id: true,
  order: {
    id: true,
    payment_option_id: true,
    order_addresses: {
      id: true,
      is_bill_address: true,
      district: true
    }
  },
  order_products: {
    id: true,
    product_id: true,
    quantity: true
  }
};

export const consumerOrdersSelect = {
  id: true,
  user_id: true,
  status: true,
  order_adjustments: {
    id: true,
    source_id: true,
    source_type: true,
    adjustable_type: true
  }
};

export const orderCheckoutCardSelect = {
  id: true,
  user_id: true,
  user_card_id: true,
  language_id: true,
  total: true,
  currency: true
};

export const orderPickingShipmentBinProductSelect = {
  id: true,
  order_product_id: true,
  order_picking_shipment_bin: {
    id: true,
    bin_id: true,
    created_at: true
  }
};

export const orderSummarySelect = {
  id: true,
  total: true,
  status: true,
  completed_at: true
};

export const orderSortKeys: string[] = [
  'id',
  'created_at',
  'delivery_date',
  'city_id',
  'user_id',
  'status',
  'delivery_option_id',
  'delivery_time'
];

export const orderErrorCodes = {
  CALCULATION_ERROR: 600,
  OUT_OF_CAPACITY_ERROR: 601,
  OUT_OF_STOCK_ERROR: 603
};
