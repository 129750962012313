import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { constants } from '@ecommerce/common-types';

@Component({
  selector: 'ecomm-image-uploader',
  templateUrl: './image-uploader.component.html'
})
export class ImageUploaderComponent implements OnChanges {
  @Input() public title = '';
  @Input() public imageUrl = '';
  @Input() public height = constants.DEFAULT_IMAGE_HEIGTH;
  @Input() public acceptFileTypes = 'image/png, image/webp, image/jpeg';
  @Input() public requiredError = false;
  @Input() public resetUploader = false;
  @Output() public selectFile: EventEmitter<File> = new EventEmitter();
  @Output() public removeFile: EventEmitter<void> = new EventEmitter();
  public selectedImageUrl: string | ArrayBuffer | null = null;
  public uploadErrorMsg = '';
  public fileSelected = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['resetUploader']?.currentValue) this.reset();
  }

  public async onFileSelect(files: File[]): Promise<void> {
    const file: File = files[0];
    this.uploadErrorMsg = '';
    this.fileSelected = true;
    let isFileValid = true;

    if (!this.acceptFileTypes.includes(file.type)) {
      isFileValid = false;
      this.uploadErrorMsg = constants.INVALID_FILE;
    }

    if (isFileValid && file) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event): void => {
        if (event.target) this.selectedImageUrl = event.target.result;
      };

      this.selectFile.emit(file);
    }
  }

  public onFileRemove(): void {
    this.reset();
    this.removeFile.emit();
  }

  public reset(): void {
    this.fileSelected = false;
    this.imageUrl = '';
    this.selectedImageUrl = '';
  }
}
