import { IAssignedJobsCount } from './common.interface';
import { IEmployeeData } from './employee.interface';

export interface IPickerAssignedJobs {
  express_order: IAssignedJobsCount;
  order_picking: IAssignedJobsCount;
  putaway: IAssignedJobsCount;
}

export interface IWmsPickerWithJobsCount {
  picker: IEmployeeData;
  putaway_jobs_count: number;
  order_picking_jobs_count: number;
  batch_pick_jobs_count: number;
}

interface IWmsPickerWithJobsCountData {
  supermarket_id: number;
  page: number;
  name: string;
  availability: string;
}

export interface IWmsPickerWithJobsCountListPayload extends IWmsPickerWithJobsCountData {
  [index: string]: string | number;
}

export interface IGrpcGetAvailablePickersReq {
  supermarket_ids: number[];
}

export interface IGrpcGetAvailablePickersRes {
  pickers: IEmployeeData[];
}
