import { IDeliveryType } from '../delivery-type.interface';
import { ISuperMarket } from './super-market.interface';
import { BaseSelect } from './../common.interface';

export interface ISuperMarketDeliveryRegion {
  id?: number;
  supermarket_id?: number;
  delivery_type_id: number;
  delivery_region: string;
  delivery_area?: string;
  center_latitude?: number;
  center_longitude?: number;
  fill_color?: string;
  fill_color_opacity?: number;
  border_color?: string;
  border_width?: number;
  supermarket?: ISuperMarket;
  city_id?: number;
  delivery_type?: IDeliveryType;
}

export interface IDeliveryRegionListPayload {
  city_id?: number;
  delivery_type_id?: number;
  supermarket_id?: number;
}

export const deliveryRegionSelect = {
  id: true,
  delivery_type_id: true,
  center_latitude: true,
  center_longitude: true,
  delivery_region: true,
  fill_color: true,
  fill_color_opacity: true,
  border_color: true,
  border_width: true,
  supermarket: {
    ...BaseSelect
  }
};
