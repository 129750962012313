import { IAuditable } from '../auditable.interface';
import { IProduct, productListSelect } from './product.interface';

export interface IWishlist extends IAuditable {
  user_id?: number;
  product_id?: number;
  product?: IProduct;
}

export const wishlistSelect = {
  id: true,
  is_deleted: true,
  product: productListSelect
};
