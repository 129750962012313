export enum OrderQueueMessagePatternEnum {
  PURCHASE_GIFT_CARD = 'purchase_gift_card',
  ACTIVATE_GIFT_CARD = 'activate_gift_card',
  POST_TRANSACTIONS = 'post_transactions',
  CANCEL_REDEMPTION = 'cancel_redemption',
  POST_REDEMPTION = 'post_redemption',
  ORDER_COMPLETED = 'order_completed',
  SHIPMENT_COMPLETED = 'shipment_completed',
  REPORT_ZATCA_INVOICE = 'report_zatca_invoice',
  UPDATE_ALGOLIA_STOCK_MASTERS = 'update_algolia_stock_masters'
}
