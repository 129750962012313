export default {
  WELCOME_VERIFICATION_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'welcome_verification_template_subject',
    HEADER_1: 'welcome_verification_template_header_1',
    HEADER_2: 'welcome_verification_template_header_2',
    CONTENT_1: 'welcome_verification_template_content_1',
    CONTENT_2: 'welcome_verification_template_content_2',
    VERIFY_EMAIL_BUTTON: 'welcome_verification_template_verify_email_button'
  },
  WELCOME_VERIFIED_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'welcome_verified_template_subject',
    HEADER_1: 'welcome_verified_template_header_1',
    HEADER_2: 'welcome_verified_template_header_2',
    CONTENT_1: 'welcome_verified_template_content_1',
    CONTENT_2: 'welcome_verified_template_content_2'
  },
  COMMON_ORDER_EMAIL_CONTENT_KEYS: {
    HEADER_2: 'order_detail_template_header_2',
    CONTENT_2_ITEM_1: 'order_detail_template_content_2_item_1',
    CONTENT_2_ITEM_2: 'order_detail_template_content_2_item_2',
    CONTENT_2_ITEM_3: 'order_detail_template_content_2_item_3',
    CONTENT_2_ITEM_4: 'order_detail_template_content_2_item_4',
    CONTENT_2_ITEM_5: 'order_detail_template_content_2_item_5',
    DELIVERY_SHIPMENT_CONTENT: 'order_detail_template_delivery_shipment_content',
    SUB_TOTAL_CONTENT: 'order_detail_template_sub_total_content',
    SUB_TOTAL_CONTENT_ITEM: 'order_detail_template_sub_total_item_content',
    SUB_TOTAL_CONTENT_ITEMS: 'order_detail_template_sub_total_items_content',
    NO_SHIPMENTS_CONTENT: 'order_detail_template_no_shipments_content',
    TAX_TOTAL_CONTENT: 'order_detail_template_tax_total_content',
    DELIVERY_FEE_CONTENT: 'order_detail_template_delivery_fee_content',
    CASH_COUPON_CONTENT: 'order_detail_template_cash_coupon_content',
    TOTAL_CONTENT: 'order_detail_template_total_content',
    ORDER_SUMMARY_TITLE_CONTENT: 'order_detail_template_order_summary_content',
    SHIPMENT_SUMMARY_TITLE_CONTENT: 'order_detail_template_shipment_summary_content',
    DELIVERY_COMMENTS_TITLE_CONTENT: 'order_detail_template_delivery_comments_content',
    PAYMENT_TITLE_CONTENT: 'order_detail_template_content_payment_title',
    FOOTER_CONTENT_1_ITEM_1: 'order_detail_template_footer_content_1_item_1',
    FOOTER_CONTENT_1_ITEM_2: 'order_detail_template_footer_content_1_item_2',
    FOOTER_CONTENT_1_ITEM_3: 'order_detail_template_footer_content_1_item_3',
    FOOTER_CONTENT_1_ITEM_4: 'order_detail_template_footer_content_1_item_4',
    FOOTER_CONTENT_1_ITEM_5: 'order_detail_template_footer_content_1_item_5',
    FOOTER_CONTENT_1_ITEM_6: 'order_detail_template_footer_content_1_item_6',
    FOOTER_CONTENT_1_ITEM_7: 'order_detail_template_footer_content_1_item_7',
    FOOTER_CONTENT_1_ITEM_8: 'order_detail_template_footer_content_1_item_8',
    FOOTER_CONTENT_1_ITEM_11: 'order_detail_template_footer_content_1_item_11',
    FOOTER_CONTENT_1_ITEM_12: 'order_detail_template_footer_content_1_item_12',
    FOOTER_CONTENT_1_ITEM_13: 'order_detail_template_footer_content_1_item_13'
  },
  ORDER_CONFIRMATION_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'order_confirmation_template_subject',
    HEADER_1: 'order_confirmation_template_header_1',
    HEADER_3: 'order_confirmation_template_header_3',
    CLUB_POINT_TITLE_CONTENT: 'order_confirmation_template_club_point_title_content',
    CLUB_POINT_POINTS_CONTENT: 'order_confirmation_template_club_point_points_content',
    CLUB_POINT_DESCRIPTION_CONTENT: 'order_confirmation_template_club_point_description_content',
    FOOTER_CONTENT_1_ITEM_10: 'order_confirmation_template_footer_content_1_item_10'
  },
  ORDER_CANCELLATION_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'order_cancellation_template_subject',
    HEADER_1: 'order_cancellation_template_header_1',
    HEADER_3: 'order_cancellation_template_header_3',
    SHIPMENT_STATUS_CONTENT: 'order_detail_template_shipment_status_content',
    SHIPMENT_STATUS_DATA_CONTENT: 'order_detail_template_shipment_status_data_content',
    CANCELLATION_REASON_CONTENT: 'order_cancellation_template_cancellation_reason_content',
    REFUND_INFORMATION_TITLE: 'order_cancellation_template_refund_information_title_content',
    REFUND_INFORMATION_CONTENT_1: 'order_cancellation_template_refund_information_content_1',
    REFUND_INFORMATION_CONTENT_2: 'order_cancellation_template_refund_information_content_2',
    REFUND_INFORMATION_CONTENT_3: 'order_cancellation_template_refund_information_content_3',
    REFUND_INFORMATION_CONTENT_4: 'order_cancellation_template_refund_information_content_4',
    CONTACT_SUPPORT_BUTTON: 'order_cancellation_template_contact_support_button_content',
    FOOTER_CONTENT_1_ITEM_9: 'order_cancellation_template_footer_content_1_item_9'
  },
  ORDER_SHIPMENT_DELIVERY_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'order_shipment_confirmation_template_subject',
    HEADER_1: 'order_shipment_confirmation_template_header_1',
    HEADER_3: 'order_shipment_confirmation_template_header_3',
    CONTENT_1: 'order_shipment_confirmation_template_content_1',
    CONTENT_2: 'order_shipment_confirmation_template_content_2',
    FOOTER_CONTENT_1_ITEM_10: 'order_confirmation_template_footer_content_1_item_10'
  },
  ORDER_COMPLETION_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'order_completion_template_subject',
    HEADER_1: 'order_completion_template_header_1',
    HEADER_3: 'order_completion_template_header_3'
  },
  ORDER_MODIFICATION_EMAIL_CONTENT_KEYS: {
    SUBJECT: 'order_modification_template_subject',
    HEADER_1: 'order_modification_template_header_1',
    HEADER_3: 'order_modification_template_header_3'
  },
  COMMON_ORDER_INVOICE_EMAIL_EN_CONTENT_KEYS: {
    CRN_EN: 'crn',
    TRN_EN: 'trn',
    COMPANY_LEGAL_NAME_EN: 'company_legal_name',
    COMPANY_LEGAL_ADDRESS_EN: 'company_legal_address',
    INVOICE_TAX_HEADER_EN: 'invoice_tax_header',
    INVOICE_ORDER_HEADER_EN: 'invoice_order_header',
    INVOICE_INVOICE_HEADER_EN: 'invoice_invoice_header',
    INVOICE_ORDER_DATE_HEADER_EN: 'invoice_order_date_header',
    INVOICE_INVOICE_DATE_EN: 'invoice_date_header',
    INVOICE_EXP_DELIVERY_DATE_HEADER_EN: 'invoice_exp_delivery_date_header',
    INVOICE_CUSTOMER_INFORMATION_EN: 'invoice_customer_information',
    INVOICE_STORE_INFORMATION_EN: 'invoice_store_information',
    INVOICE_FINAL_SUMMARY_TEXT_EN: 'invoice_final_summary_text',
    REFUND_INFORMATION_CONTENT_EN: 'order_completion_refund_template',
    INVOICE_SUB_TOTAL_HEADER_EN: 'order_completion_sub_total_template',
    INVOICE_DELIVERY_CHARGE_VAT_HEADER_EN: 'order_completion_delivery_charge_vat_template',
    INVOICE_TOTAL_AMOUNT_VAT_HEADER_EN: 'order_completion_total_amount_vat_template',
    INVOICE_VAT_HEADER_EN: 'order_completion_vat_template',
    INVOICE_TOTAL_AMOUNT_HEADER_EN: 'order_completion_total_amount_template',
    INVOICE_VAT_AMOUNT_HEADER_EN: 'order_completion_vat_amount_template',
    INVOICE_DESCRIPTION_HEADER_EN: 'order_completion_description_header',
    INVOICE_ORDERED_QTY_HEADER_EN: 'order_completion_ordered_qty_header',
    INVOICE_DELIVERED_QTY_HEADER_EN: 'order_completion_delivered_qty_header',
    INVOICE_UNIT_PRICE_VAT_HEADER_EN: 'order_completion_unit_price_vat_header',
    INVOICE_UNIT_PRICE_HEADER_EN: 'order_completion_unit_price_header',
    INVOICE_TOTAL_PRICE_HEADER_EN: 'order_completion_total_price_header',
    INVOICE_VAT_RATE_HEADER_EN: 'order_completion_vat_rate_header',
    INVOICE_VAT_RATE_AMOUNT_HEADER_EN: 'order_completion_vat_rate_amount_header',
    INVOICE_PROMOTIONS_HEADER_EN: 'order_completion_promotions_header',
    INVOICE_TOTAL_PRICE_VAT_HEADER_EN: 'order_completion_total_price_vat_header'
  },
  COMMON_ORDER_INVOICE_EMAIL_AR_CONTENT_KEYS: {
    CRN_AR: 'crn',
    TRN_AR: 'trn',
    COMPANY_LEGAL_NAME_AR: 'company_legal_name',
    COMPANY_LEGAL_ADDRESS_AR: 'company_legal_address',
    INVOICE_TAX_HEADER_AR: 'invoice_tax_header',
    INVOICE_ORDER_HEADER_AR: 'invoice_order_header',
    INVOICE_INVOICE_HEADER_AR: 'invoice_invoice_header',
    INVOICE_ORDER_DATE_HEADER_AR: 'invoice_order_date_header',
    INVOICE_INVOICE_DATE_AR: 'invoice_date_header',
    INVOICE_EXP_DELIVERY_DATE_HEADER_AR: 'invoice_exp_delivery_date_header',
    INVOICE_CUSTOMER_INFORMATION_AR: 'invoice_customer_information',
    INVOICE_STORE_INFORMATION_AR: 'invoice_store_information',
    INVOICE_FINAL_SUMMARY_TEXT_AR: 'invoice_final_summary_text',
    REFUND_CONTENT_AR: 'order_completion_refund_template',
    REFUND_INFORMATION_CONTENT_AR: 'order_completion_refund_template',
    INVOICE_SUB_TOTAL_HEADER_AR: 'order_completion_sub_total_template',
    INVOICE_DELIVERY_CHARGE_VAT_HEADER_AR: 'order_completion_delivery_charge_vat_template',
    INVOICE_TOTAL_AMOUNT_VAT_HEADER_AR: 'order_completion_total_amount_vat_template',
    INVOICE_VAT_HEADER_AR: 'order_completion_vat_template',
    INVOICE_TOTAL_AMOUNT_HEADER_AR: 'order_completion_total_amount_template',
    INVOICE_VAT_AMOUNT_HEADER_AR: 'order_completion_vat_amount_template',
    INVOICE_DESCRIPTION_HEADER_AR: 'order_completion_description_header',
    INVOICE_ORDERED_QTY_HEADER_AR: 'order_completion_ordered_qty_header',
    INVOICE_DELIVERED_QTY_HEADER_AR: 'order_completion_delivered_qty_header',
    INVOICE_UNIT_PRICE_VAT_HEADER_AR: 'order_completion_unit_price_vat_header',
    INVOICE_UNIT_PRICE_HEADER_AR: 'order_completion_unit_price_header',
    INVOICE_TOTAL_PRICE_HEADER_AR: 'order_completion_total_price_header',
    INVOICE_VAT_RATE_HEADER_AR: 'order_completion_vat_rate_header',
    INVOICE_VAT_RATE_AMOUNT_HEADER_AR: 'order_completion_vat_rate_amount_header',
    INVOICE_PROMOTIONS_HEADER_AR: 'order_completion_promotions_header',
    INVOICE_TOTAL_PRICE_VAT_HEADER_AR: 'order_completion_total_price_vat_header'
  }
};
