import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { IDeeplinkCsv } from '../../../index';

export async function exportProductDeeplinkCsvFile(deeplinkList: IDeeplinkCsv[], fileName: string): Promise<void> {
  const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
  const worksheet: ExcelJS.Worksheet = workbook.addWorksheet('Deeplinks');

  // Column name as a header
  const headers: string[] = Object.keys(deeplinkList[0]).map((columnName: string): string =>
    columnName.replace('_', '').toLowerCase()
  );
  worksheet.addRow(headers);

  // Add data in an array and push the array to the row in the worksheet
  deeplinkList.forEach((deeplinkData: IDeeplinkCsv): void => {
    const row: string[] = [];
    Object.keys(deeplinkData).forEach((header: string): void => {
      row.push(deeplinkData[header as keyof typeof deeplinkData] ?? '');
    });

    worksheet.addRow(row);
  });

  // Generate CSV file and save it to the download folder
  workbook.csv.writeBuffer().then((buffer: ExcelJS.Buffer): void => {
    const blob: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, `${fileName}.csv`);
  });
}
