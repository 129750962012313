import { IBaseAudit } from '../auditable.interface';
import { IDispatchJob } from './dispatch-job.interface';
import { IOrderShipment } from '../order/order-shipment.interface';
import { IWmsBin } from '../warehouse-management/wms-bin.interface';

export interface IDispatchJobReturn extends IBaseAudit {
  dispatch_job_id: number;
  order_shipment_id: number;
  bin_id?: number;
  product_id: number;
  returned_quantity: number;
  return_location_id?: number;
  driver_id: number;
  returned_at?: Date;
  verified_by?: number;
  verified_at?: Date;
  verification_comments?: string;
  verification_image_url?: string;
  processed_by?: number;
  processed_at?: Date;
  processing_comments?: string;
  processing_image_url?: string;
  damaged_quantity?: number;
  fit_quantity?: number;
  dispatch_job?: IDispatchJob;
  order_shipment?: IOrderShipment;
  bin?: IWmsBin;
}
