import { IAuditable } from './auditable.interface';

export interface ILogin extends IAuditable {
  email: string;
  user_id: number;
  first_name: string;
  last_name: string;
  country_code: string;
  mobile_number: string;
  gender: string;
  dob: string;
  token: string;
  refresh_token: string;
}
