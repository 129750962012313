import { IAuditable } from '../auditable.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IProduct } from './product.interface';

export interface IInventoryModifiers extends IAuditable {
  product_id: number;
  supermarket_id?: number;
  action_price?: number;
  sale_price?: number;
  sale_start_date?: Date;
  sale_end_date?: Date;
  sale_start_time?: Date;
  sale_end_time?: Date;
  availability_start_date?: Date;
  availability_end_date?: Date;
  availability_start_time?: Date;
  availability_end_time?: Date;
  track_stock?: boolean;
  stock_quantity?: number;
  reserved_quantity?: number;
  product?: IProduct;
  supermarket?: Partial<ISuperMarket>;
}

export interface IInventoryModifiersObjectChanges extends IAuditable {
  supermarket_id?: number;
  action_price?: number;
  sale_price?: number;
  sale_start_date?: string;
  sale_end_date?: string;
  sale_start_time?: string;
  sale_end_time?: string;
  availability_start_date?: string;
  availability_end_date?: string;
  availability_start_time?: string;
  availability_end_time?: string;
  track_stock?: boolean;
  stock_quantity?: number;
  reserved_quantity?: number;
}

export interface IInventoryModifiersCsv {
  sku: string;
  action_price: string;
  sale_price: string;
  sale_start_date: string;
  sale_end_date: string;
  sale_start_time: string;
  sale_end_time: string;
  availability_start_date: string;
  availability_end_date: string;
  availability_start_time: string;
  availability_end_time: string;
  track_stock: string;
  stock_quantity: string;
}

export interface IGrpcInventoryModifierStock {
  supermarket_id: number;
  product_id: number;
  quantity: number;
}

export interface IGrpcUpdateInventoryModifierStock {
  inventory_modifiers: IGrpcInventoryModifierStock[];
  is_restock?: boolean;
}

export interface IGrpcGetInventoryModifierStock {
  supermarket_id: number;
  product_id: number;
}

export interface IGrpcGetInventoryModifierStockReq {
  products: IGrpcGetInventoryModifierStock[];
}

export interface IGrpcGetInventoryModifierStockRes {
  stocks: Partial<IInventoryModifiers>[];
}

export const inventoryModifiersCsvLabels = {
  sku: 'SKU',
  action_price: 'Action Price',
  sale_price: 'Sale Price',
  sale_start_date: 'Sale Start Date',
  sale_end_date: 'Sale End Date',
  sale_start_time: 'Sale Start Time',
  sale_end_time: 'Sale End Time',
  availability_start_date: 'Availability Start Date',
  availability_end_date: 'Availability End Date',
  availability_start_time: 'Availability Start Time',
  availability_end_time: 'Availability End Time',
  track_stock: 'Track Stock',
  stock_quantity: 'Stock Quantity'
};
