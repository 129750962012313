import { IBaseAudit } from './auditable.interface';

export interface IReferral extends IBaseAudit {
  referrer_id?: number;
  referee_id?: number;
  device_id?: string;
  issued_to_referrer?: boolean;
  issued_to_referee?: boolean;
}

export interface IReferralProgram extends IBaseAudit {
  referee_credit_amount: number;
  referrer_credit_amount: number;
  referee_credit_expiry_days?: number;
  referrer_credit_expiry_days?: number;
  max_referee_count?: number;
  max_referral_earnings_limit: number;
  country_id: number;
  currency: string;
  enabled: boolean;
}

export interface IGrpcGetUserReferralDetailsReq {
  user_id: number;
}

export interface IGrpcGetUserReferralDetailsRes {
  referral?: IReferral;
  referred_users_ids?: number[];
  referral_program?: IReferralProgram;
}

export interface IGrpcUpdateReferralReq {
  id: number;
  issued_to_referee: boolean;
  issued_to_referrer: boolean;
}
