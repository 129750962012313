import { IBaseAudit } from '../auditable.interface';

export interface ILoyaltyReward extends IBaseAudit {
  user_id?: number;
  source_type?: LoyaltyRewardSourceTypeEnum;
  source_id?: number;
  location_code?: string;
  transaction_type_code?: string;
  transaction_unique_reference?: string;
  value?: number;
  base_points?: number;
  bonus_points?: number;
  points_earned?: number;
  transaction_product_list?: unknown;
  points_redeemed?: number;
}

export enum LoyaltyRewardSourceTypeEnum {
  ORDER = 'order'
}

export enum LoyaltyRewardTransactionTypeCodeEnum {
  ONLINE_EARN = 'ONLINE_EARN',
  REDEEM = 'REDEEM',
  POINTS_ADJUSTMENT = 'Points_Adjustment',
  CANCEL_REDEEM = 'CANCEL_REDEEM'
}

export interface IUserLoyaltyRewardsBalance {
  loyalty_points: number;
}

export type ILoyaltyRewardListData = {
  user_id: number;
  page: number;
};

export interface ILoyaltyRewardListPayload extends ILoyaltyRewardListData {
  [index: string]: string | number;
}

export const loyaltyRewardListSelect = {
  id: true,
  source_id: true,
  transaction_type_code: true,
  transaction_unique_reference: true,
  value: true,
  base_points: true,
  points_earned: true,
  points_redeemed: true
};
