import { Observable, Subscriber } from 'rxjs';
import { EventSourcePolyfill, OnMessageEvent } from 'ng-event-source';

import { Injectable, NgZone } from '@angular/core';

import { IEventStreamError } from '@ecommerce/common-types';

import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ServerSendEvent {
  public serverSendEvent?: EventSourcePolyfill;

  constructor(private zone: NgZone) {}

  public listenServerEvent(path: string): Observable<string> {
    return new Observable((observer: Subscriber<string>): void => {
      this.serverSendEvent = new EventSourcePolyfill(`${environment.apiBaseUrl}${path}`, {
        headers: { 'x-auth-token': localStorage.getItem('x-auth-token') },
        checkActivity: true
      });

      this.serverSendEvent.onmessage = (event: OnMessageEvent): void => {
        this.zone.run((): void => observer.next(event.data));
      };

      this.serverSendEvent.onerror = (error: IEventStreamError): void => {
        this.zone.run((): void => {
          if (error.errorCode) {
            observer.error(error);
            this.closeServerEvents();
          }
        });
      };
    });
  }

  public closeServerEvents(): void {
    if (this.serverSendEvent) this.serverSendEvent.close();
  }
}
