import { catchError, Observable, of, Subject, Subscriber, switchMap, tap, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';

import { RootService } from '../service/root/root.service';
import { AuthService } from '../service';

import { environment } from '../../../environments/environment';
import { IResponse } from '@ecommerce/common-types';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshToken$: Subject<void> = new Subject<void>();
  private refreshTokenObservable: Observable<void> = this.refreshToken$.asObservable();

  constructor(private rootService: RootService, private authService: AuthService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<Request>> {
    const clonedRequest: HttpRequest<T> = request.clone({
      headers: this.getTokenHeader(),
      url: this.fixUrl(request.url)
    });

    return next.handle(clonedRequest).pipe(
      catchError((error): Observable<HttpEvent<Request>> => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          !clonedRequest.url.includes('login') &&
          !clonedRequest.url.includes('forgot-password')
        ) {
          localStorage.removeItem('x-auth-token');
          return this.handleTokenExpired<T>(request, next);
        }

        return throwError((): unknown => error.error);
      })
    );
  }

  private fixUrl(url: string): string {
    if (url.indexOf('http://') >= 0 || url.indexOf('https://') >= 0) return url;
    else return environment.apiBaseUrl + url;
  }

  private getTokenHeader(): HttpHeaders {
    const token: string | null = localStorage.getItem('x-auth-token');
    const refreshToken: string | null = localStorage.getItem('x-refresh-token');
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Access-Control-Allow-Origin': '*',
      'x-auth-token': token ?? '',
      'x-refresh-token': refreshToken ?? '',
      platform: 'admin',
      timezone
    });
  }

  private handleTokenExpired<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<Request>> {
    // Call the refresh token endpoint to get a new access token
    return this.refreshToken().pipe(
      switchMap((): Observable<HttpEvent<Request>> => {
        // Retry the original request with the new access token
        return next.handle(this.regenerateRequest<T>(request));
      }),
      catchError((): Observable<never> => {
        this.rootService.logOut();
        return of();
      })
    );
  }

  private refreshToken(): Observable<unknown> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer: Subscriber<unknown>): void => {
        this.refreshTokenObservable.subscribe((): void => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        tap((response: IResponse<string>): void => {
          localStorage.setItem('x-auth-token', response.data);
          this.refreshTokenInProgress = false;
          this.refreshToken$.next();
        }),
        catchError((): Observable<never> => {
          this.refreshTokenInProgress = false;
          this.rootService.logOut();
          return of();
        })
      );
    }
  }

  private regenerateRequest<T>(request: HttpRequest<T>): HttpRequest<T> {
    return request.clone({
      headers: this.getTokenHeader(),
      url: this.fixUrl(request.url)
    });
  }
}
