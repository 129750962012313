import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({ selector: '[ecommDelayedInput]' })
export class DelayedInputDirective implements OnInit, OnDestroy {
  @Input() delayTime = 400;
  @Output() inputChange = new EventEmitter<string>();
  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  public ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        debounceTime(this.delayTime),
        map((event: Event): string => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((event: string): void => this.inputChange.emit(event));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
