import { IAuditable } from '../auditable.interface';
import { IFileSize } from '../common.interface';
import { IProduct } from './product.interface';

export interface IProductImage extends IAuditable {
  order: number;
  file_name?: string;
  product_id?: number;
  product?: IProduct;
  urls?: IProductImageUrls;
}

export interface IProductImageUrls {
  mini?: string;
  small?: string;
  product?: string;
  large?: string;
}

export enum ProductImageSizeEnum {
  MINI = 'mini',
  SMALL = 'small',
  PRODUCT = 'product',
  LARGE = 'large'
}

export const productImageSizes: IFileSize[] = [
  { size: ProductImageSizeEnum.MINI, width: 48, height: 48 },
  { size: ProductImageSizeEnum.SMALL, width: 100, height: 100 },
  { size: ProductImageSizeEnum.PRODUCT, width: 240, height: 240 },
  { size: ProductImageSizeEnum.LARGE, width: 600, height: 600 }
];
