export const environment = {
  name: 'dev',
  production: false,
  apiBaseUrl: 'https://ecommerce20api-dev.aymcommerce.com/api/',
  sentryConfig: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    enabled: true,
    environment: 'dev'
  }
};
