import { ComponentRef, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';

import { InputInfoTooltipComponent } from '../component/input-info-tooltip/input-info-tooltip.component';

@Directive({ selector: '[ecommInputInfoTooltip]' })
export class InputInfoIconDirective implements OnInit {
  @Input() public tooltipText = '';
  @Input() public iconStyleClass = 'input-info-icon';

  constructor(private viewContainerRef: ViewContainerRef) {}

  public ngOnInit(): void {
    const component: ComponentRef<InputInfoTooltipComponent> =
      this.viewContainerRef.createComponent(InputInfoTooltipComponent);

    // set component's input properties
    component.instance.tooltipText = this.tooltipText;
    component.instance.iconStyleClass = this.iconStyleClass;

    const labelElement: HTMLElement = this.viewContainerRef.element.nativeElement.querySelector('label');

    // insert the tooltip component after the label tag
    this.viewContainerRef.element.nativeElement.insertBefore(
      component.location.nativeElement,
      labelElement.nextSibling
    );
  }
}
