import { Directive, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[disableControl]' })
export class DisableControlDirective {
  @Input() inputControl!: AbstractControl;
  @Input() set disableControl(condition: boolean) {
    const control: AbstractControl = this.inputControl ?? this.ngControl.control;
    condition ? control?.disable() : control?.enable();
  }

  constructor(private ngControl: NgControl) {}
}
