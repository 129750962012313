import { IBaseAudit } from '../auditable.interface';
import { IUser } from '../user.interface';

export interface IAddress extends IBaseAudit {
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  type: AddressTypeEnum;
  user_id: number;
  is_default: boolean;
  formatted_address: string;
  title: string;
  district?: string;
  street_no?: string;
  area?: string;
  postal_code?: number;
  delivery_instruction?: string;
  user?: IUser;
  verified?: boolean;
  is_verified?: boolean;
  image?: string;
  comments?: string;
}

export enum AddressTypeEnum {
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other'
}

export const addressSelect = {
  id: true,
  city: true,
  country: true,
  latitude: true,
  longitude: true,
  type: true,
  is_default: true,
  district: true,
  formatted_address: true,
  street_no: true,
  area: true,
  postal_code: true,
  delivery_instruction: true,
  is_verified: true
};

export interface IGrpcUserAddressUpdateRequest {
  id: number;
  city: string;
  country: string;
  district: string;
  latitude: number;
  longitude: number;
  postal_code: number;
  area: string;
  street_no: string;
  formatted_address: string;
  comments: string;
  image: string;
  is_verified: boolean;
}

export interface IGrpcUserAddressUpdateResponse {
  isUpdated: boolean;
}
